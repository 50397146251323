import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Typography } from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import Divider from "@material-ui/core/Divider";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: "10px 10px",
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    itemText: {
        // color: theme.palette.text.secondary,
    },
    itemPrice: {
        marginTop: 0,
    },
    bigAvatar: {
        // margin: 10,
        width: 80,
        height: 80,
    },
    white: {
        background: 'white',
        color: 'black',
        border: '1px solid black',
        height: 60,
        width: 60,
    },
    green: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        width: 60,
        height: 60,
    },
    ListItemSecondaryAction: {
        paddingRight: '10px'
    },
}));

const RewardCard = (props) => {
    const classes = useStyles();
    const { data, indicator } = props;

    return (
        <>
            <ListItem>
                <ListItemIcon>
                    <Avatar variant='circular' alt={data.name} src={data.photo} className={classes.bigAvatar} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant='body2' style={{ paddingLeft: '20px' }} >
                            {data.name}
                        </Typography>
                    }
                    secondary={
                        <Typography variant='subtitle1' style={{ paddingLeft: '20px' }}>
                            {data.description || ''}
                        </Typography>
                    }
                />
                <ListItemSecondaryAction className={classes.ListItemSecondaryAction}>
                    <Avatar alt="Remy Sharp" className={(data.point || 0) < (indicator || 0) ? classes.green : classes.white}>
                        <Typography color='inherit'>{data.point || ''} </Typography>
                    </Avatar>
                </ListItemSecondaryAction>
            </ListItem >
            <Divider style={{ width: "100%" }} />
        </>
    )
};

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardCard);
