import * as VariantActionConstants from "../store/actions/variant_actions";
import * as ItemActionConstants from "../store/actions/item_actions";
import { toDict } from "../utils/array_util";
import { DataLoadingState } from "../constants";

const getInvalidItemIds = (items, variantDict) => {
  const itemsIdsToRemove = items
    .filter((itm) => {
      if (itm.itemVariationIds.length === 1) {
        if (itm.defaultVariation) {
          const variant = variantDict[itm.defaultVariation.id];
          return variant === null || variant === undefined;
        }
      }
      return false;
    })
    .map((itm) => itm.id);
  return itemsIdsToRemove;
};

const createRemoveItemAction = (itemsIdsToRemove) => {
  let removeItemAction = null;
  if (itemsIdsToRemove.length > 0) {
    removeItemAction = {
      type: ItemActionConstants.REMOVE_ITEMS,
      itemIds: itemsIdsToRemove,
    };
    return removeItemAction;
  }
};

const variantMiddleware = (store) => (next) => (action) => {
  let removeItemAction = null;
  switch (action.type) {
    case VariantActionConstants.FETCH_VARIANTS_COMPLETED:
      {
        const state = store.getState();
        if (state.items.state !== DataLoadingState.Loaded || action.variants.status !== "Ok") {
          break;
        }
        const items = state.items.data;
        const variantDict = toDict(action.variants.data, "id");
        const itemsIdsToRemove = getInvalidItemIds(items, variantDict);
        removeItemAction = createRemoveItemAction(itemsIdsToRemove);
      }
      break;
    case ItemActionConstants.FETCH_ITEMS_COMPLETED:
      {
        const state = store.getState();
        if (state.variants.state !== DataLoadingState.Loaded || action.payload.status !== "Ok") {
          break;
        }
        const items = action.payload.data;
        const variantDict = state.variants.data.variants;
        const itemsIdsToRemove = getInvalidItemIds(items, variantDict);
        removeItemAction = createRemoveItemAction(itemsIdsToRemove);
      }
      break;
    default:
      break;
  }
  next(action);
  if (removeItemAction) {
    next(removeItemAction);
  }
};

export default variantMiddleware;
