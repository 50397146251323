import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { actionCreators as userActions } from "../../store/actions/user_actions";
import { CheckoutStages } from "../../constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import HomeButton from "../../controls/home_button";
import { ArrowRight } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    //backgroundColor: theme.palette.secondary.light,
  },
  logorow: {
    backgroundColor: theme.palette.secondary.light,
  },
  entry: {
    paddingTop: "20px",
    //color: theme.palette.secondary.contrastText,
  },
  textEntry: {
    paddingTop: "20px",
  },
  pad: {
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  logo: {
    width: "128px",
  },
}));

const ShippingAddress = (props) => {
  const { user } = props
  const [values, setValues] = useState({
    address: (user && user.address) ? user.address : "",
    city: (user && user.city) ? user.city : "",
  });
  const [formError, seFormError] = useState({ address: false, city: false })
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const handleNext = (event) => {
    const validate = formValidate()
    if (validate.success) {
      props.setUserAddress(values.address, values.city);
      props.onNext(CheckoutStages.PAYMENT);
    } else {
      seFormError({ ...formError, [validate.code]: true, [validate.not]: false })
    }

  };
  const formValidate = () => {
    if (values.address.length <= 0 || values.address === "" || !values.address.replace(/\s/g, '').length) {
      return { success: false, code: 'address', not: 'city' }
    }
    if (values.city.length <= 0 || values.city === "" || !values.city.replace(/\s/g, '').length) {
      return { success: false, code: 'city', not: 'address' }
    }
    return { success: true }
  }
  const handleBack = () => {
    props.onBack(CheckoutStages.SHIPPING)
  }
  let rootStyle = { width: "100%" };
  if (matches) {
    rootStyle.width = "400px";
    //rootStyle.borderRadius = "15px";
    //rootStyle.borderColor = "black";
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  //const handleHomeButtonClick = (event) => {};

  return (
    <div>
      <HomeButton />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
          {/* <Grid item className={classes.logorow}>
            <img alt={props.merchant.businessName} src={props.merchant.logo} className={classes.logo} />
          </Grid>
          <Grid item className={classes.entry}>
            <Typography variant="h6">{props.merchant.businessName}</Typography>
          </Grid> */}
          <Grid item className={classes.entry}>
            <Typography variant="h6">Shipping Address</Typography>
          </Grid>
          <Grid item className={classes.textEntry}>
            <TextField
              id="address"
              label="Address"
              color="secondary"
              variant="outlined"
              margin="normal"
              multiline
              rowsMax={4}
              value={values.address}
              style={{ width: "250px" }}
              onChange={handleChange("address")}
              error={formError.address}
              helperText={formError.address ? 'invalid address' : ''}
            />
          </Grid>
          <Grid item className={classes.textEntry}>
            <TextField
              id="city"
              label="City"
              color="secondary"
              variant="outlined"
              margin="normal"
              value={values.city}
              style={{ width: "250px" }}
              onChange={handleChange("city")}
              error={formError.city}
              helperText={formError.city ? 'invalid city' : ''}
            />
          </Grid>
          <Grid item className={classes.entry}>
            {
              props.onBack ? (
                <Grid item className={classes.textEntry} style={{ width: '250px' }} >
                  <Grid container direction='row' spacing={8}>
                    <Grid item >
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<ArrowLeft />}
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item >
                      <Button startIcon={<ArrowRight />} variant="contained" color="primary" onClick={handleNext}>
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) :
                (<Button startIcon={<ArrowRight />} variant="contained" color="primary" onClick={handleNext}>
                  Next
                </Button>)
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    merchant: state.merchant.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAddress: (address, city) => dispatch(userActions.setUserAddress(address, city)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddress);
