import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Queue from "@material-ui/icons/Queue";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10000,
  },
  iconButton: {
    padding: "0px",
  },
}));

const BadgeIconButton = (props) => {
  const classes = useStyles();

  const { badgeValue, onClick } = props;
  return (
    <div className={classes.root}>
      <IconButton aria-label="variants" onClick={onClick} className={classes.iconButton}>
        <Badge
          className={classes.badge}
          badgeContent={badgeValue}
          max={99}
          color="primary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Queue color="primary" />
        </Badge>
      </IconButton>
    </div>
  );
};

export default BadgeIconButton;
