export const GET_SHIPPINGFEES = "GET_SHIPPINGFEES";
export const SET_SHIPPINGFEES = "SET_SHIPPINGFEES";
export const GET_SHIPPINGFEE_BY_LOCATION = "GET_SHIPPINGFEE_BY_LOCATION";
export const FETCH_SHIPPINGFEES = "FETCH_SHIPPINGFEES";
export const FETCH_SHIPPINGFEES_COMPLETED = "FETCH_SHIPPINGFEES_COMPLETED";

export const actionCreators = {
  getShippingFees: () => ({
    type: GET_SHIPPINGFEES,
  }),

  setShippingFees: (shippingFees) => ({
    type: SET_SHIPPINGFEES,
    shippingFees,
  }),

  getShippingFeeByLication: (location) => ({
    type: GET_SHIPPINGFEE_BY_LOCATION,
    location,
  }),

  fetchShippingFees: (merchantId) => ({
    type: FETCH_SHIPPINGFEES,
    merchantId,
  }),

  fetchShippingFeesCompleted: (payload) => ({
    type: FETCH_SHIPPINGFEES_COMPLETED,
    payload,
  }),
};
