var intl = require('intl-messageformat');

export const intelMessage = (str, values) => {
    var msg = new intl.IntlMessageFormat(str, 'en-US');
    let output = '';
    try {
        output = msg.format({ ...values });
    } catch (error) {
        console.log('error', error.toString())
        return { success: false, message: error.toString() }
    }
    return { success: true, message: output }
}