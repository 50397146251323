import React from 'react';
import PropTypes from 'prop-types';

const imageLinks = {
    ios: 'https://firebasestorage.googleapis.com/v0/b/mcommercev2.appspot.com/o/appStore%2FAppleStore.png?alt=media&token=a64f12c9-5e52-4490-a3c7-4a38b9a8363b',
    android: 'https://firebasestorage.googleapis.com/v0/b/mcommercev2.appspot.com/o/appStore%2FGooglePlay.png?alt=media&token=6a3303d9-1065-4e40-8aaa-14dc3731d6d7',
};
const appLinks = {
    ios: 'itms-apps://itunes.apple.com/us/app/piti/id1438732140',
    android: 'market://details?id=com.piti.member',
}

class MobileStoreButton extends React.Component {
    static propTypes = {
        store: PropTypes.oneOf(['ios', 'android']).isRequired,
        height: PropTypes.number,
        width: PropTypes.number,
        linkStyles: PropTypes.object,
        linkProps: PropTypes.object,
    };
    static defaultProps = {
        height: 75,
        width: 255,
    };

    render() {
        const {
            store,
            url,
            height,
            width,
            linkStyles,
            linkProps,
            ...props
        } = this.props;

        const defaultLinkStyles = {
            background: `url(${imageLinks[store]}) no-repeat`,
            backgroundSize: 'contain',
            display: 'inline-block',
            overflow: 'hidden',
            textDecoration: 'none',
            height: '100%',
            width: '100%',
            padding: '5px',
            ...linkStyles,
        };

        return (
            <div style={{ height, width, display: 'inline-block' }} {...props}>
                <a
                    style={defaultLinkStyles}
                    href={appLinks[store]}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...linkProps}
                >
                    &nbsp;
                </a>
            </div>
        );
    }
}

export default MobileStoreButton;