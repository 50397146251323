import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, map, retryWhen, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { handleError, RetryStrategy } from "./handle_ajax_error";
import { actionCreators, FETCH_MERCHANT } from "../store/actions/merchant_actions";
import { GET_DATA_ERROR } from "../store/actions/common_actions";
import { DataApi } from "../constants/";
import { XMLHttpRequest } from 'xmlhttprequest';
function createXHR() {
  return new XMLHttpRequest();
}
export const merchantEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_MERCHANT),
    mergeMap((action) => {
      // console.log(action);
      // console.log(`${DataApi.getMerchant}/${action.merchantId}`);
      return ajax({
        createXHR,
        url: `${DataApi.getMerchant}/${action.merchantId}`,
        method: 'GET',
        responseType: 'json',
        crossDomain: true,
        withCredentials: false,
      }).pipe(
        map((response) => actionCreators.fetchMerchantCompleted(response.response)),
        retryWhen(RetryStrategy),
        catchError((error) => handleError(error, GET_DATA_ERROR))
      );
    })
  );
