export const OSPlatforms = {
    WINDOWOS: 'Win',
    MACOS: 'Mac',
    UNIXOS: 'X11',
    LINUXOS: 'Linux',
    IPHONEOS: 'iPhone',
    ANDRIODOS: 'Android',
    UNKNOWNOS: 'Unknown'
}
export const getCurrentOS = () => {
    const userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
        os = OSPlatforms.MACOS;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = OSPlatforms.IPHONEOS;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = OSPlatforms.WINDOWOS;
    } else if (/Android/.test(userAgent)) {
        os = OSPlatforms.ANDRIODOS;
    } else if (!os && /Linux/.test(platform)) {
        os = OSPlatforms.LINUXOS;
    } else {
        os = OSPlatforms.UNKNOWNOS
    }
    return os
}

export function refreshPage() {
    window.location.reload();
}