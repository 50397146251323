import { createSelectorHook } from "react-redux";

import { createSelector } from "reselect";
import { DataLoadingState } from "../../constants/index";

const merchantLoadedSelector = (state, props) => state.merchant.state === DataLoadingState.Loaded;
const categoriesLoadedSelector = (state, props) => state.categories.state === DataLoadingState.Loaded;
const itemsLoadedSelector = (state, props) => state.items.state === DataLoadingState.Loaded;

const isInitialDataLoaded = () => {
  return createSelector([merchantLoadedSelector, categoriesLoadedSelector, itemsLoadedSelector], (merchantLoaded, categoriesLoaded, itemsLoaded) => {
    return merchantLoaded && categoriesLoaded && itemsLoaded;
  });
};

export default isInitialDataLoaded;
