import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";

const controlWidth = 30;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      width: controlWidth,
      minWidth: controlWidth,
    },
    btgGroup: {
      width: controlWidth,
      borderRadius: "15px",
    },
    upDownBtn: {
      borderRadius: "15px",
      height: controlWidth,
      minWidth: controlWidth,
      color: (props) => (props.backgroundColor ? theme.palette.getContrastText(props.backgroundColor) : theme.palette.primary.contrastText),
      backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : theme.palette.primary.main),
      "&:focus": {
        color: (props) => (props.backgroundColor ? theme.palette.getContrastText(props.backgroundColor) : theme.palette.primary.contrastText),
        backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : theme.palette.primary.main),
      },
      "&:hover": {
        color: (props) => (props.backgroundColor ? theme.palette.getContrastText(props.backgroundColor) : theme.palette.primary.contrastText),
        backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : theme.palette.primary.main),
      },
    },
    shoppingCartIcon: {
      borderRadius: "15px",
      height: controlWidth,
      minWidth: controlWidth,
      //color: theme.palette.secondary.contrastText,
      //backgroundColor: theme.palette.secondary.main,
      //":focus": {
      //  color: theme.palette.secondary.contrastText,
      //  backgroundColor: theme.palette.secondary.main,
      //},
    },
    valueTextWrapper: {
      width: controlWidth,
      minWidth: controlWidth,
      height: controlWidth,
      minHeight: controlWidth,
      backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : theme.palette.primary.main),
    },
    valueText: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: "Lato,sans-serif",
      paddingLeft: 0,
      paddingRight: 0,
      width: controlWidth - 2,
      minWidth: controlWidth,
      textAlign: "center",
      color: (props) => (props.backgroundColor ? theme.palette.getContrastText(props.backgroundColor) : theme.palette.primary.contrastText),
    },
  };
});

const VerticalUpDown = (props) => {
  const { value, onValueChange, min = 0, max = 100, showValue = true, zeroIcon = true, color = null } = props;
  const [count, setCount] = useState(value);
  const [upDisabled, setUpDisabled] = useState(false);
  const [downDisabled, setDownDisabled] = useState(false);

  const classes = useStyles({ backgroundColor: color });

  useEffect(() => {
    setCount(value);
  }, [value]);

  const handleUp = (event) => {
    const newCount = count + 1;
    changeCount(newCount, event);
  };

  const handleDown = (event) => {
    const newCount = count - 1;
    changeCount(newCount, event);
  };

  const changeCount = (newCount, event) => {
    if (newCount <= max && newCount >= 0) {
      setCount(newCount);

      if (onValueChange) onValueChange(newCount);

      //setUpDisabled(newCount >= max);
      //setDownDisabled(newCount <= min);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  if (count === 0 && zeroIcon) {
    return (
      <div className={classes.root}>
        <Button onClick={handleUp} disabled={upDisabled} color="primary" className={classes.shoppingCartIcon}>
          <ShoppingBasket />
        </Button>
      </div>
    );
  }

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div onClick={handleClick}>
      <ButtonGroup orientation="vertical" color="secondary" aria-label="button group" className={classes.btgGroup}>
        <IconButton onClick={handleUp} disabled={upDisabled} color="secondary" className={classes.upDownBtn}>
          <AddIcon fontSize="small" />
        </IconButton>
        {showValue && <Chip label={count} color="secondary" variant="default" classes={{ root: classes.valueTextWrapper, label: classes.valueText }} />}
        <IconButton onClick={handleDown} disabled={downDisabled} color="secondary" className={classes.upDownBtn}>
          <RemoveIcon />
        </IconButton>
      </ButtonGroup>
    </div>
  );
};

export default VerticalUpDown;
