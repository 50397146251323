import { connect } from "react-redux";
import { ScaleLoader } from "react-spinners";
const WaveSpinner = (props) => {
    return (
        <ScaleLoader size={30} width={3} radius={4} margin={2} loading={true} />
    );
};

const mapStateToProps = (state) => ({
    //primaryColor: state.merchant.theme.primaryColor,
});

export default connect(mapStateToProps)(WaveSpinner);
