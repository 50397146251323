import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Person from "@material-ui/icons/Person";
import DescriptionIcon from '@material-ui/icons/Description';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Grid from "@material-ui/core/Grid";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faSignOutAlt, faListAlt } from "@fortawesome/free-solid-svg-icons";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import { actionCreators as otpActions } from "../../store/actions/otp_actions";
import { actionCreators as userActions } from "../../store/actions/user_actions";
import { LoginStatus } from "../../constants";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  subHeader: {
    backgroundColor: theme.palette.secondary.dark,
  },
  subHeaderLogo: {
    paddingTop: "5px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  avararlarge: {
    marginTop: theme.spacing(1),
    //width: 48,
    height: 48,
  },
  shopName: {
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    marginLeft: "10px",
  },
}));

const MainManu = (props) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const { user } = props;

  //onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    props.setMainMenuState(open);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    props.setMainMenuState(false);

    // console.log(index);
    //login/logout
    if (index === 2) {
      //console.log(user);
      if (user.status === LoginStatus.LOGGED_IN) {
        props.logout();
        props.clearOtp();
      } else {
        //console.log("login");
        props.push("/login");
      }
    }
    if (index === 3) {
      props.push("/contact")
    }
    if (index === 0) {
      props.push("/profile");
    }
    if (index === 4) {
      props.push("/order");
    }
    if (index === 5) {
      props.push("/term");
    }
  };

  const list = () => (
    <div className={classes.list} role="presentation">
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader" className={classes.subHeader}>
            <Grid container spacing="1" justify="flex-start" alignItems="center">
              <Grid item>
                <img alt={props.merchant.businessName} src={props.merchant.logo} className={classes.avararlarge} />
              </Grid>
              <Grid item className={classes.shopName}>
                {props.merchant.businessName}
              </Grid>
            </Grid>
          </ListSubheader>
        }
        className={classes.root}
      >
        {
          user.status === LoginStatus.LOGGED_IN && (
            <ListItem button key="User" selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary={user.name || ''} />
            </ListItem>
          )
        }
        {
          user.status === LoginStatus.LOGGED_IN && (
            <ListItem button key="Order" selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faListAlt} />
              </ListItemIcon>
              <ListItemText primary={'Orders'} />
            </ListItem>
          )
        }

        {/* <ListItem button key="Order" selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem> */}
      </List>
      <Divider />
      <List>
        <ListItem button key="Contact" selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
          <ListItemIcon><ContactPhoneIcon /></ListItemIcon>
          <ListItemText primary={'Contact'} />
        </ListItem>
        <ListItem button key="termAndCondition" selected={selectedIndex === 5} onClick={(event) => handleListItemClick(event, 5)}>
          <ListItemIcon><DescriptionIcon /></ListItemIcon>
          <ListItemText primary={'Terms & Conditions'} />
        </ListItem>
        <Divider />
        <ListItem button key="Log Out" selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
          <ListItemIcon>{user.status === LoginStatus.LOGGED_IN ? <FontAwesomeIcon icon={faSignOutAlt} /> : <FontAwesomeIcon icon={faSignInAlt} />}</ListItemIcon>
          <ListItemText primary={user.status === LoginStatus.LOGGED_IN ? "Log Out" : "Log In"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key="left">
        <Drawer anchor="left" open={props.IsOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { menu, merchant, user } = state;
  return {
    IsOpen: menu.MainMenuIsOpen,
    merchant: merchant.data,
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainMenuState: (menuState) => dispatch(menuActions.setMainMenuState(menuState)),
    logout: () => dispatch(userActions.userLogout()),
    clearOtp: () => dispatch(otpActions.clearOtpCompleted()),
    push: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainManu);
