import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
//import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chip from "@material-ui/core/Chip";
//import AddIcon from "@material-ui/icons/Add";
//import RemoveIcon from "@material-ui/icons/Remove";
import ShoppingBasket from "@material-ui/icons/RemoveShoppingCart";

const controlWidth = 30;

const useStyles = makeStyles((theme) => {
    return {
        root: {
            flexGrow: 1,
            width: controlWidth,
            minWidth: controlWidth,
        },
        btgGroup: {
            width: controlWidth,
            borderRadius: "15px",
        },
        upDownBtn: {
            borderRadius: "15px",
            height: controlWidth,
            minWidth: controlWidth,
            color: (props) => (props.backgroundColor ? theme.palette.getContrastText(props.backgroundColor) : theme.palette.primary.contrastText),
            backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : theme.palette.primary.main),
            "&:focus": {
                color: (props) => (props.backgroundColor ? theme.palette.getContrastText(props.backgroundColor) : theme.palette.primary.contrastText),
                backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : theme.palette.primary.main),
            },
            "&:hover": {
                color: (props) => (props.backgroundColor ? theme.palette.getContrastText(props.backgroundColor) : theme.palette.primary.contrastText),
                backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : theme.palette.primary.main),
            },
        },
        shoppingCartIcon: {
            borderRadius: "15px",
            height: controlWidth,
            minWidth: controlWidth,
            //color: theme.palette.secondary.contrastText,
            //backgroundColor: theme.palette.secondary.main,
            //":focus": {
            //  color: theme.palette.secondary.contrastText,
            //  backgroundColor: theme.palette.secondary.main,
            //},
        },
        valueTextWrapper: {
            width: controlWidth,
            minWidth: controlWidth,
            height: controlWidth,
            minHeight: controlWidth,
            backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : theme.palette.primary.main),
        },
        valueText: {
            fontSize: 14,
            fontWeight: 700,
            fontFamily: "Lato,sans-serif",
            paddingLeft: 0,
            paddingRight: 0,
            width: controlWidth - 2,
            minWidth: controlWidth,
            textAlign: "center",
            color: (props) => (props.backgroundColor ? theme.palette.getContrastText(props.backgroundColor) : theme.palette.primary.contrastText),
        },
    };
});

const OutOfStockButton = () => {
    const [count, setCount] = useState(0);
    const classes = useStyles({ backgroundColor: null });
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setCount(count === 0 ? -1 : 0)
    };
    if (count === 0) {
        return (
            <div className={classes.root}>
                <Button onClick={handleClick} color="primary" className={classes.shoppingCartIcon}>
                    <ShoppingBasket />
                </Button>
            </div>
        );
    }


    return (
        <div onClick={handleClick}>
            <Chip label='out of stock'
                color="secondary"
                variant="default"
            />
        </div>
    );
};

export default OutOfStockButton;
