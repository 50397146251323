import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createEpicMiddleware } from "redux-observable";
import { createBrowserHistory } from "history";
import epics from "../epics/root_epic";
import { createRootReducer } from "./reducers/root_reducer";
import persistantDataMiddleware from "../middleware/persistant_data_middleware";
import cartlineTaxMiddleware from "../middleware/cartline_tax_middleware";
import variantMiddleware from "../middleware/variant_middleware";
import merchantMiddleware from "../middleware/merchant_middleware";

export const history = createBrowserHistory();

const configureStore = (initialState) => {
  const epicMiddleWare = createEpicMiddleware();

  const middlewares = [
    epicMiddleWare,
    /* AuthMiddleware, */
    routerMiddleware(history),
    merchantMiddleware,
    persistantDataMiddleware,
    cartlineTaxMiddleware,
    variantMiddleware,
  ];

  const enhancers = [];

  // In development, use the browser's Redux dev tools extension if installed
  const isDevelopment = process.env.NODE_ENV === "development";
  if (isDevelopment && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  var store = createStore(createRootReducer(history), initialState, compose(applyMiddleware(...middlewares), ...enhancers));

  epicMiddleWare.run(epics);

  return store;
};

export default configureStore;
