import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ProductDetail from "../../components/product/product_detail";
import Drawer from "@material-ui/core/Drawer";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ShoppingCart from "../../components/cart/shopping_cart";
import { DataLoadingState } from "../../constants";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  pad: {
    display: "flex",
    paddingTop: "60px",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "10px",
    width: "100%",
  },
  transparentDrawer: {
    background: "transparent",
    boxShadow: "none",
  },
}));

const ProductDetailPage = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [detailData, setDetailData] = useState(null);

  const id = props.match.params.id;
  //const path = props.match.path;

  useEffect(() => {
    if (props.items.state === DataLoadingState.Loaded && props.variants.state === DataLoadingState.Loaded) {
      const item = props.items.data.find((itm) => itm.id === id);
      if (item) {
        let variants = null;
        if (Array.isArray(item.itemVariationIds) && item.itemVariationIds.length > 1) {
          const variantArr = item.itemVariationIds.map((vid) => props.variants.data.variants[vid]).filter((v) => v !== undefined);
          if (variantArr.length > 0) {
            variantArr.forEach((v) => {
              if (!v.photo) v.photo = item.photo;
            });
            variants = variantArr;
          }
        } else {
          variants = [item.defaultVariation];
        }

        if (item && variants) {
          const data = { item, variants };
          // console.log(data);
          setDetailData(data);
        }
      }
    }
  }, [props.items, props.variants]);

  const style = matches ? { width: "100%" } : null;

  const shoppingCartDrawerAnchor = matches ? "right" : "bottom";
  const shoppingCartDrawerVariant = matches ? "persistent" : "temporary";
  const toggleShoopingCartDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    props.setShoppingCartState(open);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.pad}>
        {detailData && <ProductDetail data={detailData} />}
        <Hidden smDown>
          <Drawer anchor={shoppingCartDrawerAnchor} variant={shoppingCartDrawerVariant} open={props.cartIsOpen} onClose={toggleShoopingCartDrawer(shoppingCartDrawerAnchor, false)}>
            <ShoppingCart />
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            anchor={shoppingCartDrawerAnchor}
            variant={shoppingCartDrawerVariant}
            open={props.cartIsOpen}
            onClose={toggleShoopingCartDrawer(shoppingCartDrawerAnchor, false)}
            classes={{
              paper: classes.transparentDrawer,
            }}
          >
            <ShoppingCart />
          </Drawer>
        </Hidden>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    items: state.items,
    variants: state.variants,
    categories: state.categories.data,
    cartIsOpen: state.menu.ShoppingCartIsOpen,
    cartLines: state.cart.cartLines,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShoppingCartState: (shoppingCartState) => dispatch(menuActions.setShoppingCartState(shoppingCartState)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
