import { connect } from "react-redux";
import { ClipLoader, ScaleLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import "../../App.css";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

const DataLoadingOverlay = (props) => {
  return (
    <DarkBackground disappear={!props.loaded}>
      <LoadingOverlay active={true} spinner={<ScaleLoader size={60} width={6} radius={4} margin={4} />}></LoadingOverlay>
    </DarkBackground>
  );
};

const mapStateToProps = (state) => ({
  //primaryColor: state.merchant.theme.primaryColor,
});

export default connect(mapStateToProps)(DataLoadingOverlay);
