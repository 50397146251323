import * as Action_Constants from "../store/actions/merchant_actions";
import { applyMerchantDefaultValues, SystemCurrency } from "../constants";
import { compileTemplate } from "../utils/currency_utils";

const merchantMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case Action_Constants.FETCH_MERCHANT_COMPLETED:
      const { status, data } = action.payload;
      if (status === "Ok") {
        applyMerchantDefaultValues(data);
        Object.assign(SystemCurrency, data.currency);
        compileTemplate(data.currency.template);
       // console.log(SystemCurrency);
      }
      break;
    default:
      break;
  }
  next(action);
};

export default merchantMiddleware;
