import { createSelector } from "reselect";

const cartLinesSelector = (state) => state.cart.cartLines;
const currentCartLineIdSelector = (state, props) => props.data.defaultVariation.id;

const filterCartLine = (id) => {
  return createSelector([cartLinesSelector, currentCartLineIdSelector], (cartLines, cartLineId) => {
    const cartline = cartLines.find((line) => line.id === cartLineId);
    return cartline;
  });
};

export default filterCartLine;
