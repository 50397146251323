import React from "react";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import HomeButton from "../../controls/home_button";
//import ButtonWithProgress from "../../controls/button_with_progress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import CartHidden from "../../components/cart/shopping_cart_hidden";
import { CssBaseline, Typography, Card, CardActionArea, CardContent, CardMedia, CardHeader } from "@material-ui/core";
import { actionCreators as userActions } from "../../store/actions/user_actions";
import { actionCreators as otpActions } from "../../store/actions/otp_actions";
// import BottomNavigation from '@material-ui/core/BottomNavigation';
// import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
// import { AppBar, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
//import { ProfileNavigators } from "../../constants";
// import { getOrderHistory } from "../../services/firebase";
// import { Skeleton } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "10px",
        paddingLeft: "0px",
        //backgroundColor: theme.palette.secondary.light,
    },
    logorow: {
        backgroundColor: theme.palette.secondary.light,
    },
    bottomNavigation: {
        width: '100%',
        // backgroundColor: 'transparent'
    },
    entry: {
        paddingTop: "20px",
        //color: theme.palette.secondary.contrastText,
    },
    msg: {
        paddingTop: "20px",
        width: "100%",
    },
    pad: {
        display: "flex",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
    },
    logo: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    card: {
        boxShadow: 'none',
        backgroundColor: 'transparent'
    },
    media: {
        height: 140,
        //backgroundColor: theme.palette.secondary.light
        backgroundColor: 'transparent',
    },
    appBar: {
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        top: 'auto',
        bottom: 0,
        alignItems: 'center'
    },
    orderCard: {
        maxWidth: 345,
        minWidth: 345,
        marginTop: "5px",
        borderColor: theme.palette.primary.main,
        borderRadius: "5px",
    },
    orderExtraCard: {
        height: 100,
        width: 345,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: "none"
    },
    listItem: {
        padding: 0
    }
}));

const Profile = (props) => {
    const classes = useStyles();
    //const { user, merchant } = props;
    //const { phoneNo } = user
    //const merchantId = merchant.id
    const matches = useMediaQuery("(min-width:600px)");
    const [open, setOpen] = React.useState(false);
    //const [state, setState] = React.useState({ status: 'loading' })
    // const [navigator, setNavigator] = React.useState(ProfileNavigators.PROFILE);

    // React.useEffect(() => {
    //     if (phoneNo && merchantId) {
    //         getOrderHistory(merchantId, phoneNo, (result) => {
    //             if (result.success) {
    //                 setState({ status: 'loaded', data: result.data.orders })
    //             } else {
    //                 setState({ status: 'loaded', data: [] })
    //             }
    //         });
    //     }
    // }, [phoneNo, merchantId])
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    let rootStyle = { width: "100%" };
    if (matches) {
        rootStyle.width = "600px";
    }

    const handleLogout = () => {
        setOpen(false)
        props.logout();
        props.clearOtp();
    };

    // const handleChange = (event, newValue) => {
    //     setNavigator(newValue);
    // };

    const ProfilePage = (
        <>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
                    {/* <Grid item className={classes.logorow}>
                <Avatar alt="Remy Sharp" src={props.merchant.logo} className={classes.logo} />
            </Grid> */}
                    <Card style={{ maxWidth: 345 }} className={classes.card}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                className={classes.media}
                                image={props.merchant.logo}
                                title="Merchant Logo"
                            />
                            <CardContent alignItems='center'>
                                <Typography gutterBottom variant="h5" component="h2" style={{ textAlign: 'center' }}>
                                    {props.user.name}
                                </Typography>
                                <Typography variant="h6" style={{ textAlign: 'center' }}>{props.user.phoneNo}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    {/* <Grid item className={classes.entry}>
                <Typography variant="h5">{props.user.name}</Typography>
            </Grid>
            <Grid item className={classes.entry}>
                <Typography variant="h6">{props.user.phoneNo}</Typography>
            </Grid> */}
                    <Grid item className={classes.entry}>
                        <Button variant="contained" color="secondary" startIcon={<FontAwesomeIcon icon={faSignOutAlt} />} onClick={handleClickOpen} >
                            Logout
                        </Button>
                    </Grid>
                    {/* <Grid item className={classes.entry}>
                <ButtonWithProgress text='loading' isLoading={true} onClick={() => window.alert('On click')} />
            </Grid> */}
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure to logout?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleLogout} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

    // const OrdersPage = (
    //     <>
    //         <Grid container direction="row" justify="center" alignItems="center">
    //             <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
    //                 {
    //                     state.status === 'loaded' ? state.data.map(order => {
    //                         return (
    //                             <Card className={classes.orderCard}>
    //                                 <CardHeader title={<h5>{`Order(#${order.orderId ? order.orderId : order.id})`}</h5>} style={{ height: 0 }} />
    //                                 <CardContent>
    //                                     <List>
    //                                         <ListItem className={classes.listItem}>
    //                                             <ListItemText primary={<Typography variant="h7">Date</Typography>} />
    //                                             <ListItemSecondaryAction>
    //                                                 <Typography variant="body1" >
    //                                                     <b>{new Date((order.createdOn && order.createdOn._seconds) ? order.createdOn._seconds * 1000 : 0).toLocaleDateString("en-US")}</b>
    //                                                 </Typography>
    //                                             </ListItemSecondaryAction>
    //                                         </ListItem>
    //                                         <ListItem className={classes.listItem}>
    //                                             <ListItemText primary={<Typography variant="h7">OrderId</Typography>} />
    //                                             <ListItemSecondaryAction>
    //                                                 <Typography variant="body1" ><b>{`#${order.orderId}`}</b></Typography>
    //                                             </ListItemSecondaryAction>
    //                                         </ListItem>
    //                                         <ListItem className={classes.listItem}>
    //                                             <ListItemText primary={<Typography variant="h7">Items</Typography>}
    //                                                 secondary={
    //                                                     <ol>
    //                                                         {
    //                                                             order.details.map(d => {
    //                                                                 return (
    //                                                                     <>
    //                                                                         <li>
    //                                                                             <span> {`${d.name ? d.name : d.optionName}(${d.price})`}</span>
    //                                                                         </li>
    //                                                                     </>
    //                                                                 )
    //                                                             })
    //                                                         }
    //                                                     </ol>}
    //                                             />
    //                                         </ListItem>
    //                                         <ListItem className={classes.listItem}>
    //                                             <ListItemText primary={<Typography variant="h7">Status</Typography>} />
    //                                             <ListItemSecondaryAction>
    //                                                 <Typography variant="body1" ><b>{order.orderStatus}</b></Typography>
    //                                             </ListItemSecondaryAction>
    //                                         </ListItem>
    //                                         <ListItem className={classes.listItem}>
    //                                             <ListItemText primary={<Typography variant="h7">Total Amount</Typography>} />
    //                                             <ListItemSecondaryAction>
    //                                                 <Typography variant="body1" ><b>{order.totalAmount}</b></Typography>
    //                                             </ListItemSecondaryAction>
    //                                         </ListItem>
    //                                     </List>
    //                                 </CardContent>
    //                             </Card>
    //                         )
    //                     }) : <Card> <Skeleton animation="wave" style={{ width: 345, height: 345 }} /></Card>
    //                 }
    //                 <Card className={classes.orderExtraCard} />
    //             </Grid>

    //         </Grid>
    //     </>
    // )
    return (
        <div>
            <HomeButton />
            <CssBaseline />
            {/* {
                navigator === ProfileNavigators.ORDERS ? OrdersPage : ProfilePage

            } */}
            {ProfilePage}
            {/* <AppBar color="primary" className={classes.appBar}>
                <BottomNavigation value={navigator} onChange={handleChange} className={classes.bottomNavigation}>
                    <BottomNavigationAction label="Profile" value={ProfileNavigators.PROFILE} icon={<AccountCircleIcon fontSize='large' />} />
                    <BottomNavigationAction label="Orders" value={ProfileNavigators.ORDERS} icon={<FileCopyIcon fontSize='large' />} />
                </BottomNavigation>
            </AppBar> */}
            <CartHidden />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        merchant: state.merchant.data,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        replace: (url) => dispatch(replace(url)),
        logout: () => dispatch(userActions.userLogout()),
        clearOtp: () => dispatch(otpActions.clearOtpCompleted()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
