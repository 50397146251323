import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Typography from "@material-ui/core/Typography";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import Chip from "@material-ui/core/Chip";
import ButtonBase from "@material-ui/core/ButtonBase";
import CheckIcon from "@material-ui/icons/Check";
import { actionCreators as itemActions } from "../../store/actions/item_actions";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
    // height: 150,
    height: 120,
    // marginBottom: "15px",
    marginBottom: 0,
    border: "1px solid white",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  categoryName: {
    marginTop: 15,
    marginLeft: 0,
    opacity: 0.7,
    width: "100%",
    height: "50px",
    fontSize: 18,
    fontWeight: "bold",
    borderRadius: "0px",
    verticalAlign: "middle",
  },
  cardContent: {
    height: 150,
    objectFit: 'contain',
    padding: "0px",
  },
  textContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardAction: {
    // display: "block",
    // textAlign: "initial",

    width: "100%",
    display: "block",
    textAlign: "inherit",
  },
}));

const CategoryCard = (props) => {
  const { data, selectedCategory, selectCategory } = props;
  const classes = useStyles();

  const handleSelect = () => {
    if (selectedCategory === data.id) {
      selectCategory("");
    } else {
      selectCategory(data.id);
    }
    props.hideCategoryDrawer();
  };

  let selectedIndicator = null;
  if (selectedCategory === data.id) {
    selectedIndicator = <CheckIcon />;
  }

  let cardContent = <></>;
  if (data.photo) {
    cardContent = (
      <CardMedia className={classes.cardContent} image={data.photo} title={data.name}>
        <div className={classes.textContainer}>
          <Chip color="primary" label={data.name} classes={{ root: classes.categoryName }} icon={selectedIndicator} />
        </div>
      </CardMedia>
    );
  } else {
    cardContent = (
      <CardContent classes={{ root: classes.cardContent }}>
        <div className={classes.textContainer}>
          <Chip color="primary" label={data.name} classes={{ root: classes.categoryName }} icon={selectedIndicator} />
        </div>
      </CardContent>
    );
  }

  return (
    <Card className={classes.root} raised={false}>
      <ButtonBase className={classes.cardAction} onClick={handleSelect}>
        {cardContent}
      </ButtonBase>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCategory: state.items.filter.category,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectCategory: (categoryId) => dispatch(itemActions.setSelectedCategory(categoryId)),
    hideCategoryDrawer: () => dispatch(menuActions.hideCategoryDrawer()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCard);
