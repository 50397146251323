import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, map, retryWhen, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { handleError, RetryStrategy } from "./handle_ajax_error";
import { actionCreators, FETCH_SHIPPINGFEES } from "../store/actions/shipping_fee_actions";
import { GET_DATA_ERROR } from "../store/actions/common_actions";
import { DataApi } from "../constants/";
import { XMLHttpRequest } from 'xmlhttprequest';
function createXHR() {
  return new XMLHttpRequest();
}
export const shippingFeesEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_SHIPPINGFEES),
    mergeMap((action) => {
      return ajax({
        createXHR,
        url: `${DataApi.getShippingFee}/${action.merchantId}`,
        method: 'GET',
        responseType: 'json',
        crossDomain: true,
        withCredentials: false,
      }).pipe(
        map((response) => actionCreators.fetchShippingFeesCompleted(response.response)),
        retryWhen(RetryStrategy),
        catchError((error) =>
          //of(console.log(error.response.message))
          handleError(error, GET_DATA_ERROR)
        )
      )
    }
    )
  );
