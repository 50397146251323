import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";

const controlWidth = 42;
const borderRadius = 4;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //width: controlWidth,
    minWidth: controlWidth,
  },
  btgGroup: {
    width: controlWidth,
    borderRadius: borderRadius,
    //backgroundColor: Colors.,
  },
  upDownBtn: {
    borderRadius: borderRadius,
    height: controlWidth,
    minWidth: controlWidth,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:focus": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  shoppingCartIcon: {
    borderRadius: borderRadius,
    height: controlWidth,
    minWidth: controlWidth,
    //color: theme.palette.secondary.contrastText,
    //backgroundColor: theme.palette.secondary.main,
    //":focus": {
    //  color: theme.palette.secondary.contrastText,
    //  backgroundColor: theme.palette.secondary.main,
    //},
  },
  valueTextWrapper: {
    width: controlWidth + 10,
    minWidth: controlWidth + 10,
    height: controlWidth,
    minHeight: controlWidth,
    backgroundColor: theme.palette.primary.main,
  },
  valueText: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Lato,sans-serif",
    paddingLeft: 0,
    paddingRight: 0,
    width: controlWidth,
    minWidth: controlWidth,
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  container: {
    margin: 8,
  },
}));

const HorizontalUpDownBig = (props) => {
  const { value, onValueChange, min = 0, max = 100, showValue = true, zeroIcon = true } = props;
  const [count, setCount] = useState(value);
  const [upDisabled, setUpDisabled] = useState(false);
  const [downDisabled, setDownDisabled] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    setCount(value);
  }, [value]);

  const handleUp = (event) => {
    const newCount = count + 1;
    changeCount(newCount, event);
  };

  const handleDown = (event) => {
    const newCount = count - 1;
    changeCount(newCount, event);
  };

  const changeCount = (newCount, event) => {
    if (newCount <= max && newCount >= 0) {
      setCount(newCount);

      if (onValueChange) onValueChange(newCount);

      //setUpDisabled(newCount >= max);
      //setDownDisabled(newCount <= min);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  if (count === 0 && zeroIcon) {
    return (
      <div className={classes.root1}>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<ShoppingBasket />} size="large" onClick={handleUp} disabled={upDisabled}>
          {/* <ShoppingBasket fontSize="large" /> */}
          Add to cart
        </Button>
      </div>
    );
  }

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div onClick={handleClick} className={classes.container}>
      <ButtonGroup orientation="horizontal" color="primary" aria-label="button group" className={classes.btgGroup}>
        <IconButton onClick={handleUp} disabled={upDisabled} color="primary" className={classes.upDownBtn}>
          <AddIcon fontSize="middle" />
        </IconButton>
        {showValue && (
          <Chip
            label={count}
            color="primary"
            variant="default"
            classes={{
              root: classes.valueTextWrapper,
              label: classes.valueText,
            }}
          />
        )}
        <IconButton onClick={handleDown} disabled={downDisabled} color="primary" className={classes.upDownBtn}>
          <RemoveIcon fontSize="middle" />
        </IconButton>
      </ButtonGroup>
    </div>
  );
};

export default HorizontalUpDownBig;
