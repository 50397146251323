import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { actionCreators as userActions } from "../../store/actions/user_actions";
import { CheckoutStages } from "../../constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import { ArrowRight } from "@material-ui/icons";
import RadioGroup from "@material-ui/core/RadioGroup";
import HomeButton from "../../controls/home_button";
import LoadingButton from "../../controls/button_with_progress";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "50px",
        //backgroundColor: theme.palette.secondary.light,
    },
    logorow: {
        backgroundColor: theme.palette.secondary.light,
    },
    entry: {
        paddingTop: "20px",
        //color: theme.palette.secondary.contrastText,
    },
    pad: {
        display: "flex",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
    },
    logo: {
        width: "128px",
    },
}));

const PaymentMethods = (props) => {
    const [values, setValues] = useState({
        paymentMethod: "cash",
    });
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:600px)");

    const handleNext = (event) => {
        setLoading(true)
        props.setPaymentMethod(values.paymentMethod);
        props.onNext(CheckoutStages.ORDER);
    };

    let rootStyle = { width: "100%" };
    if (matches) {
        rootStyle.width = "400px";
        //rootStyle.borderRadius = "15px";
        //rootStyle.borderColor = "black";
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    return (
        <div>
            <HomeButton />
            <Grid container justify="center" alignItems="center">
                <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
                    <Grid item className={classes.entry}>
                        <Typography variant="h6">Payment Methods</Typography>
                    </Grid>
                    <Grid item className={classes.entry} style={{ width: "220px" }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Select Payment Method</FormLabel>
                            <RadioGroup aria-label="payment" name="payment" value={values.paymentMethod} onChange={handleChange("paymentMethod")}>
                                <FormControlLabel key="cash" value="cash" control={<Radio />} label="Cash On Delivery" />
                                <FormControlLabel key="checkout" value="checkout" control={<Radio />} label="Checkout Pay" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.entry}>
                        {
                            loading ? (<LoadingButton text='loading' isLoading={loading} onClick={() => { }} />) :
                                (
                                    <Button startIcon={<ArrowRight />} variant="contained" color="primary" onClick={handleNext}>
                                        Next
                                    </Button>
                                )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPaymentMethod: (paymentMethod) => dispatch(userActions.setPaymentMethod(paymentMethod)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
