import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import LocalMall from "@material-ui/icons/LocalMall";
// import CartLine from "./cart_line";
import CartLine2 from './cart_lin2';
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import Divider from "@material-ui/core/Divider";
import List from '@material-ui/core/List';
//import CheckoutBar from "./checkout_bar";
import CheckoutBar2 from './checkout_bar_2';
import { useWindowHeight } from "@react-hook/window-size";

const useStyles = makeStyles((theme) => ({
  mainroot: {
    flexGrow: 1,
    width: "400px",
    height: "100%",
    padding: "70px 10px 10px 10px",
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  checkoutBar: {
    position: "absolute",
    bottom: 5,
    width: "100%",
    padding: "0px 0px 0px 10px",
    display: "flex",
    justifyContent: "center",
  },
  cartlineContainer: {
    flexGrow: 1,
    width: "100%",
    overflow: "auto",
    overflowX: "hidden",
  },
}));

const ShoppingCartDrawerRight = (props) => {
  const classes = useStyles();
  const height = useWindowHeight();

  const {
    cart: { cartLines, total },
    hideShoppingCart,
  } = props;

  const handleCloseShoppingCart = () => {
    hideShoppingCart();
  };

  const handleCheckout = (event) => {
    event.preventDefault();
    event.stopPropagation();
    hideShoppingCart();
    props.push("/checkout");
  };
  const handleContinue = (event) => {
    event.preventDefault();
    event.stopPropagation();
    hideShoppingCart();
  }
  const cartLineCount = `${cartLines.length} Item${cartLines.length > 1 ? "s" : ""}`;
  return (
    <div>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.mainroot} spacing={0}>
        <Grid item container direction="row" justify="space-between" alignItems="center" spacing={0}>
          <Grid item>
            <Grid container direction="row" justify="flex-start">
              <Grid item>
                <LocalMall className={classes.large} />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h6">
                  {cartLineCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleCloseShoppingCart}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%" }} />
        {/* style={{ height: height -145  }} */}
        <Grid item className={classes.cartlineContainer} style={{ height: height - 250 }}>
          <List>
            {cartLines.map((line) => (
              <CartLine2 cartLine={line} key={line.id} />
            ))}
          </List>
          {/* {cartLines.map((line) => (
            <CartLine cartLine={line} key={line.id} />
          ))} */}
        </Grid>
        <Grid item className={classes.root}></Grid>
      </Grid>
      <div className={classes.checkoutBar}>
        <CheckoutBar2 total={total} onClick={handleCheckout} onNext={handleContinue} />
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideShoppingCart: () => dispatch(menuActions.hideShoppingCart()),
    push: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartDrawerRight);
