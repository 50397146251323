import { combineEpics } from "redux-observable";
import { categoriesEpic } from "./categories_epic";
import { itemsEpic } from "./items_epic";
import { merchantEpic } from "./merchant_epic";
import { variantsEpic } from "./variants_epic";
import { requestOTPEpic, verifyOTPEpic } from "./otp_epic";
import { shippingFeesEpic } from "./shipping_fees_epic";
import { loadUserEpic, loadUserAddressEpic } from "./persistant_epic";

const rootEpic = combineEpics(merchantEpic, categoriesEpic, itemsEpic, variantsEpic, requestOTPEpic, verifyOTPEpic, shippingFeesEpic, loadUserEpic, loadUserAddressEpic);
export default rootEpic;
