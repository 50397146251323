import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ShoppingCart from "./shopping_cart";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
    transparentDrawer: {
        background: "transparent",
        boxShadow: "none",
    },
}));

const CartHidden = (props) => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:600px)");
    const shoppingCartDrawerAnchor = matches ? "right" : "bottom";
    const shoppingCartDrawerVariant = matches ? "persistent" : "temporary";
    const toggleShoopingCartDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        props.setShoppingCartState(open);
    };

    return (
        <>
            <Hidden smDown>
                <Drawer anchor={shoppingCartDrawerAnchor} variant={shoppingCartDrawerVariant} open={props.cartIsOpen} onClose={toggleShoopingCartDrawer(shoppingCartDrawerAnchor, false)}>
                    <ShoppingCart />
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <Drawer
                    anchor={shoppingCartDrawerAnchor}
                    variant={shoppingCartDrawerVariant}
                    open={props.cartIsOpen}
                    onClose={toggleShoopingCartDrawer(shoppingCartDrawerAnchor, false)}
                    classes={{
                        paper: classes.transparentDrawer,
                    }}
                >
                    <ShoppingCart />
                </Drawer>
            </Hidden>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        location: state.router.location,
        cartIsOpen: state.menu.ShoppingCartIsOpen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShoppingCartState: (shoppingCartState) => dispatch(menuActions.setShoppingCartState(shoppingCartState)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartHidden);
