import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { actionCreators as userActions, LOAD_USER_INFO, LOAD_USER_ADDRESS } from "../store/actions/user_actions";
import * as LocalStorgeService from "../services/localstorage_service";
import { LoginStatus } from "../constants/";
export const loadUserEpic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_USER_INFO),
        mergeMap(async (action) => {
            const r = await LocalStorgeService.loadUser()
            if (r && r.phoneNo && r.name) {
                const user = {
                    name: r.name,
                    phoneNo: r.phoneNo,
                    status: LoginStatus.LOGGED_IN,
                };
                return userActions.newUserLogin(user)
            }
            return userActions.userLogout()
        })
    );
export const loadUserAddressEpic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_USER_ADDRESS),
        mergeMap(async (action) => {
            const addr = await LocalStorgeService.loadAddress();
            var address = '', city = '';
            if (addr) {
                address = addr.address || ''
                city = addr.city || ''
            }
            return userActions.setUserAddress(address, city)
        })
    );
