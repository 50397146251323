import localforage from "localforage";
import * as memoryDriver from "localforage-driver-memory";

const USER_STORAGE_KEY = "__$USER_STORAGE_KEY$__";
const USER_ADDRESS_KEY = "__$USER_SHIPPING_STORAGE_KEY$__"
//const CART_STORAGE_KEY = "__$CART_STORAGE_KEY$__";
//const SHIPPING_ADDRESS_STORAGE_KEY = "__$SHIPPING_ADDRESS_STORAGE_KEY$__";

export const initializeLocalforage = () => {
  localforage.defineDriver(memoryDriver);
  localforage.setDriver(memoryDriver._driver);

  localforage.config({
    driver: localforage.INDEXEDDB, // WebSQL is Deprecated by W3C
    name: "piti-mcommerance-localstorage",
    version: 2.0,
    size: 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName: "piti_mcommerance_localstorage", // Should be alphanumeric, with underscores.
    description: "Piti MCommerance Localstorage",
  });
};
export const saveUser = async (user) => await localforage.setItem(USER_STORAGE_KEY, { ...user });
export const loadUser = async () => {
  try {
    const value = await localforage.getItem(USER_STORAGE_KEY);
    //  console.log(value);
    return value;
  } catch (err) {
    // console.log(err);
  }
};
export const saveAddress = async (address, city) => await localforage.setItem(USER_ADDRESS_KEY, { address, city });
export const loadAddress = async () => {
  try {
    const value = await localforage.getItem(USER_ADDRESS_KEY);
    return value;
  } catch (err) {
    // console.log(err);
  }
};
export const removeUser = async () => await localforage.removeItem(USER_STORAGE_KEY);
