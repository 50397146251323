import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import numeral from "numeral";
import { SystemData } from "../../constants";
import { Link as RouterLink } from "react-router-dom";
import { actionCreators as cartActions } from "../../store/actions/cart_actions";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import VerticalUpDown from "../../controls/vertical_up_down";
import OutOfStockButton from "../../controls/outofstock_button";
import { formatWithCurrencySymbol, Currency } from "../../utils/currency_utils";
import { push } from "connected-react-router";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  media: {
    height: 140,
  },
  discount: {
    position: "absolute",
    zIndex: 1,
    top: "10px",
    left: "10px",
    backgroundColor: "primary.regular",
    color: "#fff",
    overflow: "hidden",
    padding: "0.25rem 0.5rem",
    fontSize: 12,
    borderRadius: 6,
    pointerEvents: "none",
  },
  discount2: {
    marginTop: 3,
    marginLeft: 3,
    opacity: 0.5,
  },
  itemname: {
    fontSize: 14,
    paddingLeft: "5px",
  },
  itemprice: {
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: "5px",
  },
  priceB4Discount: {
    fontSize: 12,
    textDecoration: "line-through",
  },
  content: {
    fontSize: 14,
    padding: "4px",
  },
}));

export const VariantCard = (props) => {
  const classes = useStyles();
  const { cart, setCartline, index, data, width, hideVariantDrawer, push } = props;

  let { id, name, photo, price, discountPercent = 0, discountValue = 0, enableInventoryControl = true, stockQty = 0 } = data;
  const currencySign = SystemData.DefaultCurrency;
  let outOfStock = false
  if (!stockQty) stockQty = 0
  if (!enableInventoryControl && price < 1) outOfStock = true
  if (enableInventoryControl && stockQty < 1) outOfStock = true
  if (!outOfStock) stockQty = stockQty < 1 ? 100 : stockQty
  const upDownStyle = {
    position: "absolute",
    right: 2,
    bottom: 2,
  };
  let discountLabel = null;
  let b4DiscountLabel = null;

  //discountValue = 500;

  if (discountPercent > 0 || discountValue > 0) {
    let discountValueString = null;
    let b4DiscountValueString = null;
    if (discountPercent > 0) {
      discountValueString = `${discountPercent}% off`;
      b4DiscountValueString = price + price * (discountPercent / 100);
    } else if (discountValue > 0) {
      discountValueString = `${formatWithCurrencySymbol(discountValue)} off`; //`${discountValue}${currencySign} off`;
      b4DiscountValueString = numeral(price + discountValue).format("0,0");
    }

    discountLabel = <Chip size="small" color="secondary" label={discountValueString} className={classes.discount2} />;
    b4DiscountLabel = (
      <Typography variant="subtitle1" display="inline" className={classes.priceB4Discount}>
        ({b4DiscountValueString}
        {currencySign})
      </Typography>
    );
  }

  //const priceString = `${numeral(price).format("0,0")}${currencySign}`;
  const priceString = formatWithCurrencySymbol(price);

  const getCartLine = () => {
    let cartLine = cart.cartLines.find((cl) => cl.id === id);
    if (!cartLine) {
      cartLine = {
        id: id,
        variantName: name,
        itemId: data.parentItem.id,
        itemName: data.parentItem.name,
        price: price,
        count: 0,
        total: 0,
        totalWithModifier: 0,
        modifiers: [],
      };
    }
    return cartLine;
  };

  const handleCountChange = (count) => {
    const currentCartLine = getCartLine();
    currentCartLine.count = count;
    currentCartLine.total = count * currentCartLine.price;
    setCartline(currentCartLine);
  };
  const goToDetail = () => {
    hideVariantDrawer();
    push(`/detail/${data.parentItem.id}`)
  }
  let cartLine = getCartLine();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <div style={upDownStyle}>
          {
            outOfStock ? (
              <OutOfStockButton />
            ) :
              (<VerticalUpDown max={stockQty} value={cartLine.count} onValueChange={handleCountChange} />)
          }
        </div>
        <CardMedia className={classes.media} image={photo} title={name} onClick={goToDetail}>
          {discountLabel}
        </CardMedia>
        <CardContent className={classes.content}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography gutterBottom variant="body2" className={classes.itemname}>
                {name}
              </Typography>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                <Typography gutterBottom variant="caption" className={classes.itemprice}>
                  {priceString}
                </Typography>
                {b4DiscountLabel}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartline: (cartLine) => dispatch(cartActions.setCartline(cartLine)),
    push: (url) => dispatch(push(url)),
    hideVariantDrawer: () => dispatch(menuActions.hideVariantDrawer()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantCard);
