import React, { useState } from "react";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import Avatar from "@material-ui/core/Avatar";
import CallIcon from '@material-ui/icons/Call';
import FileCopy from "@material-ui/icons/FileCopy";
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Alert, AlertTitle } from '@material-ui/lab';
import HomeButton from "../../controls/home_button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CartHidden from "../../components/cart/shopping_cart_hidden";
import { actionCreators as userActions } from "../../store/actions/user_actions";
import { actionCreators as otpActions } from "../../store/actions/otp_actions";
import { CssBaseline } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Card, CardHeader, CardContent, CardMedia, CardActionArea } from "@material-ui/core";
import ContactsIcon from '@material-ui/icons/Contacts';
import { CheckCircle } from "@material-ui/icons";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import YoutubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { OSPlatforms, getCurrentOS } from '../../utils/os_platform_util';
import { SocialTypes } from '../../constants'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "10px",
        paddingLeft: "0px",
        //backgroundColor: theme.palette.secondary.light,
    },
    logorow: {
        backgroundColor: theme.palette.secondary.light,
    },
    entry: {
        paddingTop: "20px",
        //color: theme.palette.secondary.contrastText,
    },
    msg: {
        paddingTop: "20px",
        width: "100%",
    },
    pad: {
        display: "flex",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
    },
    logo: {
        width: theme.spacing(20),
        height: theme.spacing(15),
    },
    card: {
        boxShadow: 'none',
        backgroundColor: 'inherit'
    },
    media: {
        height: 140,
        backgroundColor: theme.palette.secondary.light
    },
    bottomNavigation: {
        backgroundColor: 'inherit',
        spacing: 0,
    }
}));

const Contact = (props) => {

    const classes = useStyles();
    const matches = useMediaQuery("(min-width:600px)");
    let rootStyle = { width: "100%" };
    if (matches) {
        rootStyle.width = "600px";
    }
    const [social, setSocail] = useState('');
    const [copyIndex, setCopyIndex] = useState(-1);
    const copied = (index) => copyIndex === index;
    const platform = getCurrentOS();
    //const cardWidth = !matches ? 400 : 500;
    const maxWidth = 345
    const hasBankAcc = () => {
        if (props.merchant.bankAccounts && Array.isArray(props.merchant.bankAccounts)) return true;
        if (props.merchant.bankAccount && Object.keys(props.merchant.bankAccount).length > 0) return true;
        return false
    }
    const getBankAccs = () => {
        if (props.merchant.bankAccounts && Array.isArray(props.merchant.bankAccounts)) {
            return props.merchant.bankAccounts
        }
        if (props.merchant.bankAccount && Object.keys(props.merchant.bankAccount).length > 0) {
            const bankAccounts = props.merchant.bankAccount
            let arr = []
            Object.keys(bankAccounts).map(key => arr.push({ name: key, number: bankAccounts[key] }))
            return arr
        }
        return []
    }
    const hasSocialAccs = () => {
        if (props.merchant.socailAccounts && Array.isArray(props.merchant.socailAccounts) && props.merchant.socailAccounts.length > 0) return true;
        return false;
    }
    const getSocialLink = (socialType, id) => {
        {/* iso => "fb://profile/1692101410811137" 
            android => fb://page/pitiapp"
            <a class="mobile" href="fb://facewebmodal/f?href=https://it-it.facebook.com/fcbarcelona/">mobile</a>
            web => "https://www.facebook.com/pitiapp"
        */}
        // instagram page
        // <a href="http://instagram.com/_u/{USERNAME}/">Link to Instagram Page</a>
        // Ask user to select application to launch with
        // <a href="instagram://user?username={USERNAME}">Link to Instagram Profile</a>
        // If user installed instagram application : Directly launch page with native application
        // If user not installed instagram application : Do nothing
        // <a href = “instagram://location?id=1”>some location</a>
        // youtube channel 
        //<a href="intent://channel/UCJJw4WZ9iMT8AOX-OCjOyQw/#Intent;scheme=vnd.youtube;package=com.google.android.youtube;S.browser_fallback_url=market://details?id=com.google.android.youtube;end;">youtube channel </a>
        //'https://www.youtube.com/channel/UCAKeTj4MAHgXztgwypCYg7A'
        //twiter 
        //connect to app =>  twitter://user?user_id=id_num
        //direct to page =>  https://twitter.com/USERNAME
        //linkedIn 
        // android => <a href="linkedin://company/[linkedIncomapanyID]">LinkedIn</a>
        // <a href="https://www.linkedin.com/company/[companylinkedinURL]">LinkedIn</a>


        if (socialType === SocialTypes.FACEBOOK) {
            if (platform === OSPlatforms.ANDRIODOS) return `fb://facewebmodal/f?href=https://it-it.facebook.com/${id}/`;//return `fb://page/${id}`
            if (platform === OSPlatforms.IPHONEOS) return `fb://profile/${id}`
            return `https://www.facebook.com/${id}`
        }
        if (socialType === SocialTypes.INSTAGRAM) {
            if (platform === OSPlatforms.ANDRIODOS) return `instagram://user?username=${id}`
            if (platform === OSPlatforms.IPHONEOS) return `instagram://user?username=${id}`
            return `http://instagram.com/_u/${id}/`
        }
        if (socialType === SocialTypes.YOUTUBE) {
            if (platform === OSPlatforms.ANDRIODOS) return `intent://channel/${id}/#Intent;scheme=vnd.youtube;package=com.google.android.youtube;S.browser_fallback_url=market://details?id=com.google.android.youtube;end;`
            if (platform === OSPlatforms.IPHONEOS) return `intent://channel/${id}/#Intent;scheme=vnd.youtube;package=com.google.android.youtube;S.browser_fallback_url=market://details?id=com.google.android.youtube;end;`
            return `https://www.youtube.com/channel/${id}`
        }
        if (socialType === SocialTypes.LINKEDIN) {
            // if (platform === OSPlatforms.ANDRIODOS) return `linkedin://company/${id}`
            // if (platform === OSPlatforms.IPHONEOS) return `linkedin://company/${id}`
            return `https://www.linkedin.com/company/${id}`
        }
        if (socialType === SocialTypes.TWITTER) {
            // if (platform === OSPlatforms.ANDRIODOS) return `twitter://user?user_id=${id}`
            //if (platform === OSPlatforms.IPHONEOS) return `twitter://user?user_id=${id}`
            return `https://twitter.com/${id}`
        }
        return '#'
    }
    let primaryPhone = props.merchant.phone, secondaryPhone = "";
    if (props.merchant.phone && (typeof props.merchant.phone === 'string')) {
        const phones = props.merchant.phone.split(',')
        if (phones.length > 1) {
            primaryPhone = phones[0]
            secondaryPhone = phones[1]
        }
    }
    return (
        <div>
            <HomeButton />
            <CssBaseline />
            <Grid container justify="center" alignItems="center">
                <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle} spacing={2}>
                    <Grid item >
                        <Card style={{ maxWidth: 345 }} className={classes.card}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    className={classes.media}
                                    image={props.merchant.logo}
                                    title="Merchant Logo"
                                />
                                <CardContent alignItems='center'>
                                    <Typography gutterBottom variant="h5" component="h2" style={{ textAlign: 'center' }}>
                                        {props.merchant.businessName}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item >
                        <Card
                            style={{ maxWidth, width: 330 }}
                            className={classes.card}
                        >
                            <CardHeader
                                avatar={<ContactsIcon />}
                                title={<h4>Contact Information</h4>}
                                style={{ height: 0 }}
                            />
                            <CardContent style={{ padding: 0 }} >
                                <List>
                                    <ListItem >
                                        <ListItemIcon>
                                            <CallIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <a href={`tel:${primaryPhone}`}>{primaryPhone}</a><br />
                                                    <a href={`tel:${secondaryPhone}`}>{secondaryPhone}</a>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem >
                                        <ListItemIcon>
                                            <LocationOnIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={props.merchant.address} />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    {
                        hasBankAcc() &&
                        <Grid item>
                            <Card
                                style={{ maxWidth, width: 330 }}
                                className={classes.card}>
                                <CardHeader
                                    avatar={<AccountBalanceWalletIcon />}
                                    title={<h4>Bank Information</h4>}
                                    style={{ height: 0 }}
                                />
                                <CardContent style={{ padding: 0 }}>
                                    <List>
                                        {
                                            getBankAccs().map((acc, index) => {
                                                return (
                                                    <ListItem>
                                                        <ListItemText secondary={<span style={{ fontSize: 18, fontWeight: 'bold' }}>{acc.number}</span>} primary={acc.name} />
                                                        <ListItemSecondaryAction>
                                                            <CopyToClipboard
                                                                text={acc.number}
                                                                onCopy={() => setCopyIndex(index)}>
                                                                {
                                                                    copied(index) ? (
                                                                        <IconButton edge="end" color='primary'>
                                                                            <CheckCircle />
                                                                        </IconButton>
                                                                    ) : (
                                                                        <IconButton edge="end" >
                                                                            <FileCopy />
                                                                        </IconButton>
                                                                    )

                                                                }
                                                            </CopyToClipboard>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            })}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Alert severity="info">
                            <AlertTitle>Proof of payment attachment</AlertTitle>
                            Please upload your pay slip <span>&#128195;</span> photo <span>&#128073;</span> <a href='/order'>Click here!</a>
                        </Alert>
                    </Grid>
                    {

                        hasSocialAccs() &&

                        <Grid item>
                            <Card
                                style={{ maxWidth, minWidth: 330 }}
                                className={classes.card}
                            >
                                <CardHeader
                                    //avatar={<PeopleIcon />}
                                    title={<Typography variant='subtitle2' style={{ textAlign: 'left' }}><i>Follow Us</i></Typography>}
                                    style={{ height: 0, }}
                                />
                                <CardContent style={{ padding: 0 }} >
                                    <BottomNavigation value={social}
                                        onChange={(event, newValue) => {
                                            setSocail(newValue);
                                        }}
                                        className={classes.bottomNavigation}
                                    >
                                        {
                                            props.merchant.socailAccounts.map(social => {
                                                // [{name: 'facebook', id: 'pitiapp' },
                                                // {name: 'youtube', id: 'UCK8sQmJBp8GCxrOtXWBpyEA' },
                                                // {name: 'linkedin', id: 'googleoperationscenter' },
                                                // //  {name: 'twitter', id: 'taylorswift13' },
                                                // {name: 'instagram', id: 'taylorswift' }
                                                // ].map(social => {
                                                if (social && social.name && social.id) {
                                                    if (social.name === SocialTypes.FACEBOOK) {
                                                        return <a href={getSocialLink(SocialTypes.FACEBOOK, social.id)}>
                                                            <BottomNavigationAction label="Facebook" value={SocialTypes.FACEBOOK} icon={<FacebookIcon fontSize='large' />} />
                                                        </a>
                                                    }
                                                    if (social.name === SocialTypes.INSTAGRAM) {
                                                        return <a href={getSocialLink(SocialTypes.INSTAGRAM, social.id)}>
                                                            <BottomNavigationAction label="Instagram" value={SocialTypes.INSTAGRAM} icon={<InstagramIcon fontSize='large' />} />
                                                        </a>
                                                    }
                                                    if (social.name === SocialTypes.YOUTUBE) {
                                                        return <a href={getSocialLink(SocialTypes.YOUTUBE, social.id)}>
                                                            <BottomNavigationAction label="Youtube" value={SocialTypes.YOUTUBE} icon={<YoutubeIcon fontSize='large' />} />
                                                        </a>
                                                    }
                                                    if (social.name === SocialTypes.LINKEDIN) {
                                                        return <a href={getSocialLink(SocialTypes.LINKEDIN, social.id)}>
                                                            <BottomNavigationAction label="LinkedIn" value={SocialTypes.LINKEDIN} icon={<LinkedInIcon fontSize='large' />} />
                                                        </a>
                                                    }
                                                    if (social.name === SocialTypes.TWITTER) {
                                                        return <a href={getSocialLink(SocialTypes.TWITTER, social.id)}>
                                                            <BottomNavigationAction label="Twitter" value={SocialTypes.TWITTER} icon={<TwitterIcon fontSize='large' />} />
                                                        </a>
                                                    }
                                                    return <></>
                                                }

                                            })
                                        }
                                    </BottomNavigation>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <CartHidden />
        </div >
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        merchant: state.merchant.data,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        replace: (url) => dispatch(replace(url)),
        logout: () => dispatch(userActions.userLogout()),
        clearOtp: () => dispatch(otpActions.clearOtpCompleted()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
