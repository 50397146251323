import { createSelector } from "reselect";

const cartLinesSelector = (state) => state.cart.cartLines;
const currentItemSelector = (state, props) => props.data;

const getCartItemCount = () => {
  return createSelector([cartLinesSelector, currentItemSelector], (cartLines, item) => {
    let filteredCartLines = cartLines.filter((line) => item.itemVariationIds.find((vid) => vid === line.id));

    if (filteredCartLines.length > 0) {
      const varientCount = filteredCartLines.reduce((a, b) => a + b.count, 0);
      return varientCount;
    }
  });
};

export default getCartItemCount;
