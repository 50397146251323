import { pseudoRandomBytes } from "crypto";
import { SystemData } from "../constants";
import numeral from "numeral";

const randomLetter = () => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return alphabet[Math.floor(Math.random() * alphabet.length)];
};

const generateSixDigits = () => {
  const chars = "0123456789";
  const max = Math.floor(256 / chars.length) * chars.length;
  const size = 6;

  let ret = "";
  while (ret.length < size) {
    const buf = pseudoRandomBytes(size - ret.length);
    for (let i = 0; i < buf.length; i++) {
      const x = buf.readUInt8(i);
      if (x < max) {
        ret += chars[x % chars.length];
      }
    }
  }
  return ret;
};

export const generateOrderNumber = () => {
  return randomLetter() + generateSixDigits();
};

export const formatNumber = (number) => `${numeral(number).format("0,0")} ${SystemData.Default3DigitCurrencySign}`;
