import update from "immutability-helper";
import * as Action_Constants from "../actions/merchant_actions";
import { DataLoadingState } from "../../constants";

const initialState = {
  state: DataLoadingState.Uninitialized,
  data: {},
};

export function MerchantReducer(state = initialState, action) {
  switch (action.type) {
    case Action_Constants.GET_MERCHANT: {
      return state.data;
    }
    case Action_Constants.SET_MERCHANT: {
      return update(state, { data: { $set: action.merchant } });
    }
    case Action_Constants.FETCH_MERCHANT_COMPLETED: {
      const { status, data } = action.payload;
      if (status === "Ok") {
        return update(state, {
          state: { $set: DataLoadingState.Loaded },
          data: { $set: data },
        });
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
