import update from "immutability-helper";
import * as Action_Constants from "../actions/item_actions";
import { DataLoadingState } from "../../constants";
import { toDict } from "../../utils/array_util";

const initialState = {
  state: DataLoadingState.Uninitialized,
  data: [],
  filter: {
    category: "",
    searchString: "",
  },
  selectedId: "",
};

export function ItemReducer(state = initialState, action) {
  switch (action.type) {
    case Action_Constants.GET_ITEMS: {
      return state.data;
    }
    case Action_Constants.SET_ITEMS: {
      return update(state, { data: { $set: action.items } });
    }
    case Action_Constants.REMOVE_ITEMS: {
      const itemId2Remove = toDict(action.itemIds);
      const items = state.data.filter((itm) => itemId2Remove[itm.id] === undefined);
      return update(state, { data: { $set: items } });
    }

    case Action_Constants.GET_ITEM_BY_ID: {
      for (var i = 0; i < state.data.length; i++) {
        if (state.data[i].id === action.id) {
          return state.data[i];
        }
      }
      return null;
    }
    case Action_Constants.FETCH_ITEMS_COMPLETED: {
      const { status, data } = action.payload;
      //  console.log("fetched items", data)
      if (status === "Ok") {
        return update(state, {
          state: { $set: DataLoadingState.Loaded },
          data: { $set: data },
          filter: {
            category: { $set: "" },
            searchString: { $set: "" },
          },
        });
      } else {
        return state;
      }
    }
    case Action_Constants.GET_SELECTED_CATEGORY: {
      return state.filter.category;
    }
    case Action_Constants.SET_SELECTED_CATEGORY: {
      return update(state, { filter: { category: { $set: action.category } } });
    }
    case Action_Constants.GET_SEARCH_STRING: {
      return state.filter.searchString;
    }
    case Action_Constants.SET_SEARCH_STRING: {
      return update(state, { filter: { searchString: { $set: action.searchString } } });
    }
    case Action_Constants.GET_SELECTED_ITEM_ID: {
      return state.selectedId;
    }
    case Action_Constants.SET_SELECTED_ITEM_ID: {
      return update(state, { selectedId: { $set: action.id } });
    }
    default:
      return state;
  }
}
