import React from "react";
import { connect } from "react-redux";
import Masonry from "react-masonry-css";
import OrderCard from './order_card';
import Empty from './empty_order';

const colBreakpoints = {
    // default: 8,
    default: 4,
    1500: 5,
    1000: 4,
    450: 1,
};

const OrderList = (props) => {
    return (
        <>
            {
                props.orders.length > 0 ?
                    <>
                        <Masonry breakpointCols={colBreakpoints} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                            {props.orders.map((cardData, idx) => (
                                <OrderCard key={idx} data={cardData} />
                            ))}
                        </Masonry>
                        <div style={{ height: 40 }}>&nbsp;</div>
                    </>
                    : (<Empty />)
            }
        </>
    );
};

const makeMapStateToProps = () => {
    const mapStateToProps = (state, props) => {
        return {
        };
    };
    return mapStateToProps;
};

export default connect(makeMapStateToProps)(OrderList);
