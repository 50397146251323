import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Masonry from "react-masonry-css";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import VariantCard from "./variant_card";
import filterVariants from "../../store/selectors/variant_selector";
import { Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { useWindowWidth } from "@react-hook/window-size";
import FloatingCartSummaryBar from "../../components/cart/floating_cart_summary_bar2";

const useStyles = makeStyles((theme) => ({
  pad: {
    paddingTop: "24px",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    width: "100%",
  },
  conatiner: {
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "35px",
    backgroundColor: "white",
    borderRadius: "15px 15px 0px 0px",
    maxWidth: "1024px",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
}));

const colBreakpoints = {
  default: 8,
  1500: 6,
  1000: 4,
  450: 2,
};

const VariantList = (props) => {
  const classes = useStyles();
  const windowWidth = useWindowWidth();
  const { variants, variantDrawerIsOpen, hideVariantDrawer } = props;

  const { data, item } = variants;

  const handleCloseVariant = () => {
    hideVariantDrawer();
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const containerWidth = Math.min(windowWidth, 1024);

  return (
    <Grid container alignItems="center" justify="center" className={classes.pad} onClick={handleCloseVariant}>
      <Fab color="secondary" aria-label="close" size="medium" className={classes.fabButton} onClick={handleCloseVariant}>
        <Close />
      </Fab>
      <Grid item className={classes.conatiner} onClick={handleClick} style={{ minWidth: containerWidth }}>
        <Typography variant="h6" gutterBottom>
          {item.name}
        </Typography>
        <Masonry breakpointCols={colBreakpoints} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
          {data.map((cardData, idx) => (
            <VariantCard key={idx} data={cardData} />
          ))}
        </Masonry>
      </Grid>
      {variantDrawerIsOpen && <FloatingCartSummaryBar />}
    </Grid>
  );
};

const makeMapStateToProps = () => {
  const filterVariantData = filterVariants();
  const mapStateToProps = (state, props) => {
    return {
      variants: filterVariantData(state, props),
      variantDrawerIsOpen: state.menu.VariantIsOpen,
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideVariantDrawer: () => dispatch(menuActions.hideVariantDrawer()),
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(VariantList);
