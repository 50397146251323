import ejs from "ejs";
import { SystemCurrency } from "../constants";

let currencyTemplate = null;

export const compileTemplate = (template) => {
  currencyTemplate = ejs.compile(template);
};

export const formatWithCurrencySymbol = (value) => {
  if (currencyTemplate == null) return value;

  const data = {
    currency: SystemCurrency.currencySymbol,
    value,
  };
  return currencyTemplate(data);
};

export const formatWithCurrencyCode = (value) => {
  if (currencyTemplate == null) return value;

  const data = {
    currency: SystemCurrency.currencyCode,
    value,
  };
  return currencyTemplate(data);
};
export const formatCurrencyExchangeRate = (value) => {
  if (currencyTemplate == null) return value;
  const data = {
    currency: "MMK",
    value: value * SystemCurrency.exchangeRate
  }
  return currencyTemplate(data);
}
export const formatCurrencyExchangeRateWithSymbol = (value) => {
  if (currencyTemplate == null) return value;
  const data = {
    currency: "K",
    value: value * SystemCurrency.exchangeRate
  }
  return currencyTemplate(data);
}
export const Currency = {
  Symbol: SystemCurrency.currencySymbol,
  Code: SystemCurrency.currencyCode,
  ExchangeRate: SystemCurrency.exchangeRate,
};
