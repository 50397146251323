import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage"
import "firebase/functions";

const pro = {
  apiKey: "AIzaSyBnfir9kTzHTvgK5tMF8Sy_auc2yBm-Cc8",
  authDomain: "df-kirin.firebaseapp.com",
  databaseURL: "https://df-kirin.firebaseio.com",
  projectId: "df-kirin",
  storageBucket: "df-kirin.appspot.com",
  messagingSenderId: "816384609076",
  appId: "1:816384609076:web:51f5040bcfdb16fca0f361",
};
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const analyticsConfig = {
  apiKey: "AIzaSyC0RXlmSZvXsuEoMWbCUPLDqsTHwHgLVkg",
  authDomain: "mcommercev2.firebaseapp.com",
  projectId: "mcommercev2",
  storageBucket: "mcommercev2.appspot.com",
  messagingSenderId: "528657143174",
  appId: "1:528657143174:web:36f21328e857e074754807",
  measurementId: "G-49HJZYDJ8J"
};
const stag = {
  apiKey: "AIzaSyAxQjZH660AoyvBtllcHtMKV2UQuYlcKEY",
  authDomain: "pitiapp-staging.firebaseapp.com",
  databaseURL: "https://pitiapp-staging.firebaseio.com",
  projectId: "pitiapp-staging",
  storageBucket: "pitiapp-staging.appspot.com",
  messagingSenderId: "189699074584",
  appId: "1:189699074584:web:1e5ad93836fffe63c3716d",
  measurementId: "G-ENQ8V8XG3E",
};

export const initializeFirebase = () => {
  firebase.initializeApp({ ...pro });
  const secApp = firebase.initializeApp({ ...analyticsConfig }, "secondary")
  secApp.analytics();
};
export const analyticsSetup = (pageTitle) => {
  const secApp = firebase.app('secondary');
  const anylytics = secApp.analytics();
  anylytics.logEvent("page_view", { page_title: pageTitle });
}
const getCoupon = (data, callbackFunc) => {
  // console.log(data);
  const func = firebase.functions().httpsCallable("getCoupon2");
  func({ ...data })
    .then((result) => {
      const { data } = result;
      // console.log(result);
      let returnValue = null;
      if (data.status === "success") {
        returnValue = {
          success: true,
          unit: data.data.unit,
          value: data.data.value,
        };
        if (data.data.requestId) {
          returnValue = { ...returnValue, requestId: data.data.requestId }
        }
      } else {
        returnValue = {
          success: false,
          message: data.message,
        };
      }
      callbackFunc && callbackFunc(returnValue);
    })
    .catch((error) => {
      //console.log(error);
      callbackFunc &&
        callbackFunc({
          success: false,
          message: error,
        });
    });
};

const submitOrder = (data, callbackFunc) => {
  //console.log(data);
  const func = firebase.functions().httpsCallable("posCheckoutWeb3");
  func({ ...data })
    .then((result) => {
      const { data } = result;
      // console.log(result);
      //console.log(data);

      let returnValue = null;
      if (data.status === "success") {
        returnValue = {
          success: true,
        };
      } else {
        returnValue = {
          success: false,
          message: data.message,
        };
      }
      callbackFunc && callbackFunc(returnValue);
    })
    .catch((error) => {
      // console.log(error);
      callbackFunc &&
        callbackFunc({
          success: false,
          message: error,
        });
    });
};
const updateOrder = (merchantId, orderId, data, callbackFunc) => {
  const func = firebase.functions().httpsCallable("orderByActionType");
  func({
    actionType: 'update',
    id: orderId,
    merchantId,
    authKey: 'ClienAuthKey',
    data: { ...data }
  })
    .then((result) => {
      const { data } = result;
      let returnValue = null;
      if (data.success === true) {
        returnValue = {
          success: true,
        };
      } else {
        returnValue = {
          success: false,
          message: data.message,
        };
      }
      callbackFunc && callbackFunc(returnValue);
    })
    .catch((error) => {
      // console.log(error);
      callbackFunc &&
        callbackFunc({
          success: false,
          message: error,
        });
    });
};
export const getMemberPoint = async (merchantId, phone, callbackFunc) => {
  const func = firebase.functions().httpsCallable('getGrouponMember');
  func({ merchantId, phone })
    .then((result) => {
      const { data } = result;
      // console.log(result);
      let returnValue = null;
      if (data.status === "success") {
        returnValue = {
          success: true,
          data: {
            point: data.data.member.pointSystem.point,
            exchangeRate: data.data.groupon.exchangePointSystem.exchangeRate,
            businessName: data.data.merchant.businessName,
            groupPointSystem: { exchangeRate: data.data.groupon.pointSystem.exchangeRate },
            displayName: data.data.member.displayName || phone,
          }
        };
      } else {
        returnValue = {
          success: false,
          message: data.message,
        };
      }
      callbackFunc && callbackFunc(returnValue);
    })
    .catch((error) => {
      console.log(error);
      callbackFunc &&
        callbackFunc({
          success: false,
          message: error,
        });
    });
}
export const getOrderHistory = async (merchantId, phone, callbackFunc) => {
  const func = firebase.functions().httpsCallable('getOrderHistoryByPhone');
  func({ ["merchantId"]: merchantId, ["phone"]: phone })
    .then((result) => {
      const { data } = result;
      //console.log(result);
      let returnValue = null;
      if (data.status === "success") {
        returnValue = {
          success: true,
          data: {
            orders: data.data.orders,
            member: data.data.member || null,
          }
        };
      } else {
        returnValue = {
          success: false,
          message: data.message,
        };
      }
      callbackFunc && callbackFunc(returnValue);
    })
    .catch((error) => {
      console.log(error);
      callbackFunc &&
        callbackFunc({
          success: false,
          message: error,
        });
    });
}
export const firebaseFunctions = {
  getCoupon,
  submitOrder,
  getMemberPoint,
  updateOrder
};
