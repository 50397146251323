import { createSelector } from "reselect";
const itemSelector = (state) => state.items.data;
const variantSelector = (state) => state.variants.data.variants;
const itemCategoryFilterSelector = (state) => state.items.filter.category;
const itemSearchStringSelector = (state) => state.items.filter.searchString
const filterItems = () => {
    return createSelector([itemSelector, itemCategoryFilterSelector, itemSearchStringSelector, variantSelector], (items, category, searchString, variants) => {
        const variantsSearch = (item) => {
            const selectedVariants = item.itemVariationIds.map((vid) => variants[vid]);
            if (selectedVariants && Array.isArray(selectedVariants)) {
                const variants = selectedVariants.filter(vars => vars && vars.name && vars.name.toLowerCase().includes(searchString))
                return variants.length > 0 ? true : false
            }
            return false
        }
        const conditions = (item => {
            if (category && searchString) {
                if (item.category && item.category.id === category && item.name && item.name.toLowerCase().includes(searchString)) return true
                if (item.category && item.category.id === category) return variantsSearch(item)
                return false
            }
            if (category) {
                if (item.category && item.category.id === category) return true;
                return false
            }
            if (searchString) {
                if (item.name && item.name.toLowerCase().includes(searchString)) return true;
                return variantsSearch(item);
            }
            return true
        })
        const compare = ((a, b) => {
            // if (a.createdOn &&
            //     a.createdOn._seconds &&
            //     b.createdOn &&
            //     b.createdOn._seconds) {
            //     if (a.createdOn._seconds > b.createdOn._seconds) return -1;
            //     if (b.createdOn._seconds > a.createdOn._seconds) return 1;
            //     return 0;
            // }
            if (a.updatedAt &&
                a.updatedAt._seconds &&
                b.updatedAt &&
                b.updatedAt._seconds) {
                if (a.updatedAt._seconds > b.updatedAt._seconds) return -1;
                if (b.updatedAt._seconds > a.updatedAt._seconds) return 1;
                return 0;
            }
            return 0;
        })
        return items.filter(conditions).sort(compare);
    });
};
export default filterItems;
