import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Divider, Typography, ListItemSecondaryAction, Collapse } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: "10px 10px",
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    itemText: {
        // color: theme.palette.text.secondary,
    },
    itemPrice: {
        marginTop: 0,
    },
    bigAvatar: {
        // margin: 10,
        width: 100,
        height: 40,
    },
    expandText: {
        marginLeft: theme.spacing(15)
    }
}));

const MemberTierCard = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)
    const { data } = props;
    return (
        <>
            <ListItem  >
                <ListItemIcon>
                    <Avatar variant='square' alt={data.name} src={data.photo} className={classes.bigAvatar} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant='body2' align='center'>
                            {data.name}
                        </Typography>
                    } />
                <ListItemSecondaryAction style={{ marginRight: 0 }}>
                    <IconButton
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem >
            <Collapse in={open} >
                <Typography className={classes.expandText}>
                    {data.name} Member
                </Typography>
                <Typography className={classes.expandText}>
                    {data.autoUpgradePoint || 0}
                </Typography>
                <Typography className={classes.expandText}>
                    {data.privileges || ''}
                </Typography>
            </Collapse>
            <Divider variant='middle' />
        </>
    )
};

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberTierCard);
