import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Toolbar, Typography, Grid, IconButton } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline'
import FilterListIcon from '@material-ui/icons/FilterList';
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import { isCategoryAvailable } from "../cart/utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// const appbarWidth = 40;
const appbarHeight = 40;
const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: "auto",
        bottom: 0,
        width: '100%',
        height: appbarHeight,
        top: theme.spacing(7),
        borderRadius: "0px",
        // borderTopRightRadius: '40',
        opacity: 0.6,
        marginBottom: "20px",
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: "absolute",
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: "0 auto",
    },
    wrapper1: {
        position: "absolute",
        top: 100,
        left: 50,
    },
    itemCountText: {
        paddingTop: "5px",
        paddingLeft: "10px",
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(6),
        height: theme.spacing(6),
    }
}));

const FloatingCategoryBar = (props) => {
    const classes = useStyles();
    const {
        categories,
        selectedCategory,
    } = props;
    const badgeContentCount = (categories && Array.isArray(categories)) ? categories.length : 0
    const matches = useMediaQuery("(min-width:600px)");
    const gridProps = matches ? { spacing: 1, justify: "space-between", alignItems: "center" } : { spacing: 0, justify: "space-between", alignItems: "center" };
    const handleClick = () => {
        if (badgeContentCount > 0) {
            props.showCategoryDrawer();
        }
    };
    if (!isCategoryAvailable(props.location.pathname)) {
        return null;
    }
    const selectName = () => {
        let name = 'All'
        if (categories && Array.isArray(categories)) {
            const index = categories.findIndex(c => c.id === selectedCategory)
            name = index > -1 ? categories[index].name : "All"
        }
        name = name.length > 15 ? `${name.slice(0, 13)}...` : name
        return name;
    }
    const SelectText = (
        <Grid item>
            <Typography variant='body1'>Please Select Category</Typography>
        </Grid>
    )
    const SelectValue = (
        <Grid item>
            <IconButton edge="end" color="inherit" aria-label="menu" >
                <Typography variant='subtitle2'>{selectName()}</Typography>
            </IconButton>
        </Grid>
    )
    const fileterIcon = (
        <Grid item>
            <IconButton edge="end" color="inherit" aria-label="menu" >
                <FilterListIcon fontSize='default' />
            </IconButton>
        </Grid>
    )
    return (
        <React.Fragment>
            <CssBaseline />
            <div style={{ zIndex: 100 }}>
                <AppBar
                    color="primary"
                    className={classes.appBar}
                    position="fixed"
                    style={{ left: 1, opacity: 2 }}
                    onClick={handleClick}
                >
                    <Toolbar variant="dense"
                        style={{ minHeight: appbarHeight }}>
                        <Grid container direction="row" {...gridProps}>
                            {SelectText}
                            {SelectValue}
                            {fileterIcon}
                        </Grid>
                    </Toolbar>

                </AppBar>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        location: state.router.location,
        categories: state.categories.data,
        selectedCategory: state.items.filter.category,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCategoryDrawer: () => dispatch(menuActions.showCategoryDrawer()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloatingCategoryBar);
