import update from "immutability-helper";
import * as Action_Constants from "../actions/variant_actions";
import { DataLoadingState } from "../../constants";
import { defaultItem } from "../../constants";
import { toDict } from "../../utils/array_util";

const initialState = {
  state: DataLoadingState.Uninitialized,
  data: {
    variants: {},
    modifiers: {},
    taxes: {},
    selectedVariantIds: [],
  },
};

// const transformItemData = (taxes, items, variants, modifiers) => {
//   let key = 1;
//   let variantDict = toDict(variants, "id");
//   console.log(variantDict);

//   for (let i = 0; i < items.length; i++) {
//     let { category, name, itemVariationIds, applicableTaxIds, id, photo, description, defaultVariation } = items[i];

//     let itemVariantIds = itemVariationIds || [];
//     if (!applicableTaxIds) applicableTaxIds = [];

//     let defaultVariant = defaultVariation;
//     const { enableInventoryControl = false } = defaultVariant;
//     const [defaultId, defautlPrice] = [defaultVariant.id, defaultVariant.price];

//     let variantArr = [];
//     for (let i2 = 0; i2 < itemVariantIds.length; i2++) {
//       const variantId = itemVariantIds[i2];
//       const variant = variantDict[variantId];
//       if (variant) {
//         let { price, currency, stockQty, unit, points = 0 } = variant;
//         if (!enableInventoryControl && variantId === defaultId && defautlPrice > 0) {
//           stockQty = 20;
//         }
//         if (!variant.enableInventoryControl && price > 0) {
//           stockQty = 20;
//         }

//         variantArr.push({
//           variantName: variant.name,
//           variantId: variantId,
//           photo: variant.photo || photo || defaultItem.photo,
//           applicableTaxIds: applicableTaxIds,
//           taxes: [...taxes],
//           modifiers: [...modifiers],
//           price: price,
//           currency: currency,
//           stockQty: stockQty,
//           unitPoints: points || 0,
//           unit: { ...unit },
//           key: key,
//           _id: id,
//           name: name,
//           description: description || "",
//           category: category && category.name,
//           categoryId: category && category.id,
//         });
//       }
//     }

//     items[i].variants = variantArr;
//   }
//   return items;
// };

export function VariantReducer(state = initialState, action) {
  switch (action.type) {
    case Action_Constants.GET_VARIANTS: {
      const variantArray = Object.values(state.data.variants);
      return variantArray;
    }
    case Action_Constants.SET_VARIANTS: {
      let variantDict = toDict(action.variants, "id");
      return update(state, { data: { variants: { $set: variantDict } } });
    }
    case Action_Constants.GET_VARIANT_BY_IDS: {
      const variants = action.ids.map((key) => state.data.variants[key]);
      return variants;
    }
    case Action_Constants.FETCH_VARIANTS_COMPLETED: {
      const { variants, modifiers, taxes } = action;
      if (variants.status === "Ok" && modifiers.status === "Ok" && taxes.status === "Ok") {
        let variantDict = toDict(variants.data, "id");
        let modifierDict = toDict(modifiers.data, "id");
        let taxDict = toDict(taxes.data, "id");
        return update(state, {
          state: { $set: DataLoadingState.Loaded },
          data: {
            variants: { $set: variantDict },
            modifiers: { $set: modifierDict },
            taxes: { $set: taxDict },
          },
        });
      } else {
        return state;
      }
    }
    case Action_Constants.GET_MODIFIERS: {
      return state.data.modifiers;
    }
    case Action_Constants.GET_TAXES: {
      return state.data.taxes;
    }
    default:
      return state;
  }
}
