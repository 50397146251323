import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { actionCreators as userActions } from "../../store/actions/user_actions";
import { CheckoutStages } from "../../constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import HomeButton from "../../controls/home_button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ArrowRight } from "@material-ui/icons";
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import Alert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "50px",
        //backgroundColor: theme.palette.secondary.light,
    },
    logorow: {
        backgroundColor: theme.palette.secondary.light,
    },
    entry: {
        paddingTop: "20px",
        //color: theme.palette.secondary.contrastText,
    },
    textEntry: {
        paddingTop: "20px",
    },
    pad: {
        display: "flex",
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
    },
    logo: {
        width: "128px",
    },
}));

const ShippingAddress = (props) => {
    const [values, setValues] = useState({
        address: "",
        city: "",
    });
    const [formError, setFormError] = useState({ address: false })
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:600px)");

    const handleNext = (event) => {
        const validate = validateForm()
        if (validate.success) {
            props.setUserAddress('Pick Up', values.address);
            props.onNext(CheckoutStages.PAYMENT);
        } else {
            setFormError({ ...formError, [validate.code]: true })
        }

    };
    const validateForm = () => {
        if (values.address === "") {
            return { success: false, code: 'address' }
        }
        return { success: true }
    }
    const handleBack = () => {
        props.onBack(CheckoutStages.SHIPPING)
    }
    let rootStyle = { width: "100%" };
    if (matches) {
        rootStyle.width = "400px";
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    return (
        <div>
            <HomeButton />
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
                    <Grid item className={classes.entry}>
                        <Typography variant="h6">Shop List</Typography>
                    </Grid>
                    <Grid item className={classes.textEntry} style={{ marginLeft: 15 }}>
                        <FormControl component="fieldset">
                            {/* <FormLabel component="legend">Select Shop</FormLabel> */}
                            <RadioGroup aria-label="payment" name="payment" value={values.address} onChange={handleChange("address")}>
                                {props.merchant.stores
                                    .filter(s => s.status === 'active')
                                    .map((store) => (
                                        <FormControlLabel
                                            key={store.id}
                                            value={store.address}
                                            control={<Radio />}
                                            label={<Typography variant="subtitle2" color='secondary' gutterBottom>{store.address}</Typography>} />
                                    ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {/* <Grid item className={classes.entry}>
                        <Button variant="contained" color="secondary" onClick={handleNext}>
                            Next
                          </Button>
                    </Grid> */}

                    <Grid item className={classes.textEntry} style={{ width: '280px' }} >
                        <Grid container direction='row' spacing={8}>
                            <Grid item >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<ArrowLeft />}
                                    onClick={handleBack}
                                >
                                    Back
                             </Button>
                            </Grid>
                            <Grid item >
                                <Button startIcon={<ArrowRight />} variant="contained" color="primary" onClick={handleNext}>
                                    Next
                          </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {formError.address &&
                        (
                            <Grid item className={classes.entry}>
                                <Alert severity="error">Invalid shop.Please choice shop!</Alert>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserAddress: (address, city) => dispatch(userActions.setUserAddress(address, city)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddress);
