import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ShoppingCartDrawerRight from "./shopping_cart_drawer_right";
import ShoppingCartDrawerBottom from "./shopping_cart_drawer_bottom";

const ShoppingCart = (props) => {
  const matches = useMediaQuery("(min-width:600px)");

  return <>{matches ? <ShoppingCartDrawerRight /> : <ShoppingCartDrawerBottom />}</>;
};

export default ShoppingCart;
