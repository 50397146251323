import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
//import HomeButton from "../../controls/home_button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { formatWithCurrencySymbol } from "../../utils/currency_utils";
import HorizontalUpDownBig from "../../controls/horizontal_up_down_big";
import OutOfStockButton from "../../controls/outofstock_button_big";
import ModifierList from "./modifier_list";
import { actionCreators as cartActions } from "../../store/actions/cart_actions";
import { actionCreators as itemActions } from "../../store/actions/item_actions";
import Masonry from "react-masonry-css";

const useStyles = makeStyles((theme) => ({
  cartRoot: {
    flexGrow: 1,
    maxWidth: 140,
    width: 140,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 160,
      width: 160,
    }
  },
  media: {
    height: 140,
  },
  itemName: {
    paddingLeft: "5px",
  },
  variantCard: {
    width: "100px",
    border: "1px solid black",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  thumbnailClass: {
    // width: "90px",
  },
  image: {
    width: "100px",
  },
  cardContent: {
    padding: "4px",
  },
  msg: {
    paddingTop: "5px",
  },
  variantList: {
    paddingTop: "20px",
    paddingLeft: "12px",
  },
  btn: {
    paddingTop: "5px",
    width: "100%",
  },
  description: {
    paddingTop: '5px',
    paddingLeft: "5px",
    paddingRight: "10px"
  },
  modifierList: {
    paddingLeft: "12px"
  }
}));
const ProductDetail = (props) => {
  const {
    data: { item, variants },
    modifiers,
    setCartline,
    updateSearchString,
  } = props;
  let { name, photo, defaultVariation, photoGallery } = item;
  if (photoGallery) {
    defaultVariation.photoGallery = photoGallery
    if (variants[defaultVariation.id]) {
      variants[defaultVariation.id].photoGallery = photoGallery
    }
  }
  const [modifierList, setModifierList] = useState([])
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(defaultVariation);
  const [currentDescription, setCurrentDescription] = useState(null);

  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  let rootStyle = { width: "100%" };
  let thumbnailPosition = "bottom";

  if (matches) {
    rootStyle.width = "600px";
    //rootStyle.borderRadius = "15px";
    //rootStyle.borderColor = "black";
    thumbnailPosition = "left";
  }


  const getImageList = (variant) => {
    // variant = props.variants[variant.id] ? props.variants[variant.id] : variant
    let images = [];
    if (variant) {
      const galleryPhoto = variant.photo ? variant.photo : photo;
      if (Array.isArray(variant.photoGallery)) {
        images = variant.photoGallery.map((p) => ({
          original: p,
          thumbnail: p,
        }));
      } else {
        images = [
          {
            original: galleryPhoto,
            thumbnail: galleryPhoto,
          },
        ];
      }
    }

    return images;
  };

  const updateImageGallery = (variant) => {
    const images = getImageList(variant);
    setGalleryImages(images);
  };

  useEffect(() => {
    updateImageGallery(selectedVariant);
    updateSearchString('')
    if (selectedVariant && selectedVariant !== undefined && selectedVariant.description) {
      setCurrentDescription(selectedVariant.description);
    } else {
      setCurrentDescription(item.description);
    }
    if (modifiers && item.modifierIds && Array.isArray(item.modifierIds)) {
      let list = []
      item.modifierIds.forEach(mId => {
        const modifier = modifiers[mId]
        if (modifier && modifier.modifiers && Array.isArray(modifier.modifiers)) {
          list = list.concat(...modifier.modifiers.map(v => { return { ...v, checked: false } }))
        }
      });
      setModifierList(list)
    }

  }, [selectedVariant, updateSearchString, item, modifiers]);
  let { stockQty = 0, enableInventoryControl = false } = selectedVariant
  const pvariation = props.variants[selectedVariant.id]
  if (pvariation) stockQty = pvariation.stockQty || 0
  let outOfStock = false
  if (!stockQty) stockQty = 0
  if (!enableInventoryControl && selectedVariant.price < 1) outOfStock = true
  if (enableInventoryControl && stockQty < 1) outOfStock = true
  if (!outOfStock) stockQty = stockQty < 1 ? 100 : stockQty
  const handleVariantChange = (variant) => {
    setSelectedVariant(variant);
  };

  const getNewCartLine = () => {
    const cartLine = {
      id: selectedVariant.id,
      variantName: selectedVariant.name,
      itemId: item.id,
      itemName: item.name,
      price: selectedVariant.price,
      count: 0,
      total: 0,
      wholeTax: 0,
      inclusiveTax: 0,
      modifiers: [],
      totalWithModifier: 0,
    };
    return cartLine;
  };
  const getModifiers = () => {
    return modifierList.filter(m => m.checked === true)
  }
  const handleCountChange = (count) => {
    const cartline = props.cartLines.find((cl) => cl.id === selectedVariant.id);
    const _modifiers = getModifiers();
    let modifierTotalPrice = 0
    _modifiers.map(m => modifierTotalPrice += m.amount.value)
    if (cartline !== undefined) {
      cartline.count = count;
      cartline.total = count * cartline.price;
      cartline.modifiers = _modifiers;
      cartline.totalWithModifier = modifierTotalPrice
      setCartline(cartline);
    } else {
      const newCartLine = getNewCartLine();
      newCartLine.count = count;
      newCartLine.modifiers = _modifiers;
      newCartLine.totalWithModifier = modifierTotalPrice
      newCartLine.total = count * selectedVariant.price;
      setCartline(newCartLine);
    }
  };
  const getCartLinesCount = () => {
    const cartline = props.cartLines.find((cl) => cl.id === selectedVariant.id);
    return cartline !== undefined ? cartline.count : 0;
  };
  const setModifiers = (text, checked) => {
    let list = modifierList
    // update to local state for count change 
    const index = list.findIndex(m1 => m1.text === text)
    if (index > -1) { list[index].checked = checked }
    setModifierList(list)
    // check already exist in cartline
    const isExistCartLine = getCartLinesCount() > 0
    if (isExistCartLine) {
      const cartline = props.cartLines.find((cl) => cl.id === selectedVariant.id)
      // update origin cartline modifiers
      const modifiers = getCartLinesModifier()
      const index = modifiers.findIndex(m1 => m1.text === text)
      if (index > -1) modifiers[index].checked = checked
      let modifierTotalPrice = 0
      // update cartline modifiers
      const modifier2 = modifiers.filter(m => m.checked === true);
      modifier2.map(m => modifierTotalPrice += m.amount.value)
      cartline.modifiers = modifier2;
      cartline.totalWithModifier = modifierTotalPrice
      // redux state update
      setCartline(cartline)
    }
  }
  const getCartLinesModifier = () => {
    const cartline = props.cartLines.find((cl) => cl.id === selectedVariant.id);
    if (cartline !== undefined && Array.isArray(cartline.modifiers)) {
      const list = modifierList
      cartline.modifiers.forEach(m1 => {
        const index = list.findIndex(m2 => m2.text === m1.text)
        if (index > -1) list[index].checked = m1.checked
      })
      return list
    }
    return modifierList
  }
  const getCurrentDescription = () => {
    if (currentDescription) {
      return currentDescription.split('\n')
    }
    return [currentDescription]
  }
  const SubVariationCard = (props) => {
    const { data } = props;
    const v = data;
    return (
      <Card className={classes.cartRoot}>
        <CardActionArea onClick={(event) => handleVariantChange(v)}>
          <CardMedia className={classes.media} image={v.photo} title={v.name} />
          <CardContent
            classes={{
              root: classes.cardContent,
            }}
          >
            <Typography variant="h6" component="h2">
              {v.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {v.price}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

    );
  }
  const getColumnBreak = () => {
    let cols = variants.length < 5 ? variants.length : 5;
    return {
      default: cols,
      1500: cols,
      1000: cols,
      450: 2,
    }
  }
  return (
    <Grid container justify="center" alignItems="center">
      <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
        <Grid item>
          <ImageGallery
            items={galleryImages}
            thumbnailPosition={thumbnailPosition}
            showPlayButton={false}
          //  onImageLoad={() => console.log("image loading")}
          />
        </Grid>
        <Grid item container direction="row" justify="space-between" alignItems="center">
          <Grid item className={classes.msg}>
            <Typography gutterBottom variant="h5" className={classes.itemName}>
              {name !== selectedVariant.name ? `${name} - ${selectedVariant.name}` : `${name}`}
            </Typography>
          </Grid>
          <Grid item className={classes.msg}>
            <Typography gutterBottom variant="h6" className={classes.itemName}>
              {formatWithCurrencySymbol(selectedVariant.price)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.description}>
          <Typography gutterBottom variant="body2" className={classes.itemName} component='p'>
            {getCurrentDescription().map((text, key) => (<p key={key}>{text}</p>))}
          </Typography>
        </Grid>
        {
          modifierList.length > 0 && (
            <Grid item className={classes.modifierList}>
              <ModifierList modifiers={getCartLinesModifier()} cb={setModifiers} />
            </Grid>
          )
        }
        <Grid item className={classes.btn}>
          {
            outOfStock ? <OutOfStockButton /> :
              <HorizontalUpDownBig max={stockQty} value={getCartLinesCount()} onValueChange={handleCountChange} />
          }
        </Grid>
        {/* <Grid item className={classes.btn}>
            <Typography
              gutterBottom
              variant="body2"
              className={classes.itemName}
            >
              {`${name} - ${selectedVariant.name}`}
            </Typography>
          </Grid> */}
        {/* <Grid item container direction="row" justify="center" alignItems="flex-start" spacing={2} className={classes.variantList}>
          {variants.length > 1 ?
            variants.map((v) => (
              <Grid item>
                <Card className={classes.cartRoot}>
                  <CardActionArea onClick={(event) => handleVariantChange(v)}>
                    <CardMedia className={classes.media} image={v.photo} title={v.name} />
                    <CardContent
                      classes={{
                        root: classes.cardContent,
                      }}
                    >
                      <Typography variant="h6" component="h2">
                        {v.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {v.price}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )) : <Grid item style={{ height: 40 }} />
          }
        </Grid> */}
        <Grid item container direction='row' justify="center" >
          {
            variants.length > 1 ? <Masonry breakpointCols={getColumnBreak()} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
              {variants.map((cardData, idx) => (
                <SubVariationCard key={idx} data={cardData} />
              ))}
            </Masonry> : <Grid item style={{ height: 40 }} />
          }

        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, props) => {
  return {
    cartLines: state.cart.cartLines,
    variants: state.variants.data.variants,
    modifiers: state.variants.data.modifiers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //hideShoppingCart: () => dispatch(menuActions.hideShoppingCart()),
    setCartline: (cartLine) => dispatch(cartActions.setCartline(cartLine)),
    updateSearchString: (searchString) => dispatch(itemActions.setSearchString(searchString)),
    //selectItem: (id) => dispatch(itemActions.setSelectedItemId(id)),
    //showVariantDrawer: () => dispatch(menuActions.showVariantDrawer()),
    //push: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
