import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { push } from "connected-react-router";
import AppBar from "@material-ui/core/AppBar";
import { Toolbar, Typography, Grid } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import { isRewardAvailable } from './utils';
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
const appbarWidth = 33;
const appbarHeight = 120;
const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    appBar: {
        top: "auto",
        right: 0,
        width: appbarWidth,
        height: appbarHeight,
        top: theme.spacing(37),
        borderRadius: "8px 0px 0px 8px",
        opacity: 0.8,
    },
    rewardText: {
        marginRight: 4,
    }
}));

const FloatingRewardSticker = (props) => {
    const classes = useStyles();
    const handleClick = () => {
        props.push('/reward');
    };
    const { merchant } = props
    if (!isRewardAvailable(props.location.pathname)) {
        return null;
    }
    if (!(typeof merchant.rewardIntegrationEnabled === 'boolean')) {
        return null;
    }
    if (merchant && (typeof merchant.rewardIntegrationEnabled === 'boolean')) {
        if (merchant.rewardIntegrationEnabled === false) {
            return null;
        }
    }

    const RewardText = (
        <>
            <Grid item >
                <Typography variant='body1'>R</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>E</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>W</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>A</Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1'>D</Typography>
            </Grid>
        </>
    )
    return (
        <React.Fragment>
            <CssBaseline />
            <div style={{ zIndex: 100 }}>
                <AppBar
                    color="primary"
                    className={classes.appBar}
                    position="fixed"
                    onClick={handleClick}
                >
                    <Toolbar
                        variant="dense"
                        disableGutters={true}
                        style={{ minHeight: appbarHeight }}>
                        <Grid container
                            direction="column"
                            style={{ alignItems: 'center' }}
                        >
                            {RewardText}
                        </Grid>
                    </Toolbar>

                </AppBar>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        location: state.router.location,
        merchant: state.merchant.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCategoryDrawer: () => dispatch(menuActions.showCategoryDrawer()),
        push: (url) => dispatch(push(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloatingRewardSticker);
