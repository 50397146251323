import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from '@material-ui/core/List';
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import { useWindowHeight } from "@react-hook/window-size";
import RewardCard from './reward_card';
import { ListItem } from "@material-ui/core";
import { getOrderHistory } from "../../services/firebase";
import MobileStoreButton from './mobile_store_button';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
const appbarWidth = 400;
const useStyles = makeStyles((theme) => ({
    mainroot: {
        flexGrow: 1,
        width: "100%",
    },
    root: {
        flexGrow: 1,
        width: "100%",
        padding: '20px 0px 0px 0px'
    },
    cartlineContainer: {
        flexGrow: 1,
        width: "100%",
        overflow: "auto",
        paddingLeft: '10px',
        paddingRight: '5px',
        overflowX: "hidden",

    },
    paper: {
        height: 140,
        width: 100,
    },
    appBar: {
        top: "auto",
        bottom: 0,
        width: '100%',
        [theme.breakpoints.up("lg")]: {
            width: theme.spacing(47),
            marginRight: '14px'
        }
    },
    pad: {
        paddingTop: "50px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        width: "100%",
        maxHeight: "900px",
        [theme.breakpoints.up("lg")]: {
            paddingTop: '0px',
        }
    },

    merchantContainer: {
        paddingTop: "2px",
        paddingLeft: "15px",
        paddingRight: "0px",
        paddingBottom: "35px",
        // backgroundColor: theme.palette.primary.main, // "white",
        borderRadius: "15px 15px 0px 0px",
        width: "100%",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down("sm")]: {
            // width: 348,
            //paddingLeft: '30px',
            // maxWidth: 348,
        }
    },
    extraListItem: {
        height: theme.spacing(15)
    }
}));

const RewardDrawerBottom = (props) => {
    const classes = useStyles();
    const height = useWindowHeight();
    const {
        hideDrawer,
        merchant,
        user
    } = props;
    const { phoneNo } = user
    const merchantId = merchant.id
    const [state, setState] = React.useState({ status: 'loading', data: 0 })
    React.useEffect(() => {
        if (phoneNo && merchantId) {
            getOrderHistory(merchantId, phoneNo, (result) => {
                if (result.success) {
                    const memberPoint = (result.data.member && result.data.member.pointSystem && result.data.member.pointSystem.point) ? result.data.member.pointSystem.point : 0;
                    setState({ status: 'loaded', data: memberPoint })
                } else {
                    setState({ status: 'loaded', data: 0 })
                }
            });
        }
    }, [phoneNo, merchantId])


    const handleClose = () => {
        hideDrawer();
    };
    const compare = ((a, b) => {
        if (a.point &&
            b.point) {
            if (a.point > b.point) return 1;
            if (b.point > a.point) return -1;
            return 0;
        }
        return 0;
    })
    const businessName = merchant.businessName || '';
    const rewards = (merchant.pointRewards || []).sort(compare);
    const exchangeRate = (merchant.pointSystem && merchant.pointSystem.exchangeRate) ? merchant.pointSystem.exchangeRate : '1000';
    return (
        <div>
            <Grid container alignItems="center" justify="center" className={classes.pad} onClick={handleClose} style={{ height: 'inherit' }}>
                <Grid
                    item
                    className={classes.merchantContainer}
                    onClick={() => { }}
                    style={{
                        height: 'inherit',
                        backgroundImage: `url(${merchant.photo})`,
                        objectFit: 'contain'
                    }}
                >
                    <Grid item container direction="row" justify="space-between" alignItems="center" spacing={0}>
                        <Grid item>
                            <Typography variant="h6" color='inherit' >
                                {/* {businessName} */}
                            </Typography>
                        </Grid>
                        {/* <Grid item>
                            <IconButton
                                style={{ backgroundColor: 'white' }}
                                color="primary"
                                aria-label="upload picture"
                                component="span" onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Grid> */}
                    </Grid>
                    {/* <Grid item container direction='column' justify="space-between" alignItems="left">
                        <Grid item>
                            <Typography variant='subtitle1' color='inherit'>
                                Your points
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color='inherit' variant='h5'>
                                {state.data}
                            </Typography>
                        </Grid>
                    </Grid> */}
                    <Grid item style={{ height: 50 }} />
                </Grid>
                <Grid item style={{ paddingTop: '5px' }}>
                    <Typography variant='h6' >
                        {/* {exchangeRate} Ks = 1 point */}
                        Your have {state.data} Points!
                    </Typography>
                </Grid>
                <Grid item className={classes.cartlineContainer} style={{ height: height - 100 }}>
                    <List>
                        {rewards.map((d) => (
                            <RewardCard data={d} key={d.id} indicator={state.data} />
                        ))}
                        <ListItem className={classes.extraListItem} />
                    </List>
                </Grid>
            </Grid>
            <div>
                <AppBar
                    color="primary"
                    className={classes.appBar}
                    position="fixed"
                >
                    <Toolbar variant="dense" style={{ minHeight: 65, }}>
                        <Grid container alignItems='center' justify='center' direction='row' spacing={3}>
                            <Grid item>
                                <MobileStoreButton store="android" linkProps={{ title: "Android Store Button" }} style={{ width: 100 }} />
                            </Grid>
                            <Grid item >
                                <MobileStoreButton store="ios" linkProps={{ title: "iOS Store Button" }} style={{ width: 100 }} />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {
    return {
        cart: state.cart,
        merchant: state.merchant.data,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideDrawer: () => dispatch(menuActions.hideRewardDrawer()),
        push: (url) => dispatch(push(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardDrawerBottom);
