export const GET_ITEMS = "GET_ITEMS";
export const SET_ITEMS = "SET_ITEMS";
export const REMOVE_ITEMS = "REMOVE_ITEMS";
export const GET_ITEM_BY_ID = "GET_ITEM_BY_ID";
export const FETCH_ITEMS = "FETCH_ITEMS";
export const FETCH_ITEMS_COMPLETED = "FETCH_ITEMS_COMPLETED";

export const GET_SELECTED_CATEGORY = "GET_SELECTED_CATEGORY";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";

export const GET_SEARCH_STRING = "GET_SEARCH_STRING";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";

export const GET_SELECTED_ITEM_ID = "GET_SELECTED_ITEM_ID";
export const SET_SELECTED_ITEM_ID = "SET_SELECTED_ITEM_ID";

export const actionCreators = {
  getItems: () => ({
    type: GET_ITEMS,
  }),

  setItems: (items) => ({
    type: SET_ITEMS,
    items,
  }),

  removeItems: (itemIds) => ({
    type: REMOVE_ITEMS,
    itemIds,
  }),

  getItemById: (id) => ({
    type: GET_ITEM_BY_ID,
    id,
  }),

  fetchItems: (merchantId) => ({
    type: FETCH_ITEMS,
    merchantId,
  }),

  fetchItemsCompleted: (payload) => ({
    type: FETCH_ITEMS_COMPLETED,
    payload,
  }),

  getSelectedCategory: () => ({
    type: GET_SELECTED_CATEGORY,
  }),

  setSelectedCategory: (category) => ({
    type: SET_SELECTED_CATEGORY,
    category,
  }),

  getSearchString: () => ({
    type: GET_SEARCH_STRING,
  }),

  setSearchString: (searchString) => ({
    type: SET_SEARCH_STRING,
    searchString,
  }),

  getSelectedItemId: () => ({
    type: GET_SELECTED_ITEM_ID,
  }),

  setSelectedItemId: (id) => ({
    type: SET_SELECTED_ITEM_ID,
    id,
  }),
};
