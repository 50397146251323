import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
//import { usePositioner, useResizeObserver, useContainerPosition, MasonryScroller } from "masonic";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import CategoryCard from "./category_card";
//import { ProductListUI } from "../../constants";
import { Typography } from "@material-ui/core";
import Masonry from "react-masonry-css";
import Fab from "@material-ui/core/Fab";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
//import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useWindowSize } from "@react-hook/window-size";
const useStyles = makeStyles((theme) => ({
    pad: {
        paddingTop: "24px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        width: "100%",
    },
    conatiner: {
        paddingTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "35px",
        backgroundColor: "white",
        borderRadius: "15px 15px 0px 0px",
        maxWidth: "1024px",
    },
    fabButton: {
        position: "absolute",
        zIndex: 1,
        top: 0,
        left: 0,
        right: 0,
        margin: "0 auto",
    },
}));
const colBreakpoints = {
    default: 8,
    1500: 6,
    1000: 4,
    450: 2,
};

const CategoryList = (props) => {
    const classes = useStyles();
    const { categories, hideCategoryDrawer } = props;
    // const containerRef = React.useRef(null);
    let [windowWidth] = useWindowSize();
    // const matches = useMediaQuery("(min-width:600px)");
    // const { offset, width } = useContainerPosition(containerRef, [windowWidth, windowHeight]);

    // const itemGutter = matches ? ProductListUI.DesktopItemGutter : ProductListUI.ItemGutter;

    // let positioner = usePositioner({ width, columnWidth: ProductListUI.ItemWidth, columnGutter: itemGutter }, [props.categories]);

    // const resizeObserver = useResizeObserver(positioner);
    const handleCloseCategory = () => {
        hideCategoryDrawer();
    };

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const containerWidth = Math.min(windowWidth, 1024);
    return (
        <Grid container alignItems="center" justify="center" className={classes.pad} onClick={handleCloseCategory}>
            <Fab color="secondary" aria-label="close" size="medium" className={classes.fabButton} onClick={handleCloseCategory}>
                <Close />
            </Fab>
            <Grid item className={classes.conatiner} onClick={handleClick} style={{ minWidth: containerWidth }}>
                <Typography variant="h6" gutterBottom>
                    Categories
                </Typography>
                <Masonry breakpointCols={colBreakpoints} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                    {categories.map((cardData, idx) => (
                        <CategoryCard key={idx} data={cardData} />
                    ))}
                </Masonry>
                {/* <MasonryScroller
                    positioner={positioner}
                    resizeObserver={resizeObserver}
                    containerRef={containerRef}
                    items={categories}
                    height={windowHeight}
                    offset={offset}
                    overscanBy={8}
                    columnWidth={ProductListUI.ItemWidth}
                    columnGutter={ProductListUI.ItemGutter}
                    render={CategoryCard}
                /> */}
                {/* <Masonry items={categories} columnWidth={ProductListUI.ItemWidth} columnGutter={ProductListUI.ItemGutter} render={CategoryCard} style={{ outlineColor: "transparent" }} /> */}
            </Grid>
        </Grid>
    );
};

const makeMapStateToProps = () => {
    const mapStateToProps = (state, props) => {
        return {
            CategoryIsOpen: state.menu.CategoryIsOpen,
            categories: state.categories.data,
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCategoryDrawer: () => dispatch(menuActions.showCategoryDrawer()),
        hideCategoryDrawer: () => dispatch(menuActions.hideCategoryDrawer()),
    };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(CategoryList);
