import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, Button, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { formatWithCurrencySymbol } from "../../utils/currency_utils";

const useStyles = makeStyles((theme) => ({
    roundedBar: {
        borderRadius: "20px",
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.light,
        height: 44,
        width: "95%",
        padding: "5px 5px 5px 25px",
        //margin: "0px 0px 0px 30px",
    },
    checkoutLabel: {
        padding: "5px",
    },
    totalString: {
        borderRadius: "15px",
        color: "black",
        backgroundColor: "white",
        padding: "5px 5px 5px 10px",
    },
    card: {
        // width: '95%',
        boxShadow: 'none',
        marginRight: 10,
        backgroundColor: 'none',
    },
    btn: {
        width: '100%',
        height: 40,
    },
    bntGrid1: {
        paddingTop: 1,
    },
    bntGrid2: {
        paddingTop: 5,
        paddingBottom: 2,
    },
    subGrid: {
        // height: 20,
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8,
        color: theme.palette.secondary.contrastText,
    },
    totalGrid: {
        height: 20
    },
    subtotal: {
        height: 20,
    },

}));

const CheckoutBar = (props) => {
    const classes = useStyles();
    const { total, onClick, onNext } = props;
    const totalString = formatWithCurrencySymbol(total); //numeral(total).format("0,0") + " K";

    const handleClick = (event) => {
        if (onClick) {
            onClick(event);
        }
    };
    const handleContinue = (event) => {
        if (onNext) {
            onNext(event)
        }
    }
    return (
        <div classes={classes.card} style={{ width: '90%' }}>
            <Grid container direction="column" justify="space-between" alignItems="left">
                <Grid item className={classes.subGrid}>
                    <List>
                        <ListItem className={classes.subtotal}>
                            <ListItemText primary={<Typography variant="caption">SUBTOTAL:</Typography>}>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Typography variant="h6" ><b>{totalString}</b></Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grid>
                {/* <Grid item className={classes.bntGrid1}>
                    <Button variant="contained" color="primary" className={classes.btn} onClick={handleContinue}> Continue Shopping </Button>
                </Grid> */}
                <Grid item className={classes.bntGrid2}>
                    <Button variant="contained" color="primary" className={classes.btn} onClick={handleClick}> Proceed To Checkout </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default CheckoutBar;
