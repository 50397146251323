import React, { useState } from "react";
import { connect } from "react-redux";
import { replace, push } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardActions from '@material-ui/core/CardActions';
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import List from '@material-ui/core/List';
import MemberTierCard from './member_tier_card2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { CssBaseline, Typography, Card, CardContent, CardHeader, Divider } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "0px",
        paddingLeft: "0px",
        [theme.breakpoints.up("lg")]: {
            paddingTop: '0px'
        }
    },
    welcomeMemberText: {
        paddingTop: '0px',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    welcomeLoginText: {
        paddingTop: 5,
        textAlign: 'center',
        fontWeight: 'unset'
    },

    logorow: {
        backgroundColor: theme.palette.secondary.light,
    },
    loginCheckButton: {
        paddingTop: 0,
        marginLeft: theme.spacing(10),
        alignItems: 'center',
    },

    entry: {
        paddingTop: "20px",
    },
    typography: {
        textAlign: 'center',
    },
    welcomeCard: {
        width: 345,
        maxWidth: 345,
        borderRadius: "10px 10px 10px 10px",
        // boxShadow: 'none',
        backgroundColor: 'white'
    },
    highlightCard: {
        maxWidth: 345,
        borderRadius: "10px 10px 10px 10px",
    },
    earnCard: {
        borderColor: theme.palette.primary.main,
    },
    cardSelected: {
        width: '100%',
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.light,
    },
    media: {
        height: 150,
        backgroundColor: 'transparent',
    },
    tierCard: {
        // backgroundColor: 'transparent',
        // boxShadow: 'none',
        maxWidth: 340,
        minWidth: 340,
        backgroundColor: 'white',
        borderRadius: "10px 10px 10px 10px",
    },
    loginButton: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(10),
        width: theme.spacing(15)
    },
    merchantContainer: {
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "3px",
        justifyItems: 'center',
        background: `linear-gradient(${theme.palette.primary.main} 50%,transparent 50%)`,
        borderRadius: "0px 0px 0px 0px",
        boxShadow: 'none',
        width: "100%",
        // borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        // [theme.breakpoints.down("sm")]: {
        //     width: 348,
        //     //paddingLeft: '30px',
        //     maxWidth: 348,
        // }
    },
}));

const LogoutReward = (props) => {
    const classes = useStyles();
    const { user, merchant } = props;
    const matches = useMediaQuery("(min-width:600px)");
    let rootStyle = { width: "100%" };
    if (matches) {
        rootStyle.width = "600px";
    }
    const goToLogin = () => {
        if (props.redirect) {
            props.push(props.redirect)
        } else {
            props.push('/login?redirect=/reward');
        }
    }
    const onClose = () => {
        if (!matches) {
            props.push('/');
        } else {
            props.showDrawer();
            if (props.cb) {
                props.cb();
            }
        }
    }
    const showEarnAndRedeemDrawer = () => {
        props.showDrawer();
        if (props.cb) {
            props.cb();
        }
    }
    const getDiscover = () => {
        let highlight = '', termAndConditions = '', desc = ''
        if (merchant && merchant.discover && (typeof merchant.discover === 'object')) {
            highlight = merchant.discover.highlight || ''
            termAndConditions = merchant.discover.termsandconditions || ''
            desc = merchant.discover.description || ''
        }
        return { highlight, termAndConditions, desc };
    }
    const memberTiers = merchant.memberTiers || [];
    const businessName = merchant.businessName || '';

    const Widget = (
        <>
            <Grid container direction="row" >
                <Grid
                    item
                    container
                    className={classes.merchantContainer}
                    justify='center'
                    alignItems="center">
                    <Grid item container direction="row" justify="space-between" alignItems="center" spacing={0}>
                        <Grid item style={{ paddingLeft: 5 }}>
                            <Typography variant="body1"  >
                                Welcome to
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                size='small'
                                style={{ backgroundColor: 'whitesmoke' }}
                                color="primary"
                                aria-label="upload picture"
                                component="span" onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row'>
                        <Grid style={{ paddingLeft: 10 }}>
                            <Typography variant="h6" >
                                {businessName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Card className={classes.welcomeCard} variant='outlined'>
                            <CardContent alignItems='center' justify='center'>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    className={classes.welcomeMemberText} >
                                    Welcome member
                                </Typography>

                                <Typography variant="body2" color="text.secondary" className={classes.welcomeLoginText} >
                                    Login to see your current <br />points and available rewards.
                                </Typography>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    alignItems='center'
                                    onClick={goToLogin}
                                    className={classes.loginButton}
                                >
                                    login
                                </Button>
                                {/* <Typography variant="body2" color="text.secondary" className={classes.entry} >
                                    {getDiscover().highlight}
                                </Typography> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.root}
                    style={rootStyle}>
                    <Grid item className={classes.entry}>
                        <Card variant="outlined" className={classes.highlightCard}>
                            <CardHeader
                                title={
                                    <Typography variant='subtitle1' >
                                        {businessName} reward highlight
                                    </Typography>
                                }
                                style={{ paddingTop: 0, textAlign: 'center', fontWeight: 'bold' }}
                            />
                            <CardContent style={{ textAlign: 'center', paddingTop: 0 }}>
                                <Typography align='center' variant="subtitle1" className={classes.typography} >
                                    {getDiscover().highlight}
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" />
                            <CardActions disableSpacing onClick={showEarnAndRedeemDrawer}>
                                <IconButton color="inherit" aria-label="menu" onClick={() => { }}>
                                    <FontAwesomeIcon icon={faGift} />
                                </IconButton>
                                <Typography variant='subtitle1'>
                                    Available Rewards
                                </Typography>
                                <IconButton
                                    style={{ marginLeft: 'auto' }}
                                    onClick={showEarnAndRedeemDrawer}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                    {memberTiers.length > 0 &&
                        <Grid item className={classes.entry}>
                            <Card className={classes.tierCard}>
                                <CardHeader
                                    title={
                                        <Typography variant='subtitle1' >
                                            Member Tiers
                                        </Typography>
                                    }
                                    style={{ padding: 0, textAlign: 'center' }}
                                />
                                <CardContent style={{ paddingTop: 0 }}>
                                    <List>
                                        {memberTiers.map((tier) => (
                                            <MemberTierCard data={tier} />
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    <Grid item style={{ height: 30 }} />
                </Grid>
            </Grid>
        </>
    )
    return (
        <div className={classes.root}>
            <CssBaseline />
            {Widget}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        merchant: state.merchant.data,
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showDrawer: () => dispatch(menuActions.showRewardDrawer()),
        replace: (url) => dispatch(replace(url)),
        push: (url) => dispatch(push(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutReward);
