import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { push } from "connected-react-router";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import RewardWithLogin from "../../components/rewards/reward_with_login";
import RewardWithLogout from '../../components/rewards/reward_with_logout2';
import RewardDrawerBottom from "../../components/rewards/reward_drawer_bottom";
import { LoginStatus } from "../../constants";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
const drawerWidth = 400;
const useStyles = makeStyles((theme) => ({
    transparentDrawer: {
        width: drawerWidth,
        marginTop: theme.spacing(8)
        //background: "transparent",
        // boxShadow: "none",
    },
    list: {
        width: 250,
    },
}));

const RewardHidden = (props) => {
    const classes = useStyles();
    const { user } = props;
    const [widgetToogle, setWidgetToogle] = useState(false);
    const toggleRewardDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        if (open) {
            props.showDrawer();
        } else {
            props.hideDrawer();
        }
    };
    return (
        <>
            <Hidden mdUp>
                <Drawer
                    anchor={'bottom'}
                    open={props.cartIsOpen}
                    onClose={toggleRewardDrawer('bottom', false)}
                    classes={{
                        paper: classes.transparentDrawer,
                    }}
                >
                    <RewardDrawerBottom />
                </Drawer>
            </Hidden>
            <Hidden smDown>
                <Drawer
                    variant='temporary'
                    anchor={'right'}
                    open={props.cartIsOpen}
                    onClose={toggleRewardDrawer('right', false)}
                    classes={{
                        paper: classes.transparentDrawer,
                        root: classes.root,
                    }}
                >
                    {user.status === LoginStatus.LOGGED_IN ? <RewardWithLogin /> : widgetToogle === false ? <RewardWithLogout redirect='/login' cb={() => setWidgetToogle(true)} /> : <RewardDrawerBottom cb={() => setWidgetToogle(false)} />}
                </Drawer>
            </Hidden>
        </>
    );
};

const mapStateToProps = (state) => {
    const { merchant, user } = state;
    return {
        cartIsOpen: state.menu.RewardIsOpen,
        merchant: merchant.data,
        user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideDrawer: () => dispatch(menuActions.hideRewardDrawer()),
        showDrawer: () => dispatch(menuActions.showRewardDrawer()),
        push: (url) => dispatch(push(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardHidden);
