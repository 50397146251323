import React from "react";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from "@material-ui/core/Typography";
import HomeButton from "../../controls/home_button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CssBaseline } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "10px",
        paddingLeft: "5px",
    },
    empty: {
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        justify: "center",
        alignItems: "center"
    },
    paragraph: {
        paddingLeft: 5,
    }

}));

const TermsAndConditions = (props) => {
    const classes = useStyles();
    const { merchant } = props;
    const matches = useMediaQuery("(min-width:600px)");
    let rootStyle = { width: "100%" };
    if (matches) {
        rootStyle.width = "600px";
    }
    const getTerms = () => {
        if (merchant &&
            merchant.termAndConditions &&
            (typeof merchant.termAndConditions === 'string') &&
            merchant.termAndConditions.length > 5
        ) {
            return { success: true, terms: merchant.termAndConditions.split('\n') || [] }
        }
        return { success: false }
    }
    const Page = (
        <>
            <Grid container direction="row" justify="center" alignItems="center">

                <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
                    <Grid item>
                        <Typography>Terms And Conditions</Typography>
                    </Grid>
                    <Typography gutterBottom variant="body2" className={classes.paragraph} component='p'>
                        {getTerms().success ?
                            getTerms().terms.map((text, key) => (<p key={key}>{text}</p>)) :

                            <div />}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
    return (
        <div>
            <HomeButton />
            <CssBaseline />
            {
                getTerms().success ? Page :
                    <div className={classes.empty}>
                        <DescriptionIcon color='primary' style={{ fontSize: 80, opacity: 0.4 }} />
                        <br />
                        <i> terms & conditions</i>
                    </div>
            }
        </div >
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        merchant: state.merchant.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        replace: (url) => dispatch(replace(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
