import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { push } from "connected-react-router";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import RewardWithLogin from "../../components/rewards/reward_with_login";
import RewardWithLogout from '../../components/rewards/reward_with_logout2';
import RewardDrawerBottom from "../../components/rewards/reward_drawer_bottom";
import { LoginStatus } from "../../constants";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
    transparentDrawer: {
        //background: "transparent",
        // boxShadow: "none",
    },
    list: {
        width: 250,
    },
}));

const Reward = (props) => {
    const classes = useStyles();
    const { user } = props;
    const matches = useMediaQuery("(min-width:600px)");
    const toggleRewardDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        if (open) {
            props.showDrawer();
        } else {
            props.hideDrawer();
        }
    };
    if (matches === true) {
        props.push('/');
        return null;
    }
    return (
        <div>
            <Hidden mdUp>
                <Drawer
                    anchor={'bottom'}
                    open={props.cartIsOpen}
                    onClose={toggleRewardDrawer('bottom', false)}
                    classes={{
                        paper: classes.transparentDrawer,
                    }}
                >
                    <RewardDrawerBottom />
                </Drawer>
            </Hidden>
            <Hidden smDown>
                <Drawer
                    anchor={'right'}
                    open={props.cartIsOpen}
                    onClose={toggleRewardDrawer('right', false)}
                    classes={{
                        paper: classes.transparentDrawer,
                    }}
                >
                    {user.status === LoginStatus.LOGGED_IN ? <RewardWithLogin /> : <RewardWithLogout />}
                </Drawer>
            </Hidden>
            {user.status === LoginStatus.LOGGED_IN ? <RewardWithLogin /> : <RewardWithLogout />}

        </div>
    );
};

const mapStateToProps = (state) => {
    const { merchant, user } = state;
    return {
        cartIsOpen: state.menu.RewardIsOpen,
        merchant: merchant.data,
        user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideDrawer: () => dispatch(menuActions.hideRewardDrawer()),
        showDrawer: () => dispatch(menuActions.showRewardDrawer()),
        push: (url) => dispatch(push(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reward);
