import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CheckCircle } from "@material-ui/icons";
import { formatWithCurrencySymbol } from "../../utils/currency_utils";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Card, CardHeader, CardContent } from "@material-ui/core";
const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox
    color="default"
    icon={<CheckBoxOutlineBlankIcon />}
    checkedIcon={<CheckCircle />}
    {...props} />);

class ModifierCheckboxLabels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount() {
        this.setState({ data: this.props.modifiers })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.modifiers })
    }
    handleChange = text => event => {
        const data = this.state.data
        const index = data.findIndex(m => m.text === text)
        if (index > -1) data[index].checked = event.target.checked
        this.props.cb(text, event.target.checked)
        this.setState({ data })
    };
    render() {
        return (
            <Card style={{ boxShadow: 'none' }}>
                <CardHeader
                    title={<h6>Do you want to add?</h6>}
                    style={{ height: 0 }}
                />
                <CardContent>
                    <FormGroup row>
                        {
                            this.state.data.map((m, index) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            m.checked ?
                                                <GreenCheckbox
                                                    onChange={this.handleChange(m.text)}
                                                    checked={m.checked}
                                                    value={m.text}
                                                /> :
                                                <Checkbox
                                                    checked={m.checked}
                                                    onChange={this.handleChange(m.text)}
                                                    icon={<AddCircleIcon />}
                                                    checkedIcon={<CheckCircle />}
                                                    value={m.text}
                                                />
                                        }
                                        label={`${m.text} (${formatWithCurrencySymbol(m.amount.value)})`}
                                    />
                                )
                            })
                        }
                    </FormGroup>
                </CardContent>
            </Card>

        );
    }
}

ModifierCheckboxLabels.propTypes = {
    modifiers: PropTypes.array.isRequired,
    cb: PropTypes.func.isRequired,
};

export default (ModifierCheckboxLabels);

