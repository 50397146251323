import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
// import { useWindowHeight, useWindowWidth } from "@react-hook/window-size";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import { isCartBarAvailable } from "./utils";
import { formatWithCurrencySymbol } from "../../utils/currency_utils";
const appbarWidth = 300;
const appbarHeight = 45;
const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: "auto",
        bottom: 0,
        width: '95%',
        height: appbarHeight,
        borderRadius: "30px",
        opacity: 0.6,
        left: theme.spacing(1),
        right: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            width: appbarWidth,
            left: theme.spacing(75),
        }
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: "absolute",
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: "0 auto",
    },
    wrapper1: {
        position: "absolute",
        top: 100,
        left: 50,
    },
    itemCountText: {
        paddingTop: "5px",
        paddingLeft: "10px",
    },
}));

const FloatingCartSummaryBar = (props) => {
    const classes = useStyles();
    // const height = useWindowHeight();
    // const width = useWindowWidth();
    const {
        cart: { cartLines, total },
    } = props;

    const cardTotalPriceString = formatWithCurrencySymbol(total); // numeral(total).format("0,0") + " K";
    // const appBarLeft = (width - 280) / 2;

    const itemCount = cartLines.filter((cl) => cl.count > 0).length;
    const itemString = `${numeral(itemCount).format("0,0")} ${itemCount < 2 ? "Item" : "Items"}`;

    let barOpacity = itemCount > 0 ? 0.8 : 0.4;
    // const isDetailPage = props.location.pathname.startsWith("/detail/")
    const handleClick = () => {
        //history.push(ShoppingCartRoute);
        if (total > 0) {
            props.showShoppingCart();
        }
    };

    if (!isCartBarAvailable(props.location.pathname)) {
        return null;
    }

    return (
        <React.Fragment>
            <AppBar
                color="primary"
                className={classes.appBar}
                position="fixed"
                onClick={handleClick}
                style={{ opacity: barOpacity }}
            >
                <Toolbar variant="dense" style={{ minHeight: appbarHeight, paddingRight: 3 }}>
                    <FontAwesomeIcon icon={faShoppingBag} size="lg" />
                    <div className={classes.itemCountText}>
                        <Typography>{itemString}</Typography>
                    </div>
                    <div className={classes.grow} />
                    <Chip edge="end" label={cardTotalPriceString} />
                </Toolbar>
            </AppBar>
        </React.Fragment >
    );
};

const mapStateToProps = (state) => {
    return {
        location: state.router.location,
        cart: state.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showShoppingCart: () => dispatch(menuActions.showShoppingCart()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloatingCartSummaryBar);
