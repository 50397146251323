import { createSelector } from "reselect";

const itemSelector = (state) => state.items.data;
const selectedItemSelector = (state) => state.items.selectedId;
const variantSelector = (state) => state.variants.data.variants;
const modifierSelector = (state) => state.variants.data.modifiers;
const taxSelector = (state) => state.variants.data.taxes;

const filterVariants = () => {
  return createSelector([itemSelector, selectedItemSelector, variantSelector, modifierSelector, taxSelector], (items, selectedItemId, variants, modifiers, taxes) => {
    if (selectedItemId) {
      const item = items.find((item) => item.id === selectedItemId);
      if (item) {
        const selectedVariants = item.itemVariationIds.map((vid) => variants[vid]);
        selectedVariants.forEach((variant) => {
          if (variant) {
            if (!variant.photo) {
              variant.photo = item.photo;
            }
            variant.parentItem = item;
          }
        });
        return { data: selectedVariants.includes(undefined) ? [] : selectedVariants, item };
      }
      return {};
    }
  });
};

export default filterVariants;
