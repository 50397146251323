const api_server_url = "https://us-central1-df-kirin.cloudfunctions.net";
const api_server_pos_url = `${api_server_url}/pos`;

export const DataApi = {
  getAllCategory: `${api_server_pos_url}/getAllCategory`,
  getAllItem: `${api_server_pos_url}/getAllItem`,
  getShippingFee: `${api_server_pos_url}/getShippingFee`,
  getAllVariation: `${api_server_pos_url}/getAllVariation2`,
  getAllModifier: `${api_server_pos_url}/getAllModifier`,
  getAllTaxes: `${api_server_pos_url}/getAllTax`,
  getMerchant: `${api_server_pos_url}/getMerchant2`,

  phoneAuth: `${api_server_pos_url}/phoneAuth`,
  phoneVerify: `${api_server_pos_url}/phoneVerify`,

  posCheckoutWeb: `${api_server_url}/posCheckoutWeb`,

  getUrlToMerchant: `${api_server_pos_url}/getUrlToMerchant`,
  //https://us-central1-df-kirin.cloudfunctions.net/pos/getUrlToMerchant/mcommercev2.web.app
};

export const DataLoadingState = {
  Uninitialized: "UNINTIALIZED",
  Loading: "LOADING",
  Loaded: "LOADED",
  Error: "ERROR",
};

export const defaultItem = {
  photo:
    "https://firebasestorage.googleapis.com/v0/b/pitiapp-staging.appspot.com/o/photos%2Fnophoto.jpg?alt=media&token=75655357-4ee1-43bb-aac8-a9f14d5c4bc3",
};

export const SystemData = {
  DefaultCurrency: "K",
  Default3DigitCurrencySign: "MMK",
};

export const ProductListUI = {
  ItemWidth: 160,
  ItemGutter: 10,
  DesktopItemGutter: 60,
};

export let PitiTheme = {
  MainMenuBackColor: "",
  MainMenuTextColor: "",
  CategoryLabelBackColor: "",
  CategoryLabelTextColor: "",
  ItemShoppingCartColor: "",
  ItemCountUpdownColor: "",
};

export const CheckoutStages = {
  SHIPPING: 1,
  PAYMENT: 2,
  ORDER: 3,
  COMPLETE: 4,
};

export const LoginStatus = {
  NONE: "NONE",
  LOGGING_IN: "LOGGING_IN",
  LOGGED_IN: "LOGGED_IN",
  LOGGED_OUT: "LOGGED_OUT",
  ERROR: "ERROR",
};

export const AppBarStyle = {
  NONE: "NONE",
  HOME: "HOME",
  DETAIL: "DETAIL",
};

export const defaultTheme = {
  palette: {
    primary: {
      main: "#303030",
      dark: "#212121",
      light: "#595959",
    },
    secondary: {
      main: "#656565",
      dark: "#464646",
      light: "#838383",
    },
  },
};
export const SocialTypes = {
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram'
}
export const applyMerchantDefaultValues = (data) => {
  if (!data.theme) {
    data.theme = {};
  }

  if (!data.theme.primary) {
    data.theme.primary = { ...defaultTheme.palette.primary };
  }

  if (!data.theme.secondary) {
    data.theme.secondary = { ...defaultTheme.palette.secondary };
  }

  if (!data.bankAccount) {
    data.bankAccount = {
      AYA: "34525424254222",
      KBZ: "23454234526426",
    };
  }

  if (!data.currency) {
    data.currency = {
      ...SystemCurrency,
    };
  }
};

export const SystemCurrency = {
  exchangeRate: 1,
  template: "<%=value?value.toLocaleString():0%><%=currency && currency%>",
  currencySymbol: "K",
  currencyCode: "MMK",
};

export const ProfileNavigators = {
  PROFILE: 'profile',
  ORDERS: 'orders',
}
