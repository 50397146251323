import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
//import { useWindowWidth } from "@react-hook/window-size";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import Grid from "@material-ui/core/Grid";
import { isCartBarAvailable } from "./utils";
import { formatWithCurrencySymbol } from "../../utils/currency_utils";

const appbarWidth = 120;
const appbarHeight = 100;

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    width: appbarWidth,
    height: appbarHeight,
    borderRadius: "8px 0px 0px 8px",
    opacity: 0.6,
    marginBottom: "20px",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  wrapper1: {
    position: "absolute",
    top: 100,
    left: 50,
  },
  itemCountText: {
    paddingTop: "5px",
    paddingLeft: "10px",
  },
}));

const FloatingCartSummarySticker = (props) => {
  const classes = useStyles();
  //const height = useWindowHeight();
  //const width = useWindowWidth();
  const {
    cart: { cartLines, total },
  } = props;

  const cardTotalPriceString = formatWithCurrencySymbol(total); //  numeral(total).format("0,0") + " K";
  // const appBarLeft = (width - 280) / 2;

  const itemCount = cartLines.filter((cl) => cl.count > 0).length;
  const itemString = `${numeral(itemCount).format("0,0")} ${itemCount < 2 ? "Item" : "Items"}`;

  let barOpacity = itemCount > 0 ? 0.8 : 0.4;

  const handleClick = () => {
    //history.push(ShoppingCartRoute);
    if (total > 0) {
      props.showShoppingCart();
    }
  };

  if (!isCartBarAvailable(props.location.pathname)) {
    return null;
  }

  return (
    <React.Fragment>
      <div onClick={handleClick}>
        <AppBar
          color="primary"
          className={classes.appBar}
          position="fixed"
          style={{ top: "35%", right: 0, opacity: barOpacity }}
          onClick={handleClick}
        >
          <Toolbar variant="dense" style={{ minHeight: appbarHeight, paddingRight: 3 }} spacing={5}>
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid item container direction="row" justify="center" alignItems="center">
                <Grid item>
                  <FontAwesomeIcon icon={faShoppingBag} size="lg" />
                </Grid>
                <Grid item>
                  <div className={classes.itemCountText}>
                    <Typography>{itemString}</Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item style={{ paddingTop: 10 }}>
                <Chip edge="end" label={cardTotalPriceString} />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showShoppingCart: () => dispatch(menuActions.showShoppingCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloatingCartSummarySticker);
