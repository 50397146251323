import React from "react";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import HomeButton from "../../controls/home_button";
import Home from "@material-ui/icons/Home";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import { intelMessage } from "../../utils/intel_message_utils";
const defaultThankString = 'Your order number is {order_no}. We have sent sms your order confirmation and will send you and update when your order has shipped.'
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    paddingLeft: "10px",
    //backgroundColor: theme.palette.secondary.light,
  },
  logorow: {
    backgroundColor: theme.palette.secondary.light,
  },
  entry: {
    paddingTop: "20px",
    //color: theme.palette.secondary.contrastText,
  },
  msg: {
    paddingTop: "20px",
    width: "100%",
  },
  pad: {
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  logo: {
    width: "128px",
  },
}));

const OrderComplete = (props) => {
  const classes = useStyles();
  const { data, merchant, user } = props;
  const { phoneNo, name } = user
  const { businessName } = merchant

  const intlVars = {
    customer_phone: phoneNo,
    customer_name: name,
    shop_name: businessName,
    order_no: data.orderNo,
  }
  const res = merchant.thankyouText ? intelMessage(merchant.thankyouText, intlVars) : intelMessage(defaultThankString, intlVars)
  const thanks = res.success ? res.message : `Thank you for your order ${props.orderNo}.`
  const matches = useMediaQuery("(min-width:600px)");

  let rootStyle = { width: "100%" };
  if (matches) {
    rootStyle.width = "600px";
  }

  const handleGoHome = () => {
    props.replace("/");
  };

  return (
    <div>
      <HomeButton />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
          {/* <Grid item className={classes.logorow}>
            <img alt={props.merchant.businessName} src={props.merchant.logo} className={classes.logo} />
          </Grid>
          <Grid item className={classes.entry}>
            <Typography variant="h5">{props.merchant.businessName}</Typography>
          </Grid> */}
          <Grid item className={classes.entry}>
            <Typography variant="h5">Order Completed</Typography>
          </Grid>
          <Grid item className={classes.msg}>
            <Typography variant="h6" align="left">
              Thank you for your order.
            </Typography>
          </Grid>
          <Grid item className={classes.msg}>
            <Typography variant="h6" align="left">
              {/* Your order number is {data.orderNo}. We have sent sms your order confirmation and will send you and update when your order has shipped. */}
              {thanks} <br /><a href='/contact'>view bank information</a>
            </Typography>
          </Grid>
          <Grid item className={classes.entry}>
            <Button variant="outlined" color="secondary" startIcon={<Home />} onClick={handleGoHome}>
              Go Home
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    merchant: state.merchant.data,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    replace: (url) => dispatch(replace(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderComplete);
