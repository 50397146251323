
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Avatar, Slider, CardActions, CardContent, Card } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto'
    },
    margin: {
        height: theme.spacing(3),
    },
    media: {
        height: 120,
    },
    large: {
        backgroundColor: 'white',
        color: 'black',
        border: `2px solid black`,
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    displayName: {
        textAlign: 'center',
        display: 'block'
    },
    pointAndMoneyMain: {
        display: 'flex', width: '100%', height: '100%',
    },
    pointAvatar: {
        color: 'black', margin: 'left', display: 'block', textAlign: 'center', padding: 20
    },
    equalAvatar: {
        color: 'black',
        marginLeft: '15%',
        textAlign: 'center',
        justifyContent: 'center'
    },
    equalAvatarIcon: {
        marginTop: 25, color: 'black', backgroundColor: 'white'
    },
    moneyAvatar: {
        marginLeft: 'auto', textAlign: 'center', padding: 20
    },
    slider: {
        width: 300
    }
}));
export default function PointPay(props) {
    const classes = useStyles();
    const [point, setPoint] = useState(0)
    const onChangeHandler = (p) => {
        props.pointChange(p)
        setPoint(p)
    }
    const incrementPoint = () => {
        const v = point + 1
        props.pointChange(v)
        setPoint(v)
    }
    const decrementPoint = () => {
        const v = point > 0 ? point - 1 : 0
        props.pointChange(v)
        setPoint(v)
    }
    return (
        <Card className={classes.root}>
            <CardContent style={{ width: '100%' }}>
                <div style={{ display: 'block' }}>
                    <div className={classes.displayName}>
                        <b>{props.pointSystem.businessName || 'Piticafe'} </b>
                        <i>({props.pointSystem.displayName || '09425041493'})</i>
                    </div>
                    <div className={classes.pointAndMoneyMain}>
                        <div className={classes.pointAvatar}>
                            <Avatar variant='circle' className={classes.large} >
                                <div style={{ display: 'block' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 14 }}>
                                        {point || 0}
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        points
                                    </div>
                                </div>

                            </Avatar>
                        </div>
                        <div className={classes.equalAvatar}>
                            <Avatar variant='circle' className={classes.equalAvatarIcon}><b style={{ fontSize: 40 }}>=</b></Avatar>
                        </div>
                        <div className={classes.moneyAvatar}>
                            <Avatar variant='circle' className={classes.large} >
                                <div style={{ display: 'block' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 14 }}>
                                        {(point || 0) * (props.pointSystem.exchangeRate || 0)}
                                    </div>
                                    <div style={{ fontSize: 12, }}>
                                        MMK
                                    </div>
                                </div>
                            </Avatar>
                        </div>
                    </div>
                    <div style={{ padding: 0 }}>
                        <b style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }}>
                            You have <i style={{ fontSize: 14 }}>{props.pointSystem.point || 0}</i> points and you can change your points to money .
                            <br /><b style={{ color: 'black' }}> ( 1 point = {props.pointSystem.exchangeRate} MMK)</b>
                        </b>
                    </div>
                </div>
            </CardContent>
            <CardActions >
                <IconButton component="span" onClick={() => decrementPoint()}>
                    <RemoveIcon />
                </IconButton>
                <div className={classes.slider}>
                    <Slider
                        defaultValue={point}
                        aria-labelledby="discrete-slider-always"
                        max={props.pointSystem.point || 100}
                        value={point}
                        onChange={(e, v) => onChangeHandler(v)}
                        valueLabelDisplay="on"
                    />
                </div>
                <IconButton component="span" onClick={() => incrementPoint()}>
                    <AddIcon />
                </IconButton>
            </CardActions>

        </Card >
    );
}
