import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Close from "@material-ui/icons/Close";
import LocalMall from "@material-ui/icons/LocalMall";
import List from '@material-ui/core/List';
// import CartLine from "./cart_line";
import CartLine2 from './cart_lin2';
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import CheckoutBar from "./checkout_bar";
import CheckoutBar2 from './checkout_bar_2';
import { useWindowHeight } from "@react-hook/window-size";
import { ListItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainroot: {
    flexGrow: 1,
    width: "100%",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    padding: '20px 0px 0px 0px'
  },
  cartlineContainer: {
    flexGrow: 1,
    width: "100%",
    overflow: "auto",
    overflowX: "hidden",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  pad: {
    paddingTop: "100px",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    width: "100%",
    maxHeight: "900px",
  },
  conatiner: {
    paddingTop: "15px",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "35px",
    backgroundColor: "#ebebeb", // "white",
    borderRadius: "15px 15px 0px 0px",
    width: "100%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: 65, //0
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  checkoutBar: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: "10px 0px 0px 0px",
    display: "flex",
    justifyContent: "center",
  },
}));

const ShoppingCartDrawerBottom = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const height = useWindowHeight();

  const {
    cart: { cartLines, total },
    hideShoppingCart,
  } = props;

  const handleCloseShoppingCart = () => {
    hideShoppingCart();
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCheckout = (event) => {
    event.preventDefault();
    event.stopPropagation();
    hideShoppingCart();
    props.push("/checkout");
  };
  const handleContinue = (event) => {
    event.preventDefault();
    event.stopPropagation();
    hideShoppingCart();
  }
  const style = matches ? { height: "100%" } : { height: "inherit" };
  const cartLineCount = `${cartLines.length} Item${cartLines.length > 1 ? "s" : ""}`;
  return (
    <div>
      <Grid container alignItems="center" justify="center" className={classes.pad} onClick={handleCloseShoppingCart} style={style}>
        <Fab color="secondary" aria-label="close" size="small" className={classes.fabButton} onClick={handleCloseShoppingCart}>
          <Close />
        </Fab>
        <Grid item className={classes.conatiner} onClick={handleClick} style={style}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.mainroot}
            style={style}
            spacing={0}
          >
            <Grid item style={{ width: "100%" }}>
              <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item>
                  <LocalMall className={classes.large} />
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="body1">
                    {cartLineCount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* style={{ height: height - 205 }} */}
            <Grid item className={classes.cartlineContainer} style={{ height: height - 205 }}>
              <List>
                {cartLines.map((line) => (
                  <CartLine2 cartLine={line} key={line.id} />
                ))}
                <ListItem style={{ height: 60 }} />
              </List>
            </Grid>
            {/* <Grid item style={{ width: "100%", paddingTop: "15px" }}></Grid> */}
            <Grid item className={classes.root} />
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.checkoutBar}>
        <CheckoutBar2 total={total} onClick={handleCheckout} onNext={handleContinue} />
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideShoppingCart: () => dispatch(menuActions.hideShoppingCart()),
    push: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartDrawerBottom);
