import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, map, retryWhen, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { handleError, RetryStrategy } from "./handle_ajax_error";
import { actionCreators as otpActions, REQUEST_OTP, VERIFY_OTP } from "../store/actions/otp_actions";
import { actionCreators as userActions } from "../store/actions/user_actions";
import { GET_DATA_ERROR } from "../store/actions/common_actions";
import { DataApi, LoginStatus } from "../constants/";
import { XMLHttpRequest } from 'xmlhttprequest';
function createXHR() {
  return new XMLHttpRequest();
}
export const requestOTPEpic = (action$, state$) =>
  action$.pipe(
    ofType(REQUEST_OTP),
    mergeMap((action) => {
      const { merchant } = state$.value;
      // console.log(action);
      return ajax({
        createXHR,
        url: `${DataApi.phoneAuth}/${merchant.data.id}/${action.phoneNo}`,
        method: 'GET',
        responseType: 'json',
        crossDomain: true,
        withCredentials: false,
      }).pipe(
        map((response) => otpActions.requestOtpCompleted(response.response, action.userName, action.phoneNo)),
        retryWhen(RetryStrategy),
        catchError((error) =>
          //of(console.log(error.response.message))
          handleError(error, GET_DATA_ERROR)
        )
      );
    })
  );

export const verifyOTPEpic = (action$, state$) =>
  action$.pipe(
    ofType(VERIFY_OTP),
    mergeMap((action) => {
      const { otp } = state$.value;
      // console.log(action);
      return ajax({
        createXHR,
        url: `${DataApi.phoneVerify}/${otp.requestId}/${action.code}`,
        method: 'GET',
        responseType: 'json',
        crossDomain: true,
        withCredentials: false,
      }).pipe(
        mergeMap((res) => {
          const response = res.response
          if (response.status !== "error") {
            const { otp } = state$.value;
            const user = {
              name: otp.requesterName,
              phoneNo: otp.requestPhoneNo,
              status: LoginStatus.LOGGED_IN,
            };
            return of(otpActions.verifyOtpCompleted(response), userActions.newUserLogin(user), userActions.loadUserAddress());
          } else {
            return of(otpActions.verifyOtpCompleted(response));
          }
        }),
        retryWhen(RetryStrategy),
        catchError((error) =>
          //of(console.log(error.response.message))
          handleError(error, GET_DATA_ERROR)
        )
      );
    })
  );
