import React from "react";
import { connect } from "react-redux";
import { makeStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // textAlign: "center",
    // background: "inherit",
    // borderRadius: 6,
    // marginBottom: 20,
    // alignItems: "center",
    // marginTop: 120,
    // width: "100%",
    // height: "100%",
    //  padding: "30px 50px",
    // margin: "20px 0",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50% , -50%)',
  },
}));

const LoadingProgress = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size={50} style={{ color: props.primaryColor }} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  primaryColor: (state.merchant && state.merchant.data && state.merchant.data.theme && state.merchant.data.theme.primaryColor) ? state.merchant.data.theme.primaryColor : "#656565",
});

export default connect(mapStateToProps)(LoadingProgress);
