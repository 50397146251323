// import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import IconButton from "@material-ui/core/IconButton";
import Home from "@material-ui/icons/Home";

const HomeButton = (props) => {
  // const history = useHistory();

  const handleClick = (event) => {
    props.replace("/");
  };
  return (
    <IconButton color="primary" aria-label="home" component="span" onClick={handleClick}>
      <Home fontSize="large" />
    </IconButton>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    replace: (url) => dispatch(replace(url)),
  };
};

export default connect(null, mapDispatchToProps)(HomeButton);
