import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import LoadingProgress from "../components/loading-indicators/loading_progress";
import DataLoadingOverlay from "../components/loading-indicators/data_loading_overlay";
import isInitialDataLoaded from "../store/selectors/loading_status_selector";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { LoginStatus } from "../constants";

const getInitialDataLoadedState = isInitialDataLoaded();

export const initialDataIsLoaded = connectedRouterRedirect({
  redirectPath: "/loading",
  authenticatedSelector: (state) => getInitialDataLoadedState(state),
  authenticatingSelector: (state) => false,
  AuthenticatingComponent: DataLoadingOverlay,
  wrapperDisplayName: "InitialDataIsLoaded",
});

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: "/login",
  authenticatedSelector: ({ user }) => user && user.status === LoginStatus.LOGGED_IN,
  authenticatingSelector: ({ user }) => user && user.status === LoginStatus.LOGGING_IN,
  AuthenticatingComponent: LoadingProgress,
  wrapperDisplayName: "UserIsAuthenticated",
});

const locationHelper = locationHelperBuilder({});
export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || "/",
  allowRedirectBack: false,
  authenticatedSelector: ({ user }) => user && user.status !== LoginStatus.LOGGED_IN,
  wrapperDisplayName: "UserIsNotAuthenticated",
});
