import * as Action_Constants from "../store/actions/user_actions";
import * as LocalStorgeService from "../services/localstorage_service";

const persistantDataMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case Action_Constants.SET_USER_INFO:
      LocalStorgeService.saveUser(action.user);
      break;
    case Action_Constants.LOAD_USER_INFO:
      action.user = {}
      break;
    case Action_Constants.REMOVE_USER_INFO:
      //LocalStorgeService.removeUser();
      break;
    case Action_Constants.SET_ADDRESS:
      if (!((action.address || '').includes('Pick Up') ||
        (action.address || '').includes('Table No'))
      ) {
        LocalStorgeService.saveAddress(action.address || '', action.city || '')
      }
      break;
    case Action_Constants.NEW_USER_LOGIN:
      // const savedUser = LocalStorgeService.loadUser();
      // if (savedUser.phoneNo === action.user.phoneNo) {
      //   action.user = savedUser;
      // }
      action.user.time = new Date();
      LocalStorgeService.saveUser(action.user)
      break;
    case Action_Constants.USER_LOGOUT:
      LocalStorgeService.removeUser()
      break;
    default:
      break;
  }
  next(action);
};

export default persistantDataMiddleware;
