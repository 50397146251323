import React from "react";
import { connect } from "react-redux";
import { push, goBack, replace } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Close from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import { LoginStatus } from "../../constants";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        width: "100%",
    },
}));

const TAB3 = (props) => {
    const classes = useStyles();
    const { user } = props
    const goHome = () => {
        props.replace('/')
    }
    const appBarTitle = user.status === LoginStatus.LOGGED_IN ? 'Point Detail' : 'Welcome from membership program';
    if (user.status !== LoginStatus.LOGGED_IN) {
        return null
    }
    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar variant='dense'>
                    <Grid item container direction="row" justify="space-between" alignItems="center" spacing={0}>
                        <Grid item>
                            <Typography variant="subtitle2" style={{ textAlign: 'center' }} >
                                {appBarTitle}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" aria-label="upload picture" component="span" onClick={goHome}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { menu, merchant, user } = state;
    return {
        location: state.router.location,
        IsOpen: menu.IsOpen,
        merchant: merchant.data,
        user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        push: (path) => dispatch(push(path)),
        goBack: () => dispatch(goBack()),
        replace: (url) => dispatch(replace(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TAB3);
