import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
// import CategoryList from "../../components/category/category_list";
import ProductList from "../../components/product/product_list";
import VariantList from "../../components/product/variant_list";
import ShoppingCart from "../../components/cart/shopping_cart";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import CategoryListVertical2 from "../../components/category/category_list_vertical2";
import CategoryListBottom from "../../components/category/category_list_drawer_bottom";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  pad: {
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  transparentDrawer: {
    background: "transparent",
    boxShadow: "none",
  },
  mobileProductList: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(10),
    }
  },
  desktopProductList: {
    paddingTop: theme.spacing(1)
  }

}));

const ProductListPage = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const shoppingCartDrawerAnchor = matches ? "right" : "bottom";
  const shoppingCartDrawerVariant = matches ? "persistent" : "temporary";
  const toggleShoopingCartDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    props.setShoppingCartState(open);
  };

  const variantDrawerAnchor = "bottom";
  const toggleVariantDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    if (open) {
      props.showVariantDrawer();
    } else {
      props.hideVariantDrawer();
    }
  };
  const toggleCategoryDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    if (open) {
      props.showCategoryDrawer();
    } else {
      props.hideCategoryDrawer();
    }
  };
  const style = matches ? { paddingLeft: "270px", paddingTop: "80px" } : null;

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.pad} style={style}>
        <Grid container direction="column">
          <Hidden mdUp>
            <Grid item style={{ width: "100%", height: 10 }}>
              {/* <CategoryList /> */}
            </Grid>
          </Hidden>
          <Grid item className={classes.mobileProductList}>
            <ProductList />
          </Grid>
        </Grid>
      </div>
      <Hidden smDown>
        <Drawer anchor="left" variant="permanent" open={true}>
          <CategoryListVertical2 />
        </Drawer>
      </Hidden>

      <Hidden smDown>
        <Drawer anchor={shoppingCartDrawerAnchor} variant={shoppingCartDrawerVariant} open={props.cartIsOpen} onClose={toggleShoopingCartDrawer(shoppingCartDrawerAnchor, false)}>
          <ShoppingCart />
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          anchor={shoppingCartDrawerAnchor}
          variant={shoppingCartDrawerVariant}
          open={props.cartIsOpen}
          onClose={toggleShoopingCartDrawer(shoppingCartDrawerAnchor, false)}
          classes={{
            paper: classes.transparentDrawer,
          }}
        >
          <ShoppingCart />
        </Drawer>
      </Hidden>

      <Drawer
        anchor={variantDrawerAnchor}
        open={props.variantDrawerIsOpen}
        onClose={toggleVariantDrawer(variantDrawerAnchor, false)}
        classes={{
          paper: classes.transparentDrawer,
        }}
      >
        <VariantList />
      </Drawer>
      <Hidden mdUp>
        <Drawer
          anchor={variantDrawerAnchor}
          open={props.CategoryIsOpen}
          onClose={toggleCategoryDrawer(variantDrawerAnchor, false)}
          classes={{
            paper: classes.transparentDrawer,
          }}
        >
          <CategoryListBottom />
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    cartIsOpen: state.menu.ShoppingCartIsOpen,
    variantDrawerIsOpen: state.menu.VariantIsOpen,
    CategoryIsOpen: state.menu.CategoryIsOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShoppingCartState: (shoppingCartState) => dispatch(menuActions.setShoppingCartState(shoppingCartState)),
    showVariantDrawer: () => dispatch(menuActions.showVariantDrawer()),
    hideVariantDrawer: () => dispatch(menuActions.hideVariantDrawer()),
    showCategoryDrawer: () => dispatch(menuActions.showCategoryDrawer()),
    hideCategoryDrawer: () => dispatch(menuActions.hideCategoryDrawer()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPage);
