import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { actionCreators as userActions } from "../../store/actions/user_actions";
import { CheckoutStages } from "../../constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Alert from "@material-ui/lab/Alert";
import RadioGroup from "@material-ui/core/RadioGroup";
import HomeButton from "../../controls/home_button";
import LoadingButton from "../../controls/button_with_progress";
import { SystemData } from "../../constants";
import { formatCurrencyExchangeRateWithSymbol, formatWithCurrencyCode } from "../../utils/currency_utils";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import PaymentIcon from '@material-ui/icons/Payment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "10px",
    //backgroundColor: theme.palette.secondary.light,
  },
  logorow: {
    backgroundColor: theme.palette.secondary.light,
  },
  entry: {
    paddingTop: "20px",
    //color: theme.palette.secondary.contrastText,
  },
  pad: {
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  logo: {
    width: "128px",
  },
  card: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: 345,
  },
  selectedColor: {
    color: theme.palette.secondary.main,
  },
  unSelectedCOlor: {
    backgroundColor: 'transparent'
  }
}));

const PaymentMethods = (props) => {
  const [values, setValues] = useState({
    paymentMethod: "cash",
    shippingMethod: "",
  });
  const [loading, setLoading] = useState(false)
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  useEffect(() => {
    let paymentMethod = "cash", shippingMethod = "";
    if (props.shippingFees && Array.isArray(props.shippingFees) && props.shippingFees.length > 0) {
      shippingMethod = props.shippingFees[0].location;
      // setValues({
      //   ...values,
      //   ["shippingMethod"]: props.shippingFees[0].location,
      // });
    }
    if (props.merchant && props.merchant.paymentOptions && Array.isArray(props.merchant.paymentOptions)) {
      const payments = props.merchant.paymentOptions.filter(ms => ms.status === 'active');
      if (payments.length > 0) {
        paymentMethod = payments[0].type;
      }
    }
    setValues({
      ...values,
      paymentMethod,
      shippingMethod,
    });
  }, [props.shippingFees, props.merchant]);

  const handleNext = (event) => {
    setLoading(true)
    props.setPaymentMethod(values.paymentMethod);
    const selectedFee = (props.shippingFees && props.withShipping) ? props.shippingFees.find((fee) => fee.location === values.shippingMethod) : 0;
    props.setShippingMethod(selectedFee);
    props.onNext(CheckoutStages.ORDER);
  };

  let rootStyle = { width: "100%" };
  if (matches) {
    rootStyle.width = "400px";
    //rootStyle.borderRadius = "15px";
    //rootStyle.borderColor = "black";
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const availablePayment = (method) => {
    const { paymentOptions } = props.merchant;
    if (paymentOptions && Array.isArray(paymentOptions)) {
      const payments = paymentOptions.filter(ms => ms.type === method && ms.status === 'active');
      return payments.length > 0;
    }
    return true;
  }
  const formatShippingFee = (fee) => {
    let amount = fee.amount <= 0 ? "(Free)" : fee.amount === 1 ? '' : `(${fee.amount} ${SystemData.Default3DigitCurrencySign})`;//formatWithCurrencyCode(fee.amount);
    return `${fee.location} ${amount}`;
  };
  const selectedShipping = (location) => values && values.shippingMethod && values.shippingMethod === location;
  const hasExtraInfo = props.merchant && props.merchant.paymentAndShippingInfo && props.merchant.paymentAndShippingInfo.length > 3;
  return (
    <div>
      <HomeButton />
      <Grid container justify="center" alignItems="center">
        <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
          {/* <Grid item className={classes.logorow}>
            <img alt={props.merchant.businessName} src={props.merchant.logo} className={classes.logo} />
          </Grid>
          <Grid item className={classes.entry}>
            <Typography variant="h6">{props.merchant.businessName}</Typography>
          </Grid> */}
          <Grid item className={classes.entry}>
            <Typography variant="h6">Payment Methods</Typography>
          </Grid>
          {/* <Grid item className={classes.entry} style={{ width: "220px" }}> */}
          <Grid item className={classes.entry}  >
            <Card className={classes.card} style={{ width: 330 }}>
              <CardHeader
                avatar={<PaymentIcon />}
                title={<h4>Select Payment Method</h4>}
                style={{ height: 0 }}
              />
              <CardContent>
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">Select Payment Method</FormLabel> */}
                  <RadioGroup aria-label="payment" name="payment" value={values.paymentMethod} onChange={handleChange("paymentMethod")}>
                    <List>
                      {
                        availablePayment('cash') && <ListItem style={{ padding: 0 }}>
                          <FormControlLabel key="cash" value="cash" control={<Radio />} label="Cash On Delivery" />
                        </ListItem>
                      }
                      {
                        availablePayment('bank') && <ListItem style={{ padding: 0 }}>
                          <FormControlLabel key="bank" value="bank" control={<Radio />} label="Bank Transfer" />
                        </ListItem>
                      }

                    </List>
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Card>

          </Grid>
          {(props.shippingFees && props.withShipping) && (
            // <Grid item className={classes.entry} style={{ width: "220px" }}>
            <Grid item>
              <Card className={classes.card} style={{ width: 330 }}>
                <CardHeader
                  avatar={<LocalShippingIcon />}
                  title={<h4>Select Shipping Method</h4>}
                  style={{ height: 0 }}

                />
                <CardContent>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="payment" name="payment" value={values.shippingMethod} onChange={handleChange("shippingMethod")}>
                      <List >
                        {props.shippingFees.map((fee) => (
                          <ListItem style={{ padding: 0 }} className={selectedShipping(fee.location) ? classes.selectedColor : classes.unSelectedCOlor}><FormControlLabel key={fee.location} value={fee.location} control={<Radio />} label={formatShippingFee(fee)} /></ListItem>
                        ))}
                      </List>
                    </RadioGroup>
                  </FormControl>
                </CardContent>
              </Card>
              {/* <FormControl component="fieldset">
                <FormLabel component="legend">Select Shipping Method</FormLabel>
                <RadioGroup aria-label="payment" name="payment" value={values.shippingMethod} onChange={handleChange("shippingMethod")}>
                  <List >
                    {props.shippingFees.map((fee) => (
                      <ListItem style={{ padding: 0 }}><FormControlLabel key={fee.location} value={fee.location} control={<Radio />} label={formatShippingFee(fee)} /></ListItem>
                    ))}
                  </List>
                </RadioGroup>

              <RadioGroup aria-label="payment" name="payment" value={values.shippingMethod} onChange={handleChange("shippingMethod")}>
                  {props.shippingFees.map((fee) => (
                    <FormControlLabel key={fee.location} value={fee.location} control={<Radio />} label={formatShippingFee(fee)} />
                  ))}
                </RadioGroup>
              </FormControl> */}
            </Grid>
          )}
          <Grid item>
            {
              hasExtraInfo && <Alert severity="info" style={{ width: 300 }}>{props.merchant.paymentAndShippingInfo}</Alert>
            }

          </Grid>
          <Grid item className={classes.entry}>
            {
              loading ? (<LoadingButton text='loading' isLoading={loading} onClick={() => { }} />) :
                (
                  <Button startIcon={<ArrowRight />} variant="contained" color="secondary" onClick={handleNext}>
                    Next
                  </Button>
                )
            }

          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant.data,
    shippingFees: state.shippingFees.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPaymentMethod: (paymentMethod) => dispatch(userActions.setPaymentMethod(paymentMethod)),
    setShippingMethod: (shippingMethod) => dispatch(userActions.setShippingMethod(shippingMethod)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
