import update from "immutability-helper";
import * as Action_Constants from "../actions/category_actions";
import { DataLoadingState } from "../../constants";

const initialState = {
  state: DataLoadingState.Uninitialized,
  data: [],
};

export function CategoryReducer(state = initialState, action) {
  switch (action.type) {
    case Action_Constants.GET_CATEGORIES: {
      return state.data;
    }
    case Action_Constants.SET_CATEGORIES: {
      return update(state, { data: { $set: action.categories } });
    }
    case Action_Constants.GET_CATEGORY_BY_ID: {
      for (var i = 0; i < state.data.length; i++) {
        if (state.data[i].id === action.id) {
          return state.data[i];
        }
      }
      return null;
    }
    case Action_Constants.FETCH_CATEGORIES_COMPLETED: {
      const { status, data } = action.payload;
      if (status === "Ok") {
        return update(state, {
          state: { $set: DataLoadingState.Loaded },
          data: { $set: data },
        });
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
