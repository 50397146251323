import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Helmet } from "react-helmet";
import routes from "./routes";
import updateFavicon from "./services/favicon_update";
import DataLoadingOverlay from "./components/loading-indicators/data_loading_overlay";
import isInitialDataLoaded from "./store/selectors/loading_status_selector";
import "./App.css";
import TopAppBar from "./components/appbars/top_app_bar2";
import MembershipAppBar from './components/appbars/top_app_bar3';
import MainMenu from "./components/main-menu/main_menu";
import FloatingCartSummaryBar from "./components/cart/floating_cart_summary_bar2";
import FloatingCartSummarySticker from "./components/cart/floating_cart_summary_sticker";
import FloadingCategoryBar from './components/category/floating_category_stick';
import FloadingRewardSticker from './components/rewards/floating_reward_sticker';
import RewardHidden from './components/rewards/reward_hidden';
//import WelcomeOrderPopUp from './components/welcomes/customer_order_welcome';
import Footer from "./components/footers/footer";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Hidden from "@material-ui/core/Hidden";
import { defaultTheme } from "./constants";
import { analyticsSetup } from "./services";


const App = (props) => {
  const { history, merchant, pathname } = props; //pathname, search, hash, location,
  //const { pathname, search, hash, location } = props

  // useEffect(() => {
  //   updateFavicon(merchant.favicon);
  // }, []);
  const setUpAnylytics = () => {
    if (props.dataLoaded && props.merchant && props.merchant.businessName) {
      analyticsSetup(props.merchant.businessName);
    }
  }
  let theme = createMuiTheme(defaultTheme);

  const applyMerchantTheme = () => {
    if (merchant.theme) {
      const finalTheme = { ...defaultTheme, ...merchant.theme };
      theme = createMuiTheme(finalTheme);
    }
  };
  const applyFavicon = () => {
    updateFavicon(merchant.favicon);
  }
  let showTopAppBar = true;
  if (pathname.startsWith("/reward")) {
    showTopAppBar = false;
  } else {
    showTopAppBar = true;
  }
  //let appBarStyle = AppBarStyle.NONE;
  // if (pathname === "/") {
  //   showTopAppBar = true;
  //   appBarStyle = AppBarStyle.HOME;
  // } else if (pathname.startsWith("/detail")) {
  //   showTopAppBar = true;
  //   appBarStyle = AppBarStyle.DETAIL;
  // }
  applyFavicon();
  setUpAnylytics();
  applyMerchantTheme();
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{merchant.businessName}</title>

          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
        <DataLoadingOverlay loaded={props.dataLoaded} />
        {showTopAppBar && <TopAppBar />}
        {!showTopAppBar && <MembershipAppBar />}
        <MainMenu />
        {/* <Hidden smDown>
          <TopBannerBar />
        </Hidden> */}
        {/* <WelcomeOrderPopUp /> */}
        <ConnectedRouter history={history}>{routes}</ConnectedRouter>
        <Footer />
        <Hidden mdUp>
          <FloatingCartSummaryBar />
          <FloadingCategoryBar />
          <FloadingRewardSticker />
        </Hidden>
        <Hidden smDown>
          <FloatingCartSummarySticker />
        </Hidden>
        <RewardHidden />
      </ThemeProvider>
    </React.Fragment >
  );
};

App.propTypes = {
  history: PropTypes.object,
};

const makeMapStateToProps = () => {
  const getInitialDataLoadedState = isInitialDataLoaded();
  const mapStateToProps = (state, props) => ({
    location: state.router.location,
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash,
    merchant: state.merchant.data,
    dataLoaded: getInitialDataLoadedState(state),
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(App);
