import * as Action_Constants from "../store/actions/cart_actions";

const calculateTax = (state, cartline) => {
  const { taxes, variants } = state.variants.data;
  const taxesArray = Object.values(taxes);
  const variant = variants[cartline.id];
  const item = state.items.data.find((itm) => itm.id === cartline.itemId);
  const wholeTax = taxesArray.find((t) => t.application === "whole");
  let applicableTaxes = [];

  if (item.applicableTaxIds && Array.isArray(item.applicableTaxIds) && item.applicableTaxIds.length > 0) {
    applicableTaxes = taxesArray.filter((t) => item.applicableTaxIds.includes(t.id));
  }

  // const wholeTaxValue = cartline.count * cartline.price * (wholeTax ? wholeTax.value : 1) * 0.01;
  const wholeTaxValue = wholeTax ? cartline.count * cartline.price * wholeTax.value * 0.01 : 0;
  const inclusiveTaxValue = applicableTaxes.map((t) => cartline.count * cartline.price * ((wholeTax && wholeTax.value) ? wholeTax.value : 0) * 0.01).reduce((a, b) => a + b, 0);
  const totalB4Tax = cartline.count * cartline.price;
  const totalWithTax = totalB4Tax + wholeTaxValue + inclusiveTaxValue;
  return [wholeTaxValue, inclusiveTaxValue, totalB4Tax, totalWithTax];
};

const cartlineTaxMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case Action_Constants.SET_CARTLINE:
      {
        const { totalWithModifier, count } = action.cartLine
        const totalModifierAmount = totalWithModifier * count
        const state = store.getState();
        const taxes = calculateTax(state, action.cartLine);
        action.cartLine.wholeTax = taxes[0];
        action.cartLine.inclusiveTax = taxes[1];
        action.cartLine.total = taxes[2] + totalModifierAmount;
        action.cartLine.totalWithTax = taxes[3] + totalModifierAmount;
      }
      //calculate whole tax -> apply whole tax
      //calculate inclusive tax -> apply applicableTaxIds
      break;
    case Action_Constants.UPDATE_CARTLINE_ITEM_COUNT:

      {
        const state = store.getState();
        const cartLine = {
          ...state.cart.cartLines.find((cl) => cl.id === action.id),
          count: action.count,
        };
        const totalModifierAmount = cartLine.totalWithModifier * cartLine.count
        const taxes = calculateTax(state, cartLine);
        action.wholeTax = taxes[0];
        action.inclusiveTax = taxes[1];
        action.total = taxes[2] + totalModifierAmount;
        action.totalWithTax = taxes[3] + totalModifierAmount;
      }
      //calculate whole tax -> apply whole tax
      //calculate inclusive tax -> apply applicableTaxIds
      break;

    default:
      break;
  }
  next(action);
};

export default cartlineTaxMiddleware;
