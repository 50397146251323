import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import numeral from "numeral";
import { SystemData } from "../../constants";
import { actionCreators as cartActions } from "../../store/actions/cart_actions";
import { actionCreators as itemActions } from "../../store/actions/item_actions";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import VerticalUpDown from "../../controls/vertical_up_down";
import HorizonalLeftRight from "../../controls/outofstock_button";
import filterCartLine from "../../store/selectors/cart_line_selector";
import getCartItemCount from "../../store/selectors/cart_item_count_selector";
import BadgeIconButton from "../../controls/badge_icon_button";
import { push } from "connected-react-router";
import { formatWithCurrencySymbol, Currency } from "../../utils/currency_utils";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    // maxWidth: 240,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  media: {
    height: 140,
    backgroundSize: "contain",
  },
  discount: {
    position: "absolute",
    zIndex: 1,
    top: "10px",
    left: "10px",
    backgroundColor: "primary.regular",
    color: "#fff",
    overflow: "hidden",
    padding: "0.25rem 0.5rem",
    fontSize: 12,
    borderRadius: 6,
    pointerEvents: "none",
  },
  discount2: {
    marginTop: 3,
    marginLeft: 3,
    opacity: 0.5,
  },
  itemname: {
    fontSize: 14,
    paddingLeft: "5px",
  },
  itemprice: {
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: "5px",
  },
  priceB4Discount: {
    fontSize: 12,
    textDecoration: "line-through",
  },
  content: {
    fontSize: 14,
    padding: "4px",
  },
  variantBtn: {
    padding: "12px 0px 0px 12px",
  },
  badgeMargin: {
    margin: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
}));

export const ProductCard = (props) => {
  const classes = useStyles();
  // const [addBtnColor, setAddBtnColor] = useState("inherit");
  const [cartLineCount, setCartLineCount] = useState(0);
  const [cartVariantsCount, setCartVariantsCount] = useState(props.cartItemCount);
  const { cart, setCartline, selectItem, showVariantDrawer, index, data, width, cartItemCount } = props;

  const { id, name, photo, defaultVariation, itemVariationIds } = data;

  let { price, discountPercent = 0, discountValue = 0, enableInventoryControl = false, stockQty } = defaultVariation;
  //get stockQty from primary data
  const pvariantion = props.variants[defaultVariation.id];
  if (pvariantion) stockQty = pvariantion.stockQty || 0;
  const hasVariant = itemVariationIds.length > 1;
  let outOfStock = false;
  if (!stockQty) stockQty = 0;
  if (!enableInventoryControl && price < 1) outOfStock = true;
  if (enableInventoryControl && stockQty < 1) outOfStock = true;
  if (!outOfStock) stockQty = stockQty < 1 ? 100 : stockQty;
  const currencySign = SystemData.DefaultCurrency;
  useEffect(() => {
    if (cart.total === 0) {
      setCartLineCount(0);
      props.hideShoppingCart();
    } else {
      //console.log(defaultVariation.id);
      const currentCartLine = cart.cartLines.find((cl) => cl.id === defaultVariation.id);
      if (currentCartLine !== null && currentCartLine !== undefined) {
        setCartLineCount(currentCartLine.count);
      } else {
        setCartLineCount(0);
      }
    }

    const itemCartLines = cart.cartLines.filter((cl) => cl.itemId === id);
    if (Array.isArray(itemCartLines)) {
      const itemVartiantCount = itemCartLines.reduce((a, b) => a + b.count, 0);
      setCartVariantsCount(itemVartiantCount);
    }
  }, [cart.total, id]);

  // useEffect(() => {
  //   console.log(props.cartLine);
  //   setCartLineCount(props.cartLine ? props.cartLine.count : 0);
  // }, [props.cartLine]);

  const upDownStyle = {
    position: "absolute",
    right: 2,
    bottom: 2,
  };

  let discountLabel = null;
  let b4DiscountLabel = null;

  if (discountPercent > 0 || discountValue > 0) {
    let discountValueString = null;
    let b4DiscountValueString = null;
    if (discountPercent > 0) {
      discountValueString = `${discountPercent}% off`;
      b4DiscountValueString = price + price * (discountPercent / 100);
    } else if (discountValue > 0) {
      discountValueString = `${formatWithCurrencySymbol(discountValue)} off`; //`${discountValue}${currencySign} off`;
      b4DiscountValueString = numeral(price + discountValue).format("0,0");
    }

    discountLabel = <Chip size="small" color="secondary" label={discountValueString} className={classes.discount2} />;
    b4DiscountLabel = (
      <Typography variant="subtitle1" display="inline" className={classes.priceB4Discount}>
        ({b4DiscountValueString}
        {currencySign})
      </Typography>
    );
  }

  //const priceString = `${numeral(price).format("0,0")}${currencySign}`;
  const priceString = formatWithCurrencySymbol(price);

  const getNewCartLine = () => {
    const cartLine = {
      id: data.defaultVariation.id,
      variantName: data.defaultVariation.name,
      itemId: data.id,
      itemName: data.name,
      price: data.defaultVariation.price,
      count: 0,
      total: 0,
      wholeTax: 0,
      inclusiveTax: 0,
      totalWithModifier: 0,
      modifiers: [],
    };
    return cartLine;
  };

  const handleOpenVariantDrawer = (event) => {
    if (hasVariant) {
      selectItem(id);
      showVariantDrawer();
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCountChange = (count) => {
    if (props.cartline) {
      props.cartline.count = count;
      props.cartline.total = count * price;
      setCartline(props.cartline);
    } else {
      const newCartLine = getNewCartLine();
      newCartLine.count = count;
      newCartLine.total = count * price;
      setCartline(newCartLine);
    }
  };

  const handleGoToDetail = () => {
    props.hideShoppingCart();
    props.push(`/detail/${id}`);
  };
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia className={classes.media} image={photo} title={name} onClick={handleGoToDetail}>
          {discountLabel}
        </CardMedia>
        <CardContent className={classes.content}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography gutterBottom variant="body2" className={classes.itemname}>
                <span
                  style={{ width: '150px', wordWrap: 'break-word', display: 'inline-block' }}
                >
                  {name}
                </span>
              </Typography>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                <Typography gutterBottom variant="caption" className={classes.itemprice}>
                  {priceString}
                </Typography>
                {b4DiscountLabel}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <div style={upDownStyle}>
        {hasVariant ? (
          <BadgeIconButton badgeValue={cartVariantsCount} onClick={handleOpenVariantDrawer} />
        ) : outOfStock ? (
          <HorizonalLeftRight />
        ) : (
          <VerticalUpDown max={stockQty} value={cartLineCount} onValueChange={handleCountChange} />
        )}
      </div>
    </Card>
  );
};

const makeMapStateToProps = () => {
  const getCurrentCartLine = filterCartLine();
  const getFilteredCartItemCount = getCartItemCount();
  const mapStateToProps = (state, props) => {
    return {
      cartline: getCurrentCartLine(state, props),
      cartItemCount: getFilteredCartItemCount(state, props),
      location: state.router.location,
      variants: state.variants.data.variants,
      cart: state.cart,
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideShoppingCart: () => dispatch(menuActions.hideShoppingCart()),
    setCartline: (cartLine) => dispatch(cartActions.setCartline(cartLine)),
    selectItem: (id) => dispatch(itemActions.setSelectedItemId(id)),
    showVariantDrawer: () => dispatch(menuActions.showVariantDrawer()),
    push: (url) => dispatch(push(url)),
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(ProductCard);
