import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { actionCreators as otpActions } from "../../store/actions/otp_actions";
import Alert from "@material-ui/lab/Alert";
//import Collapse from "@material-ui/core/Collapse";
import ButtonWithProgress from "../../controls/button_with_progress";
import { InputAdornment } from "@material-ui/core";
import HomeButton from "../../controls/home_button";
import { LoginStatus } from "../../constants";
import Timer from './otp_timer';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    //backgroundColor: theme.palette.secondary.light,
  },
  logorow: {
    backgroundColor: theme.palette.secondary.light,
  },
  entry: {
    paddingTop: "20px",
    //color: theme.palette.secondary.contrastText,
  },
  entry2: {
    paddingTop: "10px"
  },
  error: {
    paddingTop: "20px",
    color: "red",
  },
  pad: {
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  logo: {
    width: "128px",
  },
  TextField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  }
}));

const Login = (props) => {
  const [values, setValues] = useState({
    name: "",
    phoneNo: "",
    otp: "",
  });
  const [sentLoading, setSentLoading] = useState(false)
  const [verifyLoading, setVerifyLoading] = useState(false)
  const [expired, setExpired] = useState(false)
  const [loginFormError, setLoginFromError] = useState({ NameError: false, PhoneError: false, OtpError: false })
  const [updatePhone, setUpdatePhone] = useState(false)
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const handleRequestOTP = (event) => {
    //console.log(`${values.name} - ${values.phoneNo}`);
    const validate = loginFromValidate()
    if (validate.success) {
      setUpdatePhone(false)
      setSentLoading(true)
      props.requestOtp(values.name, values.phoneNo);
    } else {
      setLoginFromError({ ...loginFormError, [validate.code]: true, [validate.not]: false })
    }
  };
  const loginFromValidate = () => {
    if (values.name.length <= 0 || values.name === "" || !values.name.replace(/\s/g, '').length) {
      return { success: false, code: 'NameError', not: 'PhoneError' }
    }
    if (!values.phoneNo.startsWith('09') || !(values.phoneNo.length >= 8 && values.phoneNo.length <= 11) || values.phoneNo === "") {
      return { success: false, code: 'PhoneError', not: 'NameError' }
    }
    return { success: true }
  }
  const otpValidate = () => {
    if (values.otp === "" || values.otp.length !== 4 || !values.name.replace(/\s/g, '').length) {
      return { success: false, code: 'OtpError' }
    }
    return { success: true }
  }
  const handleVerifyOTP = (event) => {
    const validate = otpValidate()
    if (validate.success) {
      setVerifyLoading(true)
      props.verifyOtp(props.otp.requestId, values.otp);
    } else {
      setLoginFromError({ ...loginFormError, [validate.code]: true })
    }

  };
  const resendOTP = () => {
    setExpired(false)
    setVerifyLoading(false)
    props.requestOtp(values.name, values.phoneNo);
  }
  let rootStyle = { width: "100%" };
  if (matches) {
    rootStyle.width = "400px";
    //rootStyle.borderRadius = "15px";
    //rootStyle.borderColor = "black";
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const updateChange = () => {
    setUpdatePhone(true)
    setVerifyLoading(false)
    setSentLoading(false)
  }
  const loginWidget = (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
      {/* <Grid item className={classes.logorow}>
        <img alt={props.merchant.businessName} src={props.merchant.logo} className={classes.logo} />
      </Grid>
      <Grid item className={classes.entry}>
        <Typography variant="h6">{props.merchant.businessName}</Typography>
      </Grid> */}
      <Grid item className={classes.entry}>
        <Typography variant="h6">Sign In</Typography>
      </Grid>
      <Grid item className={classes.entry}>
        <TextField
          id="sigin-name"
          label="Name"
          color="secondary"
          variant="outlined"
          margin="normal"
          value={values.name}
          onChange={handleChange("name")}
          error={loginFormError.NameError}
          helperText={loginFormError.NameError ? 'invalid name' : ''}
        />
      </Grid>
      <Grid item className={classes.entry}>
        <TextField id="sigin-phoneno"
          type="number"
          placeholder="09xxxxxxxx"
          label="Phone Number"
          color="secondary"
          variant="outlined"
          margin="normal"
          value={values.phoneNo}
          onChange={handleChange("phoneNo")}
          className={classes.TextField}
          error={loginFormError.PhoneError}
          helperText={loginFormError.PhoneError ? 'invalid phone number' : ''}
        />
      </Grid>
      <Grid item className={classes.entry}>
        {
          sentLoading ? (<ButtonWithProgress text='sending...' isLoading={sentLoading} onClick={() => { }} />) :
            <Button variant="contained" color="secondary" onClick={handleRequestOTP}>Send OTP</Button>
        }
      </Grid>
    </Grid>
  );

  const otpWidget = (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle}>
      {/* <Grid item className={classes.logorow}>
        <img alt={props.merchant.businessName} src={props.merchant.logo} className={classes.logo} />
      </Grid>
      <Grid item className={classes.entry}>
        <Typography variant="h6">{props.merchant.businessName}</Typography>
      </Grid> */}
      <Grid item className={classes.entry}>
        <Typography variant="h6">OTP</Typography>
      </Grid>
      <Grid item className={classes.entry}>
        <TextField
          id="sigin-name"
          label="Sent Otp To"
          color="secondary"
          variant="outlined"
          margin="normal"
          value={values.phoneNo}
          InputProps={{
            endAdornment: (
              <Button color='primary' onClick={updateChange}>Update</Button>
            )
          }}
          disabled
        />
      </Grid>
      <Grid item className={classes.entry}>
        <TextField
          className={classes.TextField}
          id="sigin-name"
          label="OTP"
          color="secondary"
          variant="outlined"
          type="number"
          margin="normal"
          value={values.otp}
          onChange={handleChange("otp")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {
                  !expired ? <Timer cb={() => setExpired(true)} /> : <div />
                }
              </InputAdornment>
            )
          }}
          error={loginFormError.OtpError}
          helperText={loginFormError.OtpError ? 'invalid otp number' : ''}
        />
      </Grid>
      <Grid item className={classes.entry}>
        {
          (!props.otp.verifyOtpMessage && verifyLoading) ? (<ButtonWithProgress text='verify...' isLoading={verifyLoading} onClick={() => { }} />) :
            (
              <Button variant="contained" color="secondary" onClick={!expired ? handleVerifyOTP : resendOTP}>
                {expired ? 'Resend OTP' : 'Verify OTP'}
              </Button>
            )
        }
      </Grid>
      {props.otp.verifyOtpMessage && (
        <Grid item className={classes.entry}>
          <Alert severity="error">{props.otp.verifyOtpMessage}</Alert>
        </Grid>
      )}
    </Grid>
  );

  return (
    <div>
      <HomeButton />
      <Grid container direction="row" justify="center" alignItems="center">
        {props.otp.requestOtpState && !updatePhone ? otpWidget : loginWidget}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    merchant: state.merchant.data,
    otp: state.otp,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setShoppingCartState: (shoppingCartState) => dispatch(menuActions.setShoppingCartState(shoppingCartState)),
    requestOtp: (userName, phoneNo) => dispatch(otpActions.requestOtp(userName, phoneNo)),
    verifyOtp: (id, code) => dispatch(otpActions.verifyOtp(id, code)),
    // hideVariantDrawer: () => dispatch(menuActions.hideVariantDrawer()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
