import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { CheckoutStages } from "../../constants";
import ShippingAddress from "../../components/checkout/shipping_address";
import PaymentMethods from "../../components/checkout/payment_methods";
import OrderCheckout from "../../components/checkout/order_checkout";
import OrderComplete from "../../components/checkout/order_complete";
import HotelPaymentMethod from '../../components/checkout/hotel_payment_methods';
import HotelAddress from '../../components/checkout/hotel_room';
import RestaurantOptionMenu from '../../components/checkout/restaurant_options';
import RestaurantDineInShipping from '../../components/checkout/restaurant_table';
import RestaurantPickUpShipping from '../../components/checkout/restaurant_shop_address';
import { getMemberPoint } from "../../services/firebase";
export const CheckoutRoute = "/checkout";

const CheckOutPage = (props) => {
  const [checkoutStage, setCheckoutStage] = useState(CheckoutStages.SHIPPING);
  const [checkoutData, setCheckoutData] = useState({});
  const [pickType, setPickType] = useState(null)
  const state = useSelector(state => state)
  let currentWidget = <></>;
  const isHotel = state && state.merchant && state.merchant.data && state.merchant.data.merchantType && state.merchant.data.merchantType === 'hotel'
  const isRestaurant = state && state.merchant && state.merchant.data && state.merchant.data.merchantType && state.merchant.data.merchantType === 'restaurant'
  const isRestaurantPick = pickType !== null
  const isPickUp = isRestaurantPick && pickType === 'pickUp'
  const handleNextAction = (data) => {
    let nextStage = checkoutStage + 1;
    if (data && data.pick) {
      nextStage = nextStage - 1
      setPickType(data.pick)
    }
    if (nextStage === CheckoutStages.ORDER) {
      getMemberPoint(state.merchant.data.id, state.user.phoneNo, (res) => {
        if (res.success) {
          data = { ...data, pointSystem: res.data }
        }
        setCheckoutData(data);
        setCheckoutStage(nextStage);
      })
    } else {
      setCheckoutData(data);
      setCheckoutStage(nextStage);
    }
  };
  const handleBackAction = (stage) => {
    setPickType(null)
    setCheckoutStage(stage)
  }
  switch (checkoutStage) {
    case CheckoutStages.PAYMENT:
      currentWidget = isHotel ?
        <HotelPaymentMethod data={checkoutData} onNext={handleNextAction} /> :
        (isRestaurantPick && pickType === 'dineIn') ? setCheckoutStage(CheckoutStages.ORDER) :
          <PaymentMethods data={checkoutData} onNext={handleNextAction} withShipping={isPickUp ? null : true} />;
      break;
    case CheckoutStages.ORDER:
      currentWidget = <OrderCheckout data={checkoutData} onNext={handleNextAction} address={(isHotel || isRestaurant) ? 'Customer Info' : 'Address'} />;
      break;
    case CheckoutStages.COMPLETE:
      currentWidget = <OrderComplete data={checkoutData} onNext={handleNextAction} />;
      break;
    default:
      currentWidget =
        isHotel ?
          <HotelAddress data={checkoutData} onNext={handleNextAction} /> :
          (isRestaurant && !isRestaurantPick) ?
            <RestaurantOptionMenu data={checkoutData} onNext={handleNextAction} /> :
            (isRestaurantPick && pickType === 'pickUp') ?
              <RestaurantPickUpShipping data={checkoutData} onNext={handleNextAction} onBack={handleBackAction} /> :
              (isRestaurantPick && pickType === 'dineIn') ?
                <RestaurantDineInShipping data={checkoutData} onNext={handleNextAction} onBack={handleBackAction} /> :
                <ShippingAddress data={checkoutData} onNext={handleNextAction} onBack={isRestaurant ? handleBackAction : null} />;
      break;
  }

  return <React.Fragment>{currentWidget}</React.Fragment>;
};

const mapStateToProps = (state, props) => {
  return {
    cartIsOpen: state.menu.ShoppingCartIsOpen,
    variantDrawerIsOpen: state.menu.VariantIsOpen,
  };
};

export default connect(mapStateToProps)(CheckOutPage);
