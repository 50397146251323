export const REQUEST_OTP = "REQUEST_OTP";
export const REQUEST_OTP_COMPLETED = "REQUEST_OTP_COMPLETED";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_COMPLETED = "VERIFY_OTP_COMPLETED";
export const GET_OTP_STATUS = "GET_OTP_STATUS";

export const actionCreators = {
  requestOtp: (userName, phoneNo) => ({
    type: REQUEST_OTP,
    userName,
    phoneNo,
  }),

  requestOtpCompleted: (payload, userName, phoneNo) => ({
    type: REQUEST_OTP_COMPLETED,
    payload,
    userName,
    phoneNo,
  }),

  verifyOtp: (id, code) => ({
    type: VERIFY_OTP,
    id,
    code,
  }),

  verifyOtpCompleted: (payload) => ({
    type: VERIFY_OTP_COMPLETED,
    payload,
  }),

  getOtpStatus: () => ({
    type: GET_OTP_STATUS,
  }),
  clearOtpCompleted: () => ({
    type: REQUEST_OTP_COMPLETED,
    payload: {
      status: "error"
    }
  })
};
