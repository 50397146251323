import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography, Card, CardContent } from "@material-ui/core";
//import Button from "@material-ui/core/Button";
import HomeButton from "../../controls/home_button";
// import { m } from "framer-motion";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "100px",
    },
    logorow: {
        backgroundColor: theme.palette.secondary.light,
    },
    card: {
        width: '100%',
        //cursor: 'grabbing',
        borderColor: theme.palette.primary.main,
        // backgroundColor: theme.palette.primary.main,
    },
    cardSelected: {
        width: '100%',
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.light,
    },
    typography: {
        textAlign: 'center',
    },
    CardContent: {
        alignItems: 'center',
        width: '128px',
    },
    entry: {
        paddingTop: "10px",
    },
    textEntry: {
        paddingTop: "20px",
    },
}));
const pickById = (id = 0) => {
    if (id === 2) return 'pickUp'
    if (id === 1) return 'dineIn'
    return 'delivery'
}
const ROptions = (props) => {
    const [values, setValues] = useState(-1)
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:600px)");
    const { stores } = props.merchant
    const handleNext = (id) => event => {
        props.onNext({ pick: pickById(id) });
    };
    const hasPickMenu = () => {
        if (stores && Array.isArray(stores)) {
            return stores.filter(s => s.status === 'active').length > 0
        }
        return false
    }
    let rootStyle = { width: "100%" };
    if (matches) {
        rootStyle.width = "400px";
    }
    const availableMenu = (menu) => {
        const { menuOptions } = props.merchant;
        if (menuOptions && Array.isArray(menuOptions)) {
            return menuOptions.filter(ms => ms.type === menu && ms.status === 'active').length > 0;
        }
        return true;
    }
    const handleChange = (prop) => {
        setValues(prop);
    };
    return (
        <div>
            <HomeButton />
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle} alignContent='center'>
                    {
                        availableMenu('delivery') &&
                        <Grid item className={classes.entry} >
                            <Card className={values === 0 ? classes.cardSelected : classes.card} variant="outlined" onClick={handleNext(0)} onMouseOver={() => handleChange(0)} onMouseLeave={() => handleChange(-1)}>
                                <CardContent className={classes.CardContent}>
                                    <Typography variant="h5" component="body" className={classes.typography}>
                                        Delivery
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    {
                        availableMenu('dine-in') &&
                        < Grid className={classes.entry} >
                            <Card className={values === 1 ? classes.cardSelected : classes.card} variant="outlined" onClick={handleNext(1)} onMouseOver={() => handleChange(1)} onMouseLeave={() => handleChange(-1)}>
                                <CardContent className={classes.CardContent}>
                                    <Typography variant="h5" component="h2" className={classes.typography}>
                                        Dine-In
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    {
                        hasPickMenu() &&
                        (<Grid className={classes.entry} >
                            <Card className={values === 2 ? classes.cardSelected : classes.card} variant="outlined" onClick={handleNext(2)} onMouseOver={() => handleChange(2)} onMouseLeave={() => handleChange(-1)}>
                                <CardContent className={classes.CardContent}>
                                    <Typography variant="h5" component="h2" className={classes.typography}>
                                        Pick-Up
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>)
                    }
                    {/* <Grid item className={classes.entry}>
                        <Button variant="contained" color="secondary" onClick={handleNext}>
                            Next
                          </Button>
                    </Grid> */}
                </Grid>
            </Grid>
        </div >
    );
};

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant.data,
    };
};

export default connect(mapStateToProps, {})(ROptions);
