import React from "react";
import { connect } from "react-redux";
import { push, goBack, replace } from "connected-react-router";
import { fade, makeStyles } from "@material-ui/core/styles";
// import HideOnScroll from "../ui-utils/hide_on_scroll";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import { actionCreators as itemActions } from "../../store/actions/item_actions";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
import SearchIcon from "@material-ui/icons/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignCenter, faChevronLeft, faHome, faGift } from "@fortawesome/free-solid-svg-icons";
import Hidden from "@material-ui/core/Hidden";
import { Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: "100%",
  },
  menuButton: {
    marginLeft: -12,
    // marginRight: 20,
  },
  pad: {
    height: 56,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up("sm")]: {
      height: 64,
    },
  },
  avatarLarge: {
    marginTop: theme.spacing(1),
    //width: 48,
    height: 30,
  },
  rewardIcon: {
    marginLeft: 'auto',
  },
  logoAndName: {
    marginLeft: theme.spacing(60)
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchMobile: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "120px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    shopName: {
      paddingLeft: "10px",
    },
  },
  inputRootMobile: {
    color: "inherit",
  },
  inputInputMobile: {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    shopName: {
      paddingLeft: "10px",
    },
  },
  inputInputMobile2: {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "20ch",
      },
    },
    shopName: {
      paddingLeft: "10px",
    },
  },
}));

const TAB2 = (props) => {
  const classes = useStyles();
  const { merchant, location, goBack, updateSearchString } = props;
  const matches = useMediaQuery("(min-width:600px)");

  const gridProps = matches ? { spacing: 1, justify: "space-between", alignItems: "center" } : { spacing: 0, justify: "space-between", alignItems: "center" };
  const isLongName = ((typeof merchant.businessName === 'string') && merchant.businessName.length > 6)
  const isHome = location.pathname === "/";
  // const isDetail = location.pathname.startsWith("/detail");
  const isCheckout = location.pathname.startsWith("/checkout");
  const isProfile = location.pathname.startsWith("/profile");
  const isContact = location.pathname.startsWith("/contact");
  const isTerm = location.pathname.startsWith("/term");
  const isLogin = location.pathname.startsWith("/login");
  const isMobile = !matches;
  const showRewardIcon = (merchant.rewardIntegrationEnabled) && (typeof merchant.rewardIntegrationEnabled === 'boolean') && (merchant.rewardIntegrationEnabled === true)
  const handleMenu = (event) => {
    if (isHome) {
      props.setMainMenuState(true);
    } else {
      goBack();
    }
  };
  const goHome = () => {
    props.replace('/')
  }
  const handleTextChange = (event) => {
    updateSearchString(event.target.value.toLowerCase());
  };

  const menuIcon = !(isHome && isMobile) ? (
    <Grid item>
      <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenu}>
        <FontAwesomeIcon icon={isHome ? faAlignCenter : (isCheckout | isProfile | isContact | isTerm) ? faHome : faChevronLeft} />
      </IconButton>
    </Grid>
  ) :
    <Grid item>
      <IconButton edge="start" className={classes.menuButton}
        color="inherit" aria-label="menu" onClick={handleMenu}>
        <FontAwesomeIcon icon={faAlignCenter} />
      </IconButton>
    </Grid>
    ;
  const MobileLogo = (
    <Grid item>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item className={classes.shopName} onClick={goHome}>
          <img alt={merchant.businessName} src={merchant.logo} className={classes.avatarLarge} />
        </Grid>
      </Grid>
    </Grid>
  )
  const logoAndName = matches ?
    (<div className={classes.logoAndName}>
      <Typography variant="h6">{merchant.businessName}</Typography>
    </div>) : (
      <Grid item>
        <Grid container direction="row" justify="center" alignItems="center">
          {/* <Grid item>
          <img alt={merchant.businessName} src={merchant.logo} className={classes.avatarLarge} onClick={handleMenu} /> */}
          {/* {matches ? (
            <img alt={merchant.businessName} src={merchant.logo} className={classes.avatarLarge} onClick={handleMenu} />
          ) : isHome ? (
            <img alt={merchant.businessName} src={merchant.logo} className={classes.avatarLarge} onClick={handleMenu} />
          ) : (
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenu}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </IconButton>
          )} */}
          {/* </Grid> */}
          <Grid item className={classes.shopName}>
            <Typography variant="h6">{merchant.businessName}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );

  const rewardIcon = (matches && showRewardIcon && !isLogin) ? (
    <div className={classes.rewardIcon}>
      <IconButton color="inherit" aria-label="menu" onClick={() => props.showRewardDrawer()}>
        <FontAwesomeIcon icon={faGift} />
      </IconButton>
    </div>
  ) : <div />;
  const searchBox = (
    < Grid item>
      {
        location.pathname === "/" && (
          // <div className={matches ? classes.search : classes.searchMobile}>
          <div className={matches ? classes.search : classes.search}>
            <Hidden smDown>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
            </Hidden>
            <InputBase
              placeholder="Search…"
              classes={{
                root: matches ? classes.inputRoot : classes.inputRootMobile,
                input: matches ? classes.inputInput : classes.inputInputMobile2,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={handleTextChange}
            />
          </div>
        )
      }
    </Grid >
  );

  return (
    <React.Fragment>
      {/* <HideOnScroll {...props}> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container direction="row" {...gridProps}>
            {menuIcon}
            {(isMobile && isLongName) ? MobileLogo : logoAndName}
            {rewardIcon}
            {searchBox}
          </Grid>
        </Toolbar>
      </AppBar>
      {/* </HideOnScroll> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { menu, merchant } = state;
  return {
    location: state.router.location,
    IsOpen: menu.IsOpen,
    merchant: merchant.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainMenuState: (menuState) => dispatch(menuActions.setMainMenuState(menuState)),
    push: (path) => dispatch(push(path)),
    goBack: () => dispatch(goBack()),
    replace: (url) => dispatch(replace(url)),
    showRewardDrawer: () => dispatch(menuActions.showRewardDrawer()),
    updateSearchString: (searchString) => dispatch(itemActions.setSearchString(searchString)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TAB2);
