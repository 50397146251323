export const GET_VARIANTS = "GET_VARIANTS";
export const SET_VARIANTS = "SET_VARIANTS";
export const GET_VARIANT_BY_IDS = "GET_VARIANT_BY_IDS";
export const FETCH_VARIANTS = "FETCH_VARIANTS";
export const FETCH_VARIANTS_COMPLETED = "FETCH_VARIANTS_COMPLETED";

export const GET_MODIFIERS = "GET_MODIFIERS";
export const GET_TAXES = "GET_TAXES";

export const actionCreators = {
  getVariants: () => ({
    type: GET_VARIANTS,
  }),

  setVariants: (variants) => ({
    type: SET_VARIANTS,
    variants,
  }),

  getVariantsById: (ids) => ({
    type: GET_VARIANT_BY_IDS,
    ids,
  }),

  fetchVariants: (merchantId) => ({
    type: FETCH_VARIANTS,
    merchantId,
  }),

  fetchVariantsCompleted: ({ variants, modifiers, taxes }) => ({
    type: FETCH_VARIANTS_COMPLETED,
    variants,
    modifiers,
    taxes,
  }),

  getModifiers: () => ({
    type: GET_MODIFIERS,
  }),

  getTaxes: () => ({
    type: GET_TAXES,
  }),
};
