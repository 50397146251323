import update from "immutability-helper";
import * as Action_Constants from "../actions/cart_actions";

//cartLine -> id (variant_id), count, price, total
const initialState = {
  cartLines: [],
  total: 0,
};

export function CartReducer(state = initialState, action) {
  switch (action.type) {
    case Action_Constants.GET_CART: {
      return state;
    }
    case Action_Constants.SET_CARTLINE: {
      const { cartLine } = action;
      if (cartLine.count === 0) {
        //to remove
        let newCartLines = state.cartLines.filter((line) => line.id !== cartLine.id);
        //console.log(newCartLines.length);
        const total = newCartLines.reduce((a, b) => a + b.total, 0);
        const totalWithTax = newCartLines.reduce((a, b) => a + b.totalWithTax, 0);
        return update(state, {
          cartLines: { $set: newCartLines },
          total: { $set: total },
          totalWithTax: { $set: totalWithTax },
        });
      }
      let total = state.cartLines.filter((cl) => cl.id !== cartLine.id).reduce((a, b) => a + b.total, cartLine.total);
      let totalWithTax = state.cartLines.filter((cl) => cl.id !== cartLine.id).reduce((a, b) => a + b.totalWithTax, cartLine.totalWithTax);
      for (let i = 0; i < state.cartLines.length; i++) {
        if (state.cartLines[i].id === cartLine.id) {
          return update(state, {
            cartLines: {
              [i]: {
                count: { $set: cartLine.count },
                price: { $set: cartLine.price },
                total: { $set: cartLine.total },
                wholeTax: { $set: cartLine.wholeTax },
                inclusiveTax: { $set: cartLine.inclusiveTax },
                totalWithTax: { $set: cartLine.totalWithTax },
              },
            },
            total: { $set: total },
            totalWithTax: { $set: totalWithTax },
          });
        }
      }

      return update(state, {
        cartLines: { $push: [cartLine] },
        total: { $set: total },
        totalWithTax: { $set: totalWithTax },
      });
    }

    case Action_Constants.UPDATE_CARTLINE_ITEM_COUNT: {
      // console.log(action);
      const { id, count, wholeTax, inclusiveTax, total, totalWithTax } = action;
      for (let i = 0; i < state.cartLines.length; i++) {
        if (state.cartLines[i].id === id) {
          let cartTotal = state.cartLines.filter((cl) => cl.id !== id).reduce((a, b) => a + b.total, total);
          let cartTotalWithTax = state.cartLines.filter((cl) => cl.id !== id).reduce((a, b) => a + b.totalWithTax, totalWithTax);
          return update(state, {
            cartLines: {
              [i]: {
                count: { $set: count },
                total: { $set: total },
                wholeTax: { $set: wholeTax },
                inclusiveTax: { $set: inclusiveTax },
                totalWithTax: { $set: totalWithTax },
              },
            },
            total: { $set: cartTotal },
            totalWithTax: { $set: cartTotalWithTax },
          });
        }
      }
      return state;
    }

    case Action_Constants.REMOVE_CARTLINE: {
      const newCartLines = state.cartLines.filter((line) => line.id !== action.id);
      const total = newCartLines.reduce((a, b) => a + b.total, 0);
      const totalWithTax = newCartLines.reduce((a, b) => a + b.totalWithTax, 0);
      return update(state, {
        cartLines: { $set: newCartLines },
        total: { $set: total },
        totalWithTax: { $set: totalWithTax },
      });
    }

    case Action_Constants.CLEAR_CART: {
      return update(state, {
        cartLines: { $set: [] },
        total: { $set: 0 },
        totalWithTax: { $set: 0 },
      });
    }

    default:
      return state;
  }
}
