import * as Action_Constants from "../actions/menu_actions";

const initialState = {
  MainMenuIsOpen: false,
  ShoppingCartIsOpen: false,
  VariantIsOpen: false,
  CategoryIsOpen: false,
  RewardIsOpen: false,
};

export function MenuReducer(state = initialState, action) {
  switch (action.type) {
    case Action_Constants.GET_MAIN_MENU_STATE: {
      return state.MainMenuIsOpen;
    }
    case Action_Constants.SET_MAIN_MENU_STATE: {
      return { ...state, MainMenuIsOpen: action.menuState };
    }
    case Action_Constants.TOGGLE_MAIN_MENU_STATE: {
      return { ...state, MainMenuIsOpen: !state.MainMenuIsOpen };
    }

    case Action_Constants.GET_SHOPPING_CART_STATE: {
      return state.ShoppingCartIsOpen;
    }
    case Action_Constants.SET_SHOPPING_CART_STATE: {
      return { ...state, ShoppingCartIsOpen: state.shoppingCartState };
    }
    case Action_Constants.SHOW_SHOPPING_CART: {
      return { ...state, ShoppingCartIsOpen: true };
    }
    case Action_Constants.HIDE_SHOPPING_CART: {
      return { ...state, ShoppingCartIsOpen: false };
    }
    case Action_Constants.SHOW_VARIANT_DRAWER: {
      return { ...state, VariantIsOpen: true };
    }
    case Action_Constants.HIDE_VARIANT_DRAWER: {
      return { ...state, VariantIsOpen: false };
    }
    case Action_Constants.SHOW_CATEGORY_DRAWER: {
      return { ...state, CategoryIsOpen: true }
    }
    case Action_Constants.HIDE_CATEGORY_DRAWER: {
      return { ...state, CategoryIsOpen: false }
    }
    case Action_Constants.SHOW_REWARD_DRAWER: {
      return { ...state, RewardIsOpen: true }
    }
    case Action_Constants.HIDE_REWARD_DRAWER: {
      return { ...state, RewardIsOpen: false }
    }
    default:
      return state;
  }
}
