import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";
import VerticalUpDown from "../../controls/vertical_up_down";
import { actionCreators as cartActions } from "../../store/actions/cart_actions";
import { Typography } from "@material-ui/core";
import { formatWithCurrencySymbol } from "../../utils/currency_utils";
import { Grid } from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { push } from "connected-react-router";
import { actionCreators as menuActions } from "../../store/actions/menu_actions";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: "10px 10px",
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    variantText: {
        marginBottom: 1,
        marginTop: 0,
        // color: theme.palette.text.secondary,
    },
    itemText: {
        // color: theme.palette.text.secondary,
    },
    itemPrice: {
        marginTop: 0,
    },
    bigAvatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
    ListItemSecondaryAction: {
        right: 0
    },
    textColor: {
        color: theme.palette.text.secondary,
    }
}));

const CartLine = (props) => {
    const classes = useStyles();
    const { variants, cartLine, updateCartlineItemCount, removeCartline, push, hideShoppingCart } = props;
    const variant = variants[cartLine.id];
    const stockQty = variant.stockQty || 0
    const maxCount = ((variant.enableInventoryControl && stockQty < 1) || (!variant.enableInventoryControl && variant.price > 1)) ? 100 : stockQty;
    const handleCountChange = (count) => {
        updateCartlineItemCount(cartLine.id, count);
    };

    const handleRemove = () => {
        updateCartlineItemCount(cartLine.id, 0);
        removeCartline(cartLine.id);
    };
    const goToDetail = () => {
        if (cartLine && cartLine.itemId) {
            push(`/detail/${cartLine.itemId}`);
            hideShoppingCart();
        }
    }
    const hasModifier = cartLine && cartLine.modifiers && Array.isArray(cartLine.modifiers) && cartLine.modifiers.length > 0

    const priceString = formatWithCurrencySymbol(variant.price);
    const totalString = formatWithCurrencySymbol(cartLine.total);
    const displayName = (cartLine.itemName === variant.name) ? cartLine.itemName : `${cartLine.itemName} (${variant.name})`

    return (
        <ListItem>
            <div style={{ marginRight: '10px' }}>
                <VerticalUpDown max={maxCount} value={cartLine.count} onValueChange={handleCountChange} zeroIcon={false} color="#F2F4F4" />
            </div>
            <ListItemIcon>
                <Avatar onClick={goToDetail} alt={variant.name} src={variant.photo} className={classes.bigAvatar} />
            </ListItemIcon>
            <ListItemText
                primary={displayName} secondary={hasModifier ?
                    <p>
                        <Grid item container direction='column'>
                            <Grid item>
                                <Typography>{priceString}</Typography>
                            </Grid>
                            {/* {
                                cartLine.modifiers.map(m => {
                                    return (<Grid item>
                                        <Typography  >{`${formatWithCurrencySymbol(m.amount.value)}(${m.text})`}</Typography>
                                    </Grid>)
                                })
                            } */}

                        </Grid></p> :
                    priceString} />
            {/* <Typography variant="body1" style={{ marginLeft: 12 }}>
                {totalString}
            </Typography> */}
            <Grid style={{ width: '100%' }} />
            <ListItemSecondaryAction className={classes.ListItemSecondaryAction}>
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleRemove}>
                    <Typography variant="body1" style={{ right: 3, color: 'black' }}>
                        {totalString}
                    </Typography>
                    <Close />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem >
    )
};

const mapStateToProps = (state, props) => {
    return {
        cart: state.cart,
        variants: state.variants.data.variants,
        items: state.items.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartlineItemCount: (id, count) => dispatch(cartActions.updateCartlineItemCount(id, count)),
        removeCartline: (id) => dispatch(cartActions.removeCartline(id)),
        hideShoppingCart: () => dispatch(menuActions.hideShoppingCart()),
        push: (url) => dispatch(push(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartLine);
