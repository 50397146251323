import React, { useEffect, useState } from "react";

const RESET_INTERVAL_S = 180; // 300s = 5m * 60s/m

const remainTimeCal = (time) => {
    return RESET_INTERVAL_S - (time % RESET_INTERVAL_S)
}
const formatTime = (time) =>
    `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(
        time % 60
    ).padStart(2, "0")}`;

const Timer = ({ time }) => {
    const timeRemain = RESET_INTERVAL_S - (time % RESET_INTERVAL_S);
    return (
        <>
            <div style={{ color: 'black', }}>{formatTime(timeRemain)}</div>
        </>
    );
};

const IntervalTimerFunctional = (props) => {
    const [time, setTime] = useState(0);
    let timerId = null
    useEffect(() => {
        timerId = setInterval(() => {
            setTime((t) => t + 1);
        }, 1000);
    }, []);
    if (remainTimeCal(time) === 1) {
        if (timerId) {
            setTime(0)
            clearInterval(timerId)
        }
        props.cb()
        return <div />
    } else {
        return <Timer time={time} />;
    }
};

export default function App(props) {
    return (
        <IntervalTimerFunctional cb={() => props.cb()} />
    );
}
