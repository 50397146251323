import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { isDesktopFooterAvailbalePath, isMobileFooterAvailablePath } from './utils';
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//mcommerce-staging
const useStyles = makeStyles({
    appBar: {
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        top: 'auto',
        bottom: 0,
        alignItems: 'center'
    },
});

function SimpleBottomNavigation(props) {
    const classes = useStyles();
    const { merchant } = props
    const matches = useMediaQuery("(min-width:600px)");
    const getUrl = () => {
        if (merchant
            && merchant.enterpriseSetting
            && merchant.enterpriseSetting.onlineStore
            && typeof merchant.enterpriseSetting.onlineStore === 'string'
            && merchant.enterpriseSetting.onlineStore.length > 0) {
            return merchant.enterpriseSetting.onlineStore
        }
        return `https://${merchant.id}.piticommerce.com/`
    }
    const avaiable = matches ? isDesktopFooterAvailbalePath(props.location.pathname) : isMobileFooterAvailablePath(props.location.pathname)
    if (!avaiable) {
        return null;
    }
    return (
        <React.Fragment>
            <AppBar color="primary" className={classes.appBar}>
                <Toolbar variant='dense' >
                    <Typography variant='subtitle2' color='textPrimary'>
                        <span>
                            &copy; {new Date().getFullYear()}{' '}
                            <a href={getUrl()}>{merchant.businessName}</a>
                        </span>
                    </Typography>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        location: state.router.location,
        merchant: state.merchant.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // showShoppingCart: () => dispatch(menuActions.showShoppingCart()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleBottomNavigation);