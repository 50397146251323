import React from "react";
import firebase from "firebase";
import "firebase/storage";
import Progress from './circular_progress_with_label';
// import AttachmentIcon from '@material-ui/icons/Attachment';
//import ImageIcon from '@material-ui/icons/Image';
import { CloudUpload } from "@material-ui/icons";
// import FileUploader from "react-firebase-file-uploader";
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';

class UploadFile extends React.Component {
    state = {
        filenames: [],
        downloadURLs: [],
        isUploading: false,
        uploadProgress: 60
    };
    handleUploadStart = () => {
        this.props.startImageLoading();
        this.setState({
            isUploading: true,
            uploadProgress: 0
        });
    }
    handleProgress = progress =>
        this.setState({
            uploadProgress: progress
        });

    handleUploadError = error => {
        this.setState({
            isUploading: false
            // Todo: handle error
        });
        //  console.error(error);
    };

    handleUploadSuccess = async (filename) => {
        const downloadURL = await firebase.app('secondary')
            .storage()
            .ref(`paySlips/${this.props.merchantId}`)
            .child(filename)
            .getDownloadURL();
        this.props.cb(downloadURL)
        this.setState(oldState => ({
            filenames: [...oldState.filenames, filename],
            downloadURLs: [...oldState.downloadURLs, downloadURL],
            uploadProgress: 100,
            isUploading: false
        }));
    };

    render() {
        return (
            <div style={{ alignItems: 'center', }}>
                {
                    this.state.isUploading ? <Progress value={this.state.uploadProgress} /> :
                        <CustomUploadButton
                            accept="image/*"
                            name="image-uploader-multiple"
                            filename={file => this.props.fileName}
                            storageRef={firebase.app('secondary').storage().ref(`paySlips/${this.props.merchantId}`)}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            multiple
                        >
                            <CloudUpload color="primary" />
                        </CustomUploadButton>
                }
                {/* <p>Progress: {this.state.uploadProgress}</p>

                <p>Filenames: {this.state.filenames.join(", ")}</p> */}


            </div>
        );
    }
}
export default UploadFile;