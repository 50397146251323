export const GET_CART = "GET_CART";
export const SET_CARTLINE = "SET_CARTLINE";
export const UPDATE_CARTLINE_ITEM_COUNT = "UPDATE_CARTLINE_ITEM_COUNT";
export const REMOVE_CARTLINE = "REMOVE_CARTLINE";
export const CLEAR_CART = "CLEAR_CART";

export const actionCreators = {
  getCart: () => ({
    type: GET_CART,
  }),

  setCartline: (cartLine) => ({
    type: SET_CARTLINE,
    cartLine,
  }),

  updateCartlineItemCount: (id, count) => ({
    type: UPDATE_CARTLINE_ITEM_COUNT,
    id,
    count,
  }),

  removeCartline: (id) => ({
    type: REMOVE_CARTLINE,
    id,
  }),

  clearCart: () => ({
    type: CLEAR_CART,
  }),
};
