import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import reduceReducers from "reduce-reducers";
import { MenuReducer } from "./menu_reducer";
import { MerchantReducer } from "./merchant_reducer";
import { CategoryReducer } from "./category_reducer";
import { ItemReducer } from "./item_reducer";
import { VariantReducer } from "./variant_reducer";
import { DataLoadingState } from "../../constants";
import { CartReducer } from "./cart_reducer";
import { OtpReducer } from "./otp_reducer";
import { UserReducer } from "./user_reducer";
import { ShippingFeeReducer } from "./shipping_fee_reducer";

const combinedReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    menu: MenuReducer,
    merchant: MerchantReducer,
    categories: CategoryReducer,
    items: ItemReducer,
    variants: VariantReducer,
    cart: CartReducer,
    otp: OtpReducer,
    user: UserReducer,
    shippingFees: ShippingFeeReducer,
  });

const crossSliceReducer = (state, action) => {
  switch (action.type) {
    case "GET_INITIAL_DATA_LOADING_STATE": {
      const isLoaded =
        state.merchant.state === DataLoadingState.Loaded &&
        state.categories.state === DataLoadingState.Loaded &&
        state.items.state === DataLoadingState.Loaded;
      return {
        MerchantLoadingState: state.merchant.state,
        CategoriesLoadingState: state.categories.state,
        ItemsLoadingState: state.items.state,
        VariantsLoadingState: state.variants.state,
        Loaded: isLoaded,
      };
    }
    default:
      return state;
  }
};

export const createRootReducer = (history) =>
  reduceReducers(combinedReducer(history), crossSliceReducer);
