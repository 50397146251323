import update from "immutability-helper";
import * as Action_Constants from "../actions/otp_actions";

const initialState = {
  requestOtpState: false,
  requestId: "",
  requesterName: "",
  requestPhoneNo: "",
  verifyOtpState: false,
  verifyOtpMessage: "",
  verifiedAt: null,
};

export const OtpReducer = (state = initialState, action) => {
  switch (action.type) {
    case Action_Constants.GET_OTP_STATUS: {
      return state;
    }
    case Action_Constants.REQUEST_OTP_COMPLETED: {
     // console.log(action);
      const { payload, userName, phoneNo } = action;
      if (payload.status !== "error") {
        return update(state, {
          requestOtpState: { $set: true },
          requestId: { $set: payload.id },
          requesterName: { $set: userName },
          requestPhoneNo: { $set: phoneNo },
        });
      } else {
        return update(state, {
          requestOtpState: { $set: false },
          requestId: { $set: "" },
        });
      }
    }
    case Action_Constants.VERIFY_OTP_COMPLETED: {
     // console.log(action);
      const { payload } = action;
      if (payload.status !== "error") {
        return update(state, {
          verifyOtpState: { $set: true },
          verifyOtpMessage: { $set: "" },
          verifiedAt: { $set: new Date() },
        });
      } else {
        return update(state, {
          verifyOtpState: { $set: false },
          verifyOtpMessage: { $set: payload.message },
          verifiedAt: { $set: new Date() },
        });
      }
    }
    default:
      return state;
  }
};
