export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORIES_COMPLETED = "FETCH_CATEGORIES_COMPLETED";

export const actionCreators = {
  getCategories: () => ({
    type: GET_CATEGORIES,
  }),

  setCategories: (categories) => ({
    type: SET_CATEGORIES,
    categories,
  }),

  getCategoryById: (id) => ({
    type: GET_CATEGORY_BY_ID,
    id,
  }),

  fetchCategories: (merchantId) => ({
    type: FETCH_CATEGORIES,
    merchantId,
  }),

  fetchCategoriesCompleted: (payload) => ({
    type: FETCH_CATEGORIES_COMPLETED,
    payload,
  }),
};
