import React from "react";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import HomeButton from "../../controls/home_button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CssBaseline } from "@material-ui/core";
import { actionCreators as userActions } from "../../store/actions/user_actions";
import { actionCreators as otpActions } from "../../store/actions/otp_actions";
import OrderList from "../../components/orders/order_list";
import LoadingProgress from "../../components/loading-indicators/loading_progress";
import { getOrderHistory } from "../../services/firebase";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "10px",
        paddingLeft: "10px",
        //backgroundColor: theme.palette.secondary.light,
    },
}));

const OrderPage = (props) => {
    const classes = useStyles();
    const { user, merchant } = props;
    const { phoneNo } = user
    const merchantId = merchant.id
    const matches = useMediaQuery("(min-width:600px)");
    const [state, setState] = React.useState({ status: 'loading' })
    React.useEffect(() => {
        if (phoneNo && merchantId) {
            getOrderHistory(merchantId, phoneNo, (result) => {
                if (result.success) {
                    setState({ status: 'loaded', data: result.data.orders })
                } else {
                    setState({ status: 'loaded', data: [] })
                }
            });
        }
    }, [phoneNo, merchantId])
    let rootStyle = { width: "100%" };
    if (matches) {
        rootStyle.width = "600px";
    }

    return (
        <div className={classes.root}>
            <HomeButton />
            <CssBaseline />
            {state.status === "loading" ? <LoadingProgress /> : <OrderList orders={state.data} />}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        merchant: state.merchant.data,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        replace: (url) => dispatch(replace(url)),
        logout: () => dispatch(userActions.userLogout()),
        clearOtp: () => dispatch(otpActions.clearOtpCompleted()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
