import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FileUploader from '../file-uploader/file_uploader';
import WaveSpinner from '../file-uploader/mini_wave_spinner';
import { firebaseFunctions } from "../../services/firebase";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { formatWithCurrencySymbol } from "../../utils/currency_utils";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    listItem: {
        padding: 0
    },
}));

export const OrderCard = (props) => {
    const classes = useStyles();
    const { data, merchant } = props;
    const [state, setState] = React.useState({ paymentPhoto: data.paymentPhoto || null, imageLoading: false })
    const onOrderUpdate = (imageUrl) => {
        if (data.id && merchant.id) {
            firebaseFunctions.updateOrder(merchant.id, data.id, { ...data, ["paymentPhoto"]: imageUrl }, (result) => {
                if (result.success === false) {
                    // console.log('failed')
                } else {
                    setState({ paymentPhoto: imageUrl, imageLoading: false })
                    //console.log('success')
                }
            })
        }
    }
    const startImageLoading = () => {
        setState({ ...state, imageLoading: true })
    }
    return (
        <Card className={classes.root}>
            <CardHeader title={<h5>{`Order(#${data.orderId ? data.orderId : data.id})`}</h5>} style={{ height: 0 }} />
            <CardContent>
                <List>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary={<Typography variant="h7">Date</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="body1" >
                                <b>{new Date((data.createdOn && data.createdOn._seconds) ? data.createdOn._seconds * 1000 : 0).toLocaleDateString("en-US")}</b>
                            </Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary={<Typography variant="h7">OrderId</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="body1" ><b>{`#${data.orderId}`}</b></Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary={<Typography variant="h7">Items</Typography>}
                            secondary={
                                <ol>
                                    {
                                        data.details.map(d => {
                                            return (
                                                <>
                                                    <li>
                                                        <span> {`${`${d.name} ${d.name !== d.optionName ? `(${d.optionName})` : ''}`}(${d.price})`}</span>
                                                    </li>
                                                </>
                                            )
                                        })
                                    }
                                </ol>}
                        />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary={<Typography variant="h7">Status</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="body1" ><b>{data.orderStatus}</b></Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary={<Typography variant="h7">Total Amount</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="body1" ><b>{formatWithCurrencySymbol(data.totalAmount)}</b></Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary={<Typography variant="h7">Pay Slip</Typography>} />
                        <ListItemSecondaryAction style={{ marginRight: 10 }}>
                            <FileUploader
                                fileName={data.id}
                                merchantId={merchant.id}
                                startImageLoading={() => startImageLoading()}
                                cb={(imageUrl) => onOrderUpdate(imageUrl)
                                } />
                        </ListItemSecondaryAction>
                    </ListItem >
                    <ListItem className={classes.listItem}>
                        {
                            state.imageLoading ? <WaveSpinner /> :
                                state.paymentPhoto ? <>
                                    <a href={state.paymentPhoto}>
                                        <img src={state.paymentPhoto}
                                            alt="Payslip images"
                                            style={{ width: '42px', height: '42px' }}
                                        />
                                    </a>
                                </> : <div />
                        }

                    </ListItem>
                </List>
            </CardContent>
        </Card>

    );
};

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant.data,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);
