import { Route, Switch, withRouter } from "react-router"; //, Switch
import { spring } from "react-router-transition";
import { userIsNotAuthenticated, userIsAuthenticated } from "./route_wrappers";
import ProductListPage from "../pages/product/product_list_page";
import ProductDetailPage from "../pages/product/product_detail_page";
import CheckOutPage, { CheckoutRoute } from "../pages/checkout/checkout_page";
import Login from "../pages/login/login_page";
import Profile from '../pages/profile/profile';
import Contact from '../pages/contact/contact';
import Reward from "../pages/reward/reward";
import Order from '../pages/order/order_history_page';
import TermAndCondition from '../pages/termAndCondition/page';

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

const routes = (
  <div>
    {/* <AnimatedSwitch atEnter={bounceTransition.atEnter} atLeave={bounceTransition.atLeave} atActive={bounceTransition.atActive} mapStyles={mapStyles} className="route-wrapper"> */}
    <Switch>
      <Route exact path="/" component={ProductListPage} />
      <Route path='/contact' component={Contact} />
      <Route path='/reward' component={Reward} />
      <Route path='/term' component={TermAndCondition} />
      <Route path="/detail/:id" component={ProductDetailPage} />
      <Route path="/login" component={userIsNotAuthenticated(Login)} />
      <Route path='/profile' component={userIsAuthenticated(Profile)} />
      <Route path='/order' component={userIsAuthenticated(Order)} />
      <Route path={CheckoutRoute} component={userIsAuthenticated(CheckOutPage)} />
      <Route component={ProductListPage} />
    </Switch>
  </div>
);

export default routes;
