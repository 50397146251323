import update from "immutability-helper";
import * as Action_Constants from "../actions/user_actions";
import { LoginStatus } from "../../constants";

// name, phoneNo, address, city, paymentMethod, shippingMethod, time, status
const initialState = {
  status: LoginStatus.NONE,
}; // load from local storage

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case Action_Constants.GET_USER_INFO: {
      return state;
    }
    case Action_Constants.SET_USER_INFO: {
      return update(state, { $set: action.user });
    }
    case Action_Constants.LOAD_USER_INFO: {
      return update(state, { $set: action.user });
    }
    case Action_Constants.REMOVE_USER_INFO: {
      return update(state, { $set: {} });
    }
    case Action_Constants.NEW_USER_LOGIN: {
      return update(state, { $set: action.user });
    }
    case Action_Constants.SET_USER_NAME: {
      return update(state, { name: { $set: action.name } });
    }
    case Action_Constants.SET_ADDRESS: {
      return update(state, {
        address: { $set: action.address },
        city: { $set: action.city },
      });
    }
    case Action_Constants.SET_PAYMENT_METHOD: {
      return update(state, { paymentMethod: { $set: action.method } });
    }
    case Action_Constants.SET_SHIPPING_METHOD: {
      return update(state, { shippingMethod: { $set: action.method } });
    }
    case Action_Constants.USER_LOGOUT: {
      return update(state, {
        status: { $set: LoginStatus.LOGGED_OUT },
        time: { $set: new Date() },
      });
    }
    default:
      return state;
  }
};
