import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useWindowWidth } from "@react-hook/window-size";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import { actionCreators as itemActions } from "../../store/actions/item_actions";

const useStyles = makeStyles((theme) => ({
  pad: {
    paddingTop: "64px",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    width: "240px",
  },
  conatiner: {
    padding: "10px",
    backgroundColor: "white",
    width: "240px",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
}));

const CategoryListVertical2 = (props) => {
  const classes = useStyles();
  const { categories, selectedCategory, selectCategory } = props;
  const windowWidth = useWindowWidth();
  if (categories.length <= 1) {
    // no render category list of there is only or none.
    return null;
  }

  const handleSelectCategory = (event, id) => {
    if (selectedCategory === id) {
      selectCategory("");
    } else {
      selectCategory(id);
    }
  };

  let numberOfCards = Math.round(windowWidth / 200);
  if (numberOfCards < 2 || isNaN(numberOfCards)) numberOfCards = 2;

  return (
    <Grid container alignItems="center" justify="center" className={classes.pad}>
      <Grid item className={classes.conatiner}>
        <Typography variant="h6" gutterBottom>
          Categories
        </Typography>
        <div className={classes.drawerContainer}>
          <List>
            {categories.map((category, index) => (
              <ListItem button key={category.id}>
                <ListItemText primary={category.name} onClick={(event) => handleSelectCategory(event, category.id)} />
                {selectedCategory === category.id && (
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories.data,
  selectedCategory: state.items.filter.category,
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectCategory: (categoryId) => dispatch(itemActions.setSelectedCategory(categoryId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListVertical2);
