import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Masonry from "react-masonry-css";
import ProductCard from "./product_card";
import fullTextSearch from "../../store/selectors/full_text_search_selector";

const colBreakpoints = {
  // default: 8,
  default: 5,
  1500: 5,
  1000: 5,
  450: 2,
};
const mobileColBreakpoints = {
  default: 2,
  1500: 3,
  1000: 3,
  450: 2,
}

const ProductList = (props) => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      <Masonry breakpointCols={matches ? colBreakpoints : mobileColBreakpoints} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
        {props.items.map((cardData, idx) => (
          <ProductCard key={idx} data={cardData} />
        ))}
      </Masonry>
      <div style={{ height: 40 }}>&nbsp;</div>
    </>
  );
};

const makeMapStateToProps = () => {
  const filterItemData = fullTextSearch();
  const mapStateToProps = (state, props) => {
    return {
      items: filterItemData(state, props),
      categories: state.categories.data,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(ProductList);
