import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, Button, TextField } from "@material-ui/core";
import { replace } from "connected-react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import { SystemData, SystemCurrency } from "../../constants";
import numeral from "numeral";
import HomeButton from "../../controls/home_button";
import Alert from "@material-ui/lab/Alert";
import { firebaseFunctions } from "../../services/firebase";
import { generateOrderNumber } from "../../utils/number_utils";
import { actionCreators as cartActions } from "../../store/actions/cart_actions";
import ButtonWithProgress from "../../controls/button_with_progress";
import PointPay from "./point_pay";
import { formatCurrencyExchangeRate, formatWithCurrencyCode } from "../../utils/currency_utils";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    paddingLeft: "10px",
    //backgroundColor: theme.palette.secondary.light,
  },
  logorow: {
    backgroundColor: theme.palette.secondary.light,
  },
  entry: {
    paddingTop: "20px",
    //color: theme.palette.secondary.contrastText,
  },
  pad: {
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  logo: {
    width: "128px",
  },
}));

const OrderCheckout = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [customerNote, setCustomerNote] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [coupon, setCoupon] = useState({ code: "", amount: 0, requestId: null, isPercentage: false });
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [payPoint, setPayPoint] = useState(0);
  const [couponError, setCouponError] = useState(null);
  const [orderError, setOrderError] = useState(null)
  const [loading, setLoading] = useState(false)

  const [loadingCouponValue, setLoadingCouponValue] = useState(false);
  const { user, cart, merchant, clearCart } = props;
  const currencySign = SystemData.Default3DigitCurrencySign;
  let rootStyle = { width: "100%" };
  if (matches) {
    rootStyle.width = "400px";
    //rootStyle.borderRadius = "15px";
    //rootStyle.borderColor = "black";
  }

  const tax = cart.totalWithTax - cart.total
  let shippingFee = (user.shippingMethod && user.shippingMethod.amount) | 0;
  shippingFee = shippingFee <= 1 ? 0 : shippingFee;
  const shippingLocation = user.shippingMethod && user.shippingMethod.location || '';
  useEffect(() => {
    setTotalAmount((cart.totalWithTax * SystemCurrency.exchangeRate) + (shippingFee || 0));
  }, [cart.totalWithTax]);
  //setTotalAmount(cart.totalWithTax + shippingFee);
  const formatNumber = (number) => `${numeral(number).format("0,0")} ${currencySign}`;
  const handlePayPointChange = (p) => {
    const total = cart.totalWithTax + (shippingFee || 0);
    const pointAmount = p * props.data.pointSystem.exchangeRate;
    if (p > 0 && total > 0 && pointAmount < total) {
      setTotalAmount(total - pointAmount);
      setPayPoint(p);
    } else {
      setTotalAmount(total);
    }
  };
  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleNoteChange = (event) => {
    setCustomerNote(event.target.value);
  };

  const handleGetCoupon = (event) => {
    if (couponCode.length < 2) {
      return;
    }
    setLoadingCouponValue(true);
    firebaseFunctions.getCoupon(
      {
        merchantId: merchant.id,
        couponId: couponCode,
        amount: totalAmount,
        memberIdOrPhoneNo: user.phoneNo,
      },
      (result) => {
        try {
          if (result.success === false) {
            setCouponError(result.message);
          } else {
            setCouponError(null);
            let discAmt = 0;
            if (result.unit === "percentage") {
              discAmt = (cart.totalWithTax * (result.value / 100)) * SystemCurrency.exchangeRate;
              discAmt = Math.trunc(discAmt)
            } else {
              discAmt = result.value * SystemCurrency.exchangeRate;
            }
            setDiscountAmount(discAmt);
            setTotalAmount((cart.totalWithTax * SystemCurrency.exchangeRate) + (shippingFee || 0) - discAmt);
            let obj = { code: couponCode, amount: discAmt, isPercentage: (result.unit === "percentage" ? true : false) }
            if (result.requestId) {
              obj = { ...obj, requestId: result.requestId }
            }
            setCoupon({ ...obj })
          }
        } catch (exception) {
          console.log(exception);
        } finally {
          setLoadingCouponValue(false);
        }
      }
    );
  };
  const givePoint = (m, amount = 0, detailPoints = 0) => {
    if (m.posPointSystem && m.posPointSystem === 'whole') {
      if (m.pointSystem?.exchangeRate) {
        return Math.trunc(amount / m.pointSystem?.exchangeRate)
      }
    }
    return detailPoints;
  }
  const getCheckOutItems = () => {
    const { items, variants } = props;

    let totalAmount = 0;
    let totalAddedTaxAmount = 0;
    let totalNetAmount = 0;
    let taxIds = [];
    let totalEarnPoints = 0;
    const checkoutItems = cart.cartLines.map((cl, index) => {
      let modifiers = null;
      const variant = variants[cl.id];
      const item = items.find((itm) => itm.id === cl.itemId);

      totalAmount += cl.total;
      totalAddedTaxAmount += cl.wholeTax + cl.inclusiveTax;
      totalNetAmount += cl.totalWithTax;

      if (Array.isArray(item.applicableTaxIds)) taxIds = item.applicableTaxIds;
      let _listModifier = []
      if (Array.isArray(cl.modifiers)) {
        cl.modifiers.map(m => _listModifier.push(`${m.text || ''}`))
      }
      if (_listModifier.length > 0) {
        modifiers = _listModifier.join(',')
      }
      let itemPoint = 0;
      if (variant.points && cl.count) {
        itemPoint = variant.points * cl.count;
      }
      totalEarnPoints = totalEarnPoints + itemPoint;
      //to clearify
      return {
        addedTaxAmount: cl.wholeTax + cl.inclusiveTax,
        name: cl.itemName,
        itemId: cl.itemId,
        unitPoints: itemPoint,
        optionName: variant.name,
        itemVariationId: cl.id,
        price: variant.price * SystemCurrency.exchangeRate,
        photo: variant.photo,
        netAmount: cl.totalWithTax * SystemCurrency.exchangeRate,
        currency: "MMK",
        inclusiveTaxAmount: cl.inclusiveTax,
        index: index,
        totalNetAmount: cl.totalWithTax * SystemCurrency.exchangeRate,
        totalAmount: cl.total * SystemCurrency.exchangeRate,
        taxIds: taxIds,
        qty: { value: cl.count, precision: variant.unit.precision, suffix: variant.unit.abbr || "" },
        modifiers
      };
    });
    let result = {
      totalAmount,
      totalAddedTaxAmount,
      totalNetAmount,
      taxIds,
      totalEarnPoints,
      items: checkoutItems,
    };
    return result;
  };
  const hasCoupon = () => {
    if (coupon.code.length <= 0 || coupon.code === "" || !coupon.code.replace(/\s/g, '').length) {
      return { success: false, code: 'code' }
    }
    if (coupon.amount <= 0) {
      return { success: false, code: 'amount' }
    }
    return { success: true }
  }
  const handleCheckout = (event) => {
    // if (merchant.id === 'madnest') {
    //   setOrderError("Orders closed");
    //   return;
    // }
    if (discountAmount <= 0) {
      if ((totalAmount <= 0) || Number.isNaN(totalAmount)) { props.replace('/'); return; }
    }
    setLoading(true)
    //const _totalAmount = SystemCurrency.exchangeRate * totalAmount;
    const orderNo = generateOrderNumber();
    const cartDetail = getCheckOutItems();
    let orderDetail = {
      merchantId: merchant.id,
      customer: {
        address: [user.address || "", user.city || ""].join(","),
        name: user.name,
        phone: user.phoneNo,
        payment: user.paymentMethod,
      },
      detail: cartDetail.items,
      other: {
        ...cartDetail,
        totalNetAmount: cart.total * SystemCurrency.exchangeRate,
        totalAmount: totalAmount,
        earnPoints: givePoint(merchant, totalAmount - (parseInt(shippingFee, 10)), cartDetail.totalEarnPoints),
        orderType: "Web",
        notes: customerNote,
        couponId: hasCoupon().success ? couponCode : '',
        couponAmount: discountAmount,
        totalDiscountAmount: discountAmount,
        paymentType: user.paymentMethod || "cash",
        currency: "MMK",
        discount: {},
        orderStatus: "Pending",
        orderId: orderNo,
        shippingAmount: parseInt(shippingFee, 10),
        shippinglocation: shippingLocation,
        totalInclusiveTaxAmount: 0,
        exchangeCurrency: {
          symbol: SystemCurrency.currencySymbol,
          code: SystemCurrency.currencyCode,
          value: SystemCurrency.exchangeRate,
        }
      },
    };
    if (props.data && props.data.pointSystem && payPoint > 0) {
      orderDetail = {
        ...orderDetail,
        other: {
          ...orderDetail.other,
          pointPay: {
            merchantId: merchant.id,
            point: payPoint,
            phone: user.phoneNo,
          },
        }
      };
    }
    const c = hasCoupon()
    if (c.success) {
      orderDetail = {
        ...orderDetail,
        other: {
          ...orderDetail.other,
          discountCoupon: {
            merchantId: merchant.id,
            phone: user.phoneNo,
            code: coupon.code,
            amount: coupon.amount,
            requestId: coupon.requestId,
            totalAmount: totalAmount,
          },
          discount: {
            name: coupon.code,
            value: coupon.amount,
            isPercentage: coupon.isPercentage,
          }
        }
      }
    }
    firebaseFunctions.submitOrder(orderDetail, (result) => {
      if (result.success === false) {
        let msg = result.message
        if (result.message === 'invalid-customer') msg = 'Invalid coupon. This coupon code is invalid or has expired'
        setOrderError(msg)
      } else {
        clearCart();
        props.onNext({ orderNo });
      }
      setLoading(false)
    });
  };
  return (
    <div>
      <HomeButton />
      <Grid container justify="center" alignItems="center">
        <Grid container direction="column" justify="center" alignItems="center" className={classes.root} style={rootStyle} spacing={2}>
          {/* <Grid item className={classes.logorow}>
            <img alt={props.merchant.businessName} src={props.merchant.logo} className={classes.logo} />
          </Grid>
          <Grid item className={classes.entry}>
            <Typography variant="h6">{props.merchant.businessName}</Typography>
          </Grid> */}
          <Grid item>
            <Typography>Order Summary</Typography>
          </Grid>
          <Grid item>
            <Typography>{`Exchange Rate(1${SystemCurrency.currencyCode} = ${SystemCurrency.exchangeRate}MMK)`}</Typography>
          </Grid>
          {/* <Grid item container direction="row" justify="space-between" alignItems="center"> */}
          {/* <Grid item /> */}
          {/* <Grid item>{`Exchange Rate(1${SystemCurrency.currencyCode} = ${SystemCurrency.exchangeRate}MMK)`}</Grid> */}
          {/* </Grid> */}
          <Grid item container direction="column" justify="space-between" alignItems="center" spacing={2}>
            {cart.cartLines.map((line) => (
              <Grid item container direction="row" justify="space-between" alignItems="center" key={`cl-${line.id}`}>
                <Grid item xs={6}>
                  <Grid item container direction='column'>
                    <Grid item>
                      {`${line.variantName === line.itemName ? line.variantName : `${line.itemName}(${line.variantName})`}(${line.count}x${line.price})`}
                    </Grid>
                    {
                      line.modifiers.map(m => (
                        <Grid item>
                          <Typography variant='subtitle2' color='textSecondary'>{`${m.text}(${line.count}x${m.amount.value})`}</Typography>
                        </Grid>
                      ))
                    }
                  </Grid>

                </Grid>

                {/* <Grid item xs={6}>{`${line.variantName}(${line.count}x${line.price})`}</Grid> */}

                <Grid item xs={6}>
                  <Typography align="right">{formatCurrencyExchangeRate(line.total)}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <Divider variant="middle" />
          </Grid>
          <Grid item container direction="row" justify="space-between" alignItems="center">
            <Grid item>Taxes</Grid>
            <Grid item>{formatCurrencyExchangeRate(tax)}</Grid>
          </Grid>
          <Grid item container direction="row" justify="space-between" alignItems="center">
            <Grid item>Shipping Fee</Grid>
            <Grid item>{`MMK ${shippingFee}`}</Grid>
          </Grid>
          <Grid item container direction="row" justify="space-between" alignItems="center">
            <Grid item>Discount</Grid>
            <Grid item>{`MMK ${discountAmount}`}</Grid>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <Divider variant="middle" />
          </Grid>
          <Grid item container direction="row" justify="space-between" alignItems="center">
            <Grid item>Total</Grid>
            <Grid item>{`MMK ${totalAmount}`}</Grid>
          </Grid>
          <Grid item container direction="row" justify="space-between" alignItems="center">
            <Grid item>Payment</Grid>
            <Grid item>
              <Chip label={user.paymentMethod || 'cash'} />
            </Grid>
          </Grid>
          <Grid item container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <TextField
                id="coupon-code"
                label="Coupon Code"
                value={couponCode}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                onChange={handleCouponCodeChange}
              />
            </Grid>
            <Grid item>
              <ButtonWithProgress text={loadingCouponValue ? "loading..." : "Apply"}
                onClick={handleGetCoupon}
                isLoading={loadingCouponValue}
                disabled={(couponCode && couponCode.length > 2) ? false : true}
              />
              {/* <Button variant="contained" color="secondary" onClick={handleGetCoupon}>
                  Get Coupon
                </Button> */}
            </Grid>
            {couponError && (
              <Grid item xs={12}>
                <Alert severity="error">{couponError}</Alert>
              </Grid>
            )}
          </Grid>
          <Grid item container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="customer-note"
                label="Customer Note"
                multiline
                rows={3}
                value={customerNote}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                onChange={handleNoteChange}
              />
            </Grid>
          </Grid>
          {props.data && props.data.pointSystem && (
            <Grid item container direction="row" justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <PointPay pointSystem={props.data.pointSystem} pointChange={(p) => handlePayPointChange(p)} />
              </Grid>
            </Grid>
          )}
          <Grid item container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item>
              <Typography variant="h6">{props.address || 'Address'}</Typography>
            </Grid>
            <Grid item>{`${user.name}, ${user.phoneNo}`}</Grid>
            <Grid item>{`${user.address}, ${user.city}`}</Grid>
          </Grid>
          {orderError && (
            <Grid item xs={12}>
              <Alert severity="error">{orderError}</Alert>
            </Grid>
          )}
          <Grid item>
            {
              loading ? (<ButtonWithProgress text='Checkout' isLoading={loading} onClick={() => { }} />) :
                (<Button variant="contained" color="secondary" onClick={handleCheckout}>
                  Place Order
                </Button>)
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant.data,
    cart: state.cart,
    user: state.user,
    items: state.items.data,
    variants: state.variants.data.variants,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: () => dispatch(cartActions.clearCart()),
    replace: (url) => dispatch(replace(url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderCheckout);
