export const GET_USER_INFO = "GET_USER_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const REMOVE_USER_INFO = "REMOVE_USER_INFO";
export const NEW_USER_LOGIN = "NEW_USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const SET_USER_NAME = "SET_USER_NAME";
export const SET_ADDRESS = "SET_ADDRESS";
export const LOAD_USER_ADDRESS = "LOAD_USER_ADDRESS";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD";

export const actionCreators = {
  getUser: () => ({
    type: GET_USER_INFO,
  }),

  setUser: (user) => ({
    type: SET_USER_INFO,
    user,
  }),

  loadUser: () => ({
    type: LOAD_USER_INFO,
  }),

  saveUser: (user) => ({
    type: SAVE_USER_INFO,
    user,
  }),

  removeUser: (user) => ({
    type: REMOVE_USER_INFO,
    user,
  }),

  newUserLogin: (user) => ({
    type: NEW_USER_LOGIN,
    user,
  }),

  setUserName: (name) => ({
    type: SET_USER_NAME,
    name,
  }),
  loadUserAddress: () => ({
    type: LOAD_USER_ADDRESS,
  }),
  setUserAddress: (address, city) => ({
    type: SET_ADDRESS,
    address,
    city,
  }),

  setPaymentMethod: (method) => ({
    type: SET_PAYMENT_METHOD,
    method,
  }),

  setShippingMethod: (method) => ({
    type: SET_SHIPPING_METHOD,
    method,
  }),

  userLogout: () => ({
    type: USER_LOGOUT,
  }),
};
