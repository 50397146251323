import React from "react";
import { ajax } from "rxjs/ajax";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { actionCreators as merchantActions } from "./actions/merchant_actions";
import { actionCreators as categoryActions } from "./actions/category_actions";
import { actionCreators as itemActions } from "./actions/item_actions";
import { actionCreators as variantActions } from "./actions/variant_actions";
import { actionCreators as shippingFeeActions } from "./actions/shipping_fee_actions";
import { actionCreators as userActions } from "./actions/user_actions";
import { DataApi } from "../constants";
import { XMLHttpRequest } from 'xmlhttprequest';
function createXHR() {
  return new XMLHttpRequest();
}

const loadInitialData = (store) => {
  let hostname = window.location.hostname;
  //if (hostname === "localhost") hostname = piticafe_host; //mone_host || pitibusiness_host ||
  //console.log(`Hostname : ${hostname}`);
  ajax({
    createXHR,
    url: `${DataApi.getUrlToMerchant}/${hostname}`,
    method: 'GET',
    responseType: 'json',
    crossDomain: true,
    withCredentials: false,
  }).pipe(
    map((res) => {
      const response = res.response
      if (response.status === "Ok") {
        //  console.log(`merchantid okay awesome: ${response.data}`);
        const merchantId = response.data.id;
        store.dispatch(categoryActions.fetchCategories(merchantId));
        store.dispatch(merchantActions.fetchMerchant(merchantId));
        store.dispatch(itemActions.fetchItems(merchantId));
        store.dispatch(variantActions.fetchVariants(merchantId));
        store.dispatch(shippingFeeActions.fetchShippingFees(merchantId));
        store.dispatch(userActions.loadUser());
        store.dispatch(userActions.loadUserAddress());
      }
    },
    )).subscribe();
  // ajax
  //   .getJSON(`${DataApi.getUrlToMerchant}/${hostname}`)
  //   .pipe(
  //     map((response) => {
  //       if (response.status === "Ok") {
  //         const merchantId = response.data.id;
  //         store.dispatch(categoryActions.fetchCategories(merchantId));
  //         store.dispatch(merchantActions.fetchMerchant(merchantId));
  //         store.dispatch(itemActions.fetchItems(merchantId));
  //         store.dispatch(variantActions.fetchVariants(merchantId));
  //         store.dispatch(shippingFeeActions.fetchShippingFees(merchantId));
  //         store.dispatch(userActions.loadUser());
  //       }
  //     }),
  //     catchError((error) => {
  //       // console.log("error: ", error);
  //       return of(error);
  //     })
  //   )
  //   .subscribe();
};

export default loadInitialData;
