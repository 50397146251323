export const GET_MAIN_MENU_STATE = "GET_MAIN_MENU_STATE";
export const SET_MAIN_MENU_STATE = "SET_MAIN_MENU_STATE";
export const TOGGLE_MAIN_MENU_STATE = "TOGGLE_MAIN_MENU_STATE";

export const GET_SHOPPING_CART_STATE = "GET_SHOPPING_CART_STATE";
export const SET_SHOPPING_CART_STATE = "SET_SHOPPING_CART_STATE";
export const SHOW_SHOPPING_CART = "SHOW_SHOPPING_CART";
export const HIDE_SHOPPING_CART = "HIDE_SHOPPING_CART";
export const SHOW_VARIANT_DRAWER = "SHOW_VARIANT_DRAWER";
export const HIDE_VARIANT_DRAWER = "HIDE_VARIANT_DRAWER";

export const SHOW_CATEGORY_DRAWER = "SHOW_CATEGORY_DRAWER";
export const HIDE_CATEGORY_DRAWER = "HIDE_CATEGORY_DRAWER";

export const SHOW_REWARD_DRAWER = "SHOW_REWARD_DRAWER";
export const HIDE_REWARD_DRAWER = "HIDE_REWARD_DRAWER";

export const actionCreators = {
  getMainMenuState: () => ({
    type: GET_MAIN_MENU_STATE,
  }),

  setMainMenuState: (menuState) => ({
    type: SET_MAIN_MENU_STATE,
    menuState,
  }),

  getShoppingCartState: () => ({
    type: GET_SHOPPING_CART_STATE,
  }),

  setShoppingCartState: (shoppingCartState) => ({
    type: SET_SHOPPING_CART_STATE,
    shoppingCartState,
  }),

  showShoppingCart: () => ({
    type: SHOW_SHOPPING_CART,
  }),

  hideShoppingCart: () => ({
    type: HIDE_SHOPPING_CART,
  }),

  showVariantDrawer: () => ({
    type: SHOW_VARIANT_DRAWER,
  }),

  hideVariantDrawer: () => ({
    type: HIDE_VARIANT_DRAWER,
  }),
  showCategoryDrawer: () => ({
    type: SHOW_CATEGORY_DRAWER,
  }),
  hideCategoryDrawer: () => ({
    type: HIDE_CATEGORY_DRAWER,
  }),
  showRewardDrawer: () => ({
    type: SHOW_REWARD_DRAWER,
  }),
  hideRewardDrawer: () => ({
    type: HIDE_REWARD_DRAWER,
  })
};
