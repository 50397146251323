export const isDesktopFooterAvailbalePath = (path) => {
    if (path === "/") return true;
    if (path.startsWith("/detail/")) return true;
    if (path.startsWith("/profile")) return true;
    //if (path.startsWith("/contact")) return true;
    if (path.startsWith("/login")) return true;
    return false;
};
export const isMobileFooterAvailablePath = (path) => {
    if (path.startsWith("/profile")) return true;
    //if (path.startsWith("/contact")) return true;
    if (path.startsWith("/login")) return true;
    return false
}