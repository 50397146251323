export const GET_MERCHANT = "GET_MERCHANT";
export const SET_MERCHANT = "SET_MERCHANT";
export const FETCH_MERCHANT = "FETCH_MERCHANT";
export const FETCH_MERCHANT_COMPLETED = "FETCH_MERCHANT_COMPLETED";

export const actionCreators = {
  getMerchant: () => ({
    type: GET_MERCHANT,
  }),

  setMerchant: (merchant) => ({
    type: SET_MERCHANT,
    merchant,
  }),

  fetchMerchant: (merchantId) => ({
    type: FETCH_MERCHANT,
    merchantId,
  }),

  fetchMerchantCompleted: (payload) => ({
    type: FETCH_MERCHANT_COMPLETED,
    payload,
  }),
};
