import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import ShoppingBasket from "@material-ui/icons/RemoveShoppingCart";

const controlWidth = 42;
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //width: controlWidth,
        minWidth: controlWidth,
    },
    valueTextWrapper: {
        width: controlWidth + 10,
        minWidth: controlWidth + 10,
        height: controlWidth,
        minHeight: controlWidth,
        backgroundColor: theme.palette.primary.main,
    },
    valueText: {
        fontSize: 18,
        fontWeight: 400,
        fontFamily: "Lato,sans-serif",
        paddingLeft: 0,
        paddingRight: 0,
        width: controlWidth,
        minWidth: controlWidth,
        textAlign: "center",
    },
    button: {
        margin: theme.spacing(1),
    },
    container: {
        margin: 8,
    },
}));

const HorizontalUpDownBig = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Chip
                label='out of stock'
                color="primary"
                variant="default"
                icon={<ShoppingBasket />}
            />
        </div>
    );
};

export default HorizontalUpDownBig;
