import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default function Empty() {
    return (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
            <FileCopyIcon color='primary' style={{ fontSize: 80, opacity: 0.4 }} />
            <br />
            <i> empty orders</i>
        </div>
    );
}
