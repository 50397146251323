import { zip, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, map, retryWhen, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { handleError, RetryStrategy } from "./handle_ajax_error";
import { actionCreators, FETCH_VARIANTS } from "../store/actions/variant_actions";
import { GET_DATA_ERROR } from "../store/actions/common_actions";
import { DataApi } from "../constants";
import { XMLHttpRequest } from 'xmlhttprequest';
function createXHR() {
  return new XMLHttpRequest();
}
const toDataObj = (zippedData) => {
  if (zippedData.length === 3) {
    return {
      variants: zippedData[0].response,
      modifiers: zippedData[1].response,
      taxes: zippedData[2].response,
    };
  }
  return {};
};

export const variantsEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_VARIANTS),
    mergeMap((action) =>
      zip(
        ajax({
          createXHR,
          url: `${DataApi.getAllVariation}/${action.merchantId}`,
          method: 'GET',
          responseType: 'json',
          crossDomain: true,
          withCredentials: false,
        }),
        ajax({
          createXHR,
          url: `${DataApi.getAllModifier}/${action.merchantId}`,
          method: 'GET',
          responseType: 'json',
          crossDomain: true,
          withCredentials: false,
        }),
        ajax({
          createXHR,
          url: `${DataApi.getAllTaxes}/${action.merchantId}`,
          method: 'GET',
          responseType: 'json',
          crossDomain: true,
          withCredentials: false,
        })
        //(variants, modifiers, taxes) => actionCreators.fetchItemsCompleted(variants.response, modifiers.response, taxes.response)
      ).pipe(
        map((responses) => {
          return actionCreators.fetchVariantsCompleted(toDataObj(responses));
        }),
        retryWhen(RetryStrategy),
        catchError((error) => handleError(error, GET_DATA_ERROR))
      )
    )
  );
